import React, { useState } from "react";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import {
  getMallGroupedService,
  getReportExcelService,
} from "../../reports.api";
import { useTranslation } from "react-i18next";
import {
  convertCentToDollar,
  convertSecondsToDhms,
} from "../../../../app/util";
import { Button } from "antd";
import CustomSingleSelectMall from "../../../customFilters/CustomSingleSelectMall";

const Mall = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState({});

  const columns = [
    {
      title: t("general::strollerSummary"),
      dataIndex: "mallName",
      key: "mallName",
      // searchable: true,
      customFilter: CustomSingleSelectMall,
    },
    {
      title: t("financial::totalRent"),
      dataIndex: "totalRent",
      key: "totalRent",
      filter: "fromTo",
    },

    {
      title: t("financial::totalPrice"),
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (totalPrice) => {
        return convertCentToDollar(totalPrice);
      },
    },
    {
      title: t("financial::duration") + " (DD:HH:MM:SS)",
      dataIndex: "usageDuration",
      key: "usageDuration",
      render: (usageDuration) => {
        return convertSecondsToDhms(usageDuration);
      },
    },
  ];

  // async function fetchReports(p) {
  //   if (p.mallName) {
  //     p.mallName = p.mallName.toString();
  //   }
  //   let resp = await getMallGroupedService(p);
  //   setParams((prevState) => ({ ...prevState, ...p }));
  //   return resp;
  // }

  function downloadExcel() {
    const result = {
      ...dateFilter,
      mallName: dateFilter.mallName ? dateFilter.mallName : undefined,
      from: dateFilter.from
        ? dateFilter.from.format("YYYY-MM-DDTHH:mm:ss")
        : undefined,
      to: dateFilter.to
        ? dateFilter.to.format("YYYY-MM-DDTHH:mm:ss")
        : undefined,
    };
    getReportExcelService(result, "mall-grouped.csv", setLoading);
  }

  return (
    <Container loading={loading}>
      <div style={{ margin: "10px 0" }}>
        <Button onClick={() => downloadExcel()}> Excel Download </Button>
      </div>
      <Crud
        get={{
          apiService: (params) => {
            setDateFilter({
              from: params.from,
              to: params.to,
              mallName: params.mallName,
            });
            return getMallGroupedService(params);
          },
          columns,
          tableProps: {
            rowKey: "mallId",
          },
        }}
      />
    </Container>
  );
};

export default Mall;
