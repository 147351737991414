import React, { useState, useEffect } from "react";
import {AutoComplete, Button, Input} from "antd";
import { DownOutlined } from "@ant-design/icons";



const CustomFilterState = (props) => {
  const { setFilter, item, title, reset, setReset } = props;
  const [value, setValue] = useState();

  const customFilterData = [
    { label: "Rented", value: "RENTED" },
    { label: "Free", value: "FREE" },
  ];

  useEffect(
    () => {
      setValue();
      setReset(false);
    },
    // eslint-disable-next-line
    [reset]
  );

  const handleConfirm = (value) => {
    setValue(value);
  };

  useEffect(
    () => {
      setFilter((prevData) => ({
        ...prevData,
        [item]: value,
      }));
    },
    // eslint-disable-next-line
    [value]
  );

  return (
    <>
      <AutoComplete
        allowClear
        backfill
        style={{ width: "100%", padding: "4px 9px" }}
        onSelect={handleConfirm}
        value={value !== undefined ? value : ""}
        placeholder={`Enter ${title}`}
        options={customFilterData}

      >
        <Input
          suffix={
            <Button className={"down-icon"}>
              <DownOutlined />
            </Button>
          }
        />
      </AutoComplete>
    </>
  );
};
export default CustomFilterState;
