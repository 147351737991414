import React from "react";
import { Anchor, Descriptions, Modal } from "antd";
import PropTypes from "prop-types";

const ExtraInfo = (props) => {
  const { extraInfoPopup, setExtraInfoPopup } = props;
  const { Link } = Anchor;
  return (
    <Modal
      title={"metaData"}
      visible={extraInfoPopup !== false}
      onOk={() => setExtraInfoPopup(false)}
      onCancel={() => setExtraInfoPopup(false)}
      cancelText={"Close"}
      okButtonProps={{ hidden: true }}
    >
      {extraInfoPopup && extraInfoPopup.metaData && (
        <>
          <Descriptions>
            <Descriptions.Item span={24} label="title">
              {extraInfoPopup.metaData.title}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions>
            <Descriptions.Item span={24} label="changes">
              {extraInfoPopup.metaData.changes.join("-")}
            </Descriptions.Item>
          </Descriptions>

          <Anchor>
            <Link
              target="_blank"
              href={`${extraInfoPopup.link}`}
              title="link"
            />
          </Anchor>
        </>
      )}
    </Modal>
  );
};

ExtraInfo.propTypes = {
  extraInfo: PropTypes.any,
  setExtraInfoPopup: PropTypes.func,
};

export default ExtraInfo;
