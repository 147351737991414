import React, { useEffect, useState } from "react";
import ButtonFileManager from "./components/buttonFileManager/ButtonFileManager";
import {
  Button,
  Col,
  Divider,
  message,
  Modal,
  Row,
  Spin,
  Tree,
  Typography,
} from "antd";
import {
  createNewFolder,
  getFileUrlService,
  getFolderContent,
  getRootFolders,
  uploadFileService,
} from "./fileManager.api";
import "./fileManager.style.scss";
import Files from "./components/files/Files";
import {
  checkValue,
  downloadURL,
  downloadWithAxios,
  getCookieLanguages,
} from "../../app/util";
import {
  FolderAddFilled,
  FolderOpenTwoTone,
  FolderTwoTone,
  LeftOutlined,
  ReloadOutlined,
  UpOutlined,
} from "@ant-design/icons";
import UploadFile from "./components/uploadFile/UploadFile";
import { useTranslation } from "react-i18next";

const FileManager = ({
  accept = "*/*",
  setValue,
  getFileId = false,
  value,
  directoryName = "content",
  showFileManager = false,
}) => {
  const [isOpen, setIsOpen] = useState(showFileManager);
  const [loading, setLoading] = useState(false);
  const [rootFolders, setRootFolders] = useState([]);
  const [objectFolders, setObjectFolders] = useState({});
  const [explorerHistory, setExplorerHistory] = useState([]);
  const [content, setContent] = useState([]);
  const { t } = useTranslation();
  const language = getCookieLanguages();
  // eslint-disable-next-line
  const [simpleMode, setSimpleMode] = useState(true);
  // const [directoryUUID, setDirectoryUUID] = useState(
  //   configReducer.data !== undefined && configReducer.data.FOLDER_ID
  //     ? configReducer.data.FOLDER_ID
  //     : null
  // );

  useEffect(() => {
    fetchRootFolders();
    // setDirectoryUUID(null);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isOpen === false) {
      setLoading(false);
    }
  }, [isOpen]);

  function toggle() {
    setIsOpen(!isOpen);
  }

  function fetchRootFolders() {
    setLoading(true);
    getRootFolders()
      .then((r) => {
        let result = parseFolders(r.data);

        setRootFolders(result);
        // handleFolderDirectUpload(r.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function parseFolders(data) {
    let result = [];
    let objFolder = {};

    data.forEach((i) => {
      result.push(getAntObject(i));
    });

    function getAntObject(i) {
      objFolder[i.uuid] = i;
      return {
        ...i,
        title: i.name ? i.name : "no-name",
        key: i.uuid,
        icon: ({ selected }) =>
          selected ? <FolderOpenTwoTone /> : <FolderTwoTone />,
        children: i.childFolders ? getChildren(i.childFolders) : [],
      };
    }

    function getChildren(children) {
      let res = [];

      children.forEach((child) => {
        res.push(getAntObject(child));
      });

      return res;
    }

    setObjectFolders(objFolder);
    return result;
  }

  function selectFolder(id) {
    if (id.length === 0 || !id || !id[0]) {
      setContent([]);
      setExplorerHistory([]);
      return false;
    }

    const folderID = id[0];
    explorerHistory.push(folderID);
    setExplorerHistory(explorerHistory);

    setLoading(true);
    getFolderContent(folderID)
      .then((r) => {
        setLoading(false);
        setContent(r.data);
      })
      .catch((e) => {
        message.warn(t("fileManager::errorGettingFolderInfo"), 3);
        setLoading(false);
        console.log(e);
      });
  }

  function handleBackFolder() {
    let folderId = explorerHistory.pop();

    if (explorerHistory.length > 1) {
      folderId = explorerHistory.pop();
    }

    setExplorerHistory(explorerHistory);
    selectFolder([folderId]);
  }

  function handleReload() {
    let inRoot = explorerHistory.length === 0;

    fetchRootFolders();
    if (!inRoot) {
      let folderId = explorerHistory.pop();
      selectFolder([folderId]);
    }
  }

  function handleCreateFolder(name, parentId = false) {
    name = name
      ? name
      : window.prompt("Please enter a new folder name", "new folder");

    if (!checkValue(name)) {
      return false;
    }

    let data = {
      name,
    };

    let inRoot = explorerHistory.length === 0;
    if (!inRoot && !parentId) {
      data.parentId = explorerHistory[explorerHistory.length - 1];
    } else if (parentId) {
      data.parentId = parentId;
    }

    setLoading(true);

    createNewFolder(data)
      .then((r) => {
        message.success(language === "en" && `${name} folder added`, 3);
        handleReload();
      })
      .catch((e) => {
        console.log(e);
        message.error(t("fileManager::errorCreatingNewFolder"), 3);
      });
  }

  function handleUpFolder() {
    if (!(explorerHistory.length > 0)) {
      return false;
    }
    // get current folder id
    const currentFolderId = explorerHistory.pop();
    // get current folder parentId
    if (!objectFolders.hasOwnProperty(currentFolderId)) {
      return false;
    }
    const currentFolderItem = objectFolders[currentFolderId];
    // selectFolder(parentId)
    setExplorerHistory(explorerHistory);
    selectFolder([currentFolderItem.parentId]);
  }

  function handleUploadFile(file, folderId = "") {
    if (loading) {
      message.loading(t("fileManager::uploading"), 3);
      return false;
    }

    setLoading(true);
    uploadFileService(file, folderId)
      .then((r) => {
        setLoading(false);
        message.success(t("fileManager::uploadSuccessfully"), 3);
        if (simpleMode) {
          setValue(r.data.url);
          setIsOpen(false);
          return false;
        } else if (window.confirm(t("fileManager::selectUploadedFile"))) {
          setValue(r.data.url);
          setIsOpen(false);
          return false;
        } else {
          handleReload();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  // function selectFile(fileId) {
  //   console.log(fileId, "fileid");
  //   if (getFileId) {
  //     setValue(fileId);
  //     setIsOpen(false);
  //     return false;
  //   } else {
  //     getFileUrl(fileId, (url) => {
  //       setValue(url);
  //       setIsOpen(false);
  //     });
  //   }
  // }

  function getFileUrl(uuid, callback) {
    setLoading(true);
    getFileUrlService(uuid)
      .then((r) => {
        // r.data.url;
        setLoading(false);
        if (callback) {
          callback(r.data.url, r.data);
        }
      })
      .catch((e) => {
        console.log(e);
        message.error(t("fileManager::errorGettingLink"), 3);
        setLoading(false);
      });
  }

  // function handleFolderDirectUpload(folders = rootFolders) {
  //   let directUpload = handleGetFolderUUID(folders);
  //   let directory = handleGetFolderUUID(
  //     directUpload.childFolders,
  //     directoryName,
  //     directUpload.uuid
  //   );
  //
  //   if (directory && directory.uuid) {
  //     setDirectoryUUID(directory.uuid);
  //   }
  // }

  // function handleGetFolderUUID(
  //   folders,
  //   AutoMateFolder = "directUpload",
  //   parentId = true
  // ) {
  //   let folder = "";
  //   let d = folders.find((i) => i.name === AutoMateFolder);
  //   if (d && d.uuid) {
  //     folder = d.length > 0 ? d[0] : d;
  //   } else if (directoryUUID) {
  //     folder = directoryUUID;
  //   } else {
  //     handleCreateFolder(AutoMateFolder, parentId);
  //   }
  //
  //   return folder;
  // }

  return (
    <>
      <ButtonFileManager accept={accept} onClick={toggle} value={value} />
      {!!value && (
        <Button
          onClick={() => {
            if (value && value.toString().search("http") > 0) {
              downloadURL(value);
            } else {
              getFileUrl(value, (url, file) => {
                downloadWithAxios(url, file.name, setLoading);
              });
            }
          }}
          // disabled={loading}
          loading={loading}
        >
          Download File
        </Button>
      )}
      <Modal
        visible={isOpen}
        onOk={toggle}
        onCancel={toggle}
        okText="Ok"
        cancelText="Cancel"
        className="file-manager-modal"
        width={simpleMode ? "40%" : "80%"}
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <Spin spinning={loading}>
          <div className={"modal-custom-header"}>
            <Typography.Title level={5}>file management</Typography.Title>
            {/* <Button type="primary" onClick={() => setSimpleMode(!simpleMode)}>
              {simpleMode ? "Manual mode (advanced)": "Automatic mode (simple)"}
            </Button> */}
          </div>
          {simpleMode ? (
            <div className={"simple-mode"}>
              <UploadFile
                accept={accept}
                folderId={""}
                simpleMode={simpleMode}
                handleUploadFile={handleUploadFile}
              />
            </div>
          ) : (
            <>
              <Row className="header">
                <Col span={4} className="header-items-wrapper">
                  <div className="new-folder-btn">
                    <Button
                      type="text"
                      className="reload-btn"
                      onClick={handleCreateFolder}
                    >
                      <FolderAddFilled />
                      <p>New Folder</p>
                    </Button>
                  </div>
                </Col>
                <Col span={4} className="header-items-wrapper">
                  {explorerHistory.length > 0 && (
                    <Button
                      type="text"
                      className="reload-btn"
                      onClick={handleReload}
                    >
                      <ReloadOutlined />
                      <p>Reload</p>
                    </Button>
                  )}
                </Col>
                <Col span={4} className="header-items-wrapper">
                  {explorerHistory.length > 0 && (
                    <UploadFile
                      accept={accept}
                      handleUploadFile={handleUploadFile}
                      simpleMode={simpleMode}
                      folderId={explorerHistory[explorerHistory.length - 1]}
                    />
                  )}
                </Col>
                <Col
                  span={4}
                  className="header-items-wrapper"
                  style={{ justifyContent: "flex-end" }}
                >
                  {explorerHistory.length > 0 && (
                    <Button
                      type="text"
                      className="back-btn"
                      onClick={handleUpFolder}
                    >
                      <UpOutlined />
                      <p> Parent Folder </p>
                    </Button>
                  )}
                </Col>
                <Col
                  span={8}
                  className="header-items-wrapper"
                  style={{ justifyContent: "flex-end" }}
                >
                  {explorerHistory.length > 1 && (
                    <Button
                      type="text"
                      className="back-btn"
                      onClick={handleBackFolder}
                    >
                      <LeftOutlined />
                      <p>Back</p>
                    </Button>
                  )}
                </Col>
              </Row>
              <Divider />
              <Row className="main-row">
                <Col span={18} className="files-wrapper">
                  <div className="files">
                    <Files
                      content={
                        content.length === 0 &&
                        explorerHistory.length === 0 &&
                        rootFolders.length > 0
                          ? rootFolders
                          : content
                      }
                      selectFile={getFileUrl}
                      getFileUrl={getFileUrl}
                      selectFolder={selectFolder}
                    />{" "}
                    :
                  </div>
                </Col>
                {/* SHOW ROOT */}
                <Col span={6} className="directories-wrapper">
                  <div className="directories">
                    <Tree
                      treeData={rootFolders}
                      showIcon
                      onSelect={(id) => {
                        selectFolder(id);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default FileManager;
