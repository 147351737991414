import React, { useEffect, useMemo, useState } from "react";
import {
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  WalletOutlined,
  FlagTwoTone
} from "@ant-design/icons";
import { Button, Col, Divider, Dropdown, Layout, Menu, Row, Badge } from "antd";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./components/languageSelector/LanguageSelector";
import setMultipleLang from "../../../../app/config";
import { getBalance } from "./header.api";
import {dollarToCent} from "../../../../app/util";
import {getRedFlags} from "../../../administration/pages/stationMonitoring/StationMonitoring.api";

const Header = (props) => {
  const [data, setData] = useState([]);
  const [redFlagsCount, setRedFlagsCount] = useState(0)
  const { collapsed, toggle } = props;
  const history = useHistory();

  const { profileReducer } = useSelector((state) => state);
  const handleLogout = () => {
    localStorage.clear();
    history.push("/login");
  };

  window.updateBadge = () => {
    getRedFlags({
      from: undefined,
      to: undefined,
      state: "OPENED",
      pageSize: 1,
      pageNumber: 0,
    }).then(res =>{
      setRedFlagsCount(res.data.totalElements);
    })
  }
  useEffect(() => {
    getBalance().then((res) => {
      if (res.data) {
        setData(res.data);
      }
    });

    window.updateBadge()
  }, []);
  const available =
    data && data.available && data.available[0] && data.available[0].amount
      ? Number(data.available[0].amount)
      : "0";
  const pending =
    data && data.pending && data.pending[0] && data.pending[0].amount
      ? Number(data.pending[0].amount)
      : "0";
  const total = useMemo(
    () =>
      isNaN(available + pending) ? "0" : Number(pending) + Number(available),
    [available, pending]
  );
  const { t } = useTranslation();
  const menu = (
    <Menu className={"drop-down-balance"}>
      <Menu.Item key="1">
        <span>Available </span>: US {dollarToCent(available)}
      </Menu.Item>
      <Divider />
      <Menu.Item key="2">
        <span>Pending</span> : US {dollarToCent(pending)}
      </Menu.Item>
      <Divider />
      <Menu.Item key="3">
        <span>Total</span> : US {dollarToCent(total)}
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Header className="site-layout-background">
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: "trigger",
        onClick: toggle,
      })}
      <div className="sign-out-wrapper">
        <Row>
          <Col className="header-col">
            <Badge count={redFlagsCount}>
              <Button href={"/administration/station-monitoring"}>
                <FlagTwoTone twoToneColor={redFlagsCount ? "#90103e" : "#000000"} />
                Red Flags
              </Button>
            </Badge>
          </Col>
          <Col className="header-col">
            <Dropdown overlay={menu}>
              <Button>
                <WalletOutlined /> Balance : US {dollarToCent(total)}
                <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
          <Col className="header-col">
            {setMultipleLang === true && <LanguageSelector />}
            <Dropdown
              overlay={
                <Menu inlineIndent={24}>
                  <Menu.Item danger>
                    <div onClick={handleLogout}>{t("general::exit")}</div>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <Button className="profile-btn">
                <div className="profile">
                  {t("general::hello")}{" "}
                  {profileReducer.data.username
                    ? profileReducer.data.username
                    : t("general::user")}
                </div>
                <DownOutlined style={{ marginTop: "1ch" }} />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </Layout.Header>
  );
};

Header.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default Header;
