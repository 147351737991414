import { Avatar, Button, Comment, Form, Input, Modal, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Container from "../../container/Container";
import {
  addMessage,
  deleteMessage,
  getMessages,
  getSubscriber,
} from "../pages/ticket/ticket.api";
import moment from "jalali-moment";
import "./ticketMessages.style.scss";
import { showDetailDate } from "../../../app/util";
import { useTranslation } from "react-i18next";

export default function TicketMessages() {
  const { t } = useTranslation();
  const params = useParams();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [fetchDataFlag, setFetchDataFlag] = useState(false);
  let history = useHistory();

  const handleChangeInput = ({ target: { value } }) => {
    setMessage(value);
  };

  const fetchMessages = (params) => {
    getMessages(params)
      .then((response) => {
        setApiData(response.data.content);
        let objDiv = document.getElementById("chat-list");
        objDiv.scrollTop = objDiv.scrollHeight;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchMessages(params.ticketId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let idsModified = {};
    if (apiData && apiData.length > 0 && !fetchDataFlag) {
      setFetchDataFlag(true);
      apiData.forEach((item) => {
        getSubscriber(item.subscriberId)
          .then((response) => {
            idsModified[item.subscriberId] = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      });
      setUserInfo(idsModified);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData]);

  const handleAddMessage = () => {
    setLoading(true);
    addMessage({
      ticketId: params.ticketId,
      content: message.trim(),
    })
      .then((response) => {
        setLoading(false);
        setMessage("");
        fetchMessages(params.ticketId);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  //-----------------Delete message

  const [visible, setVisible] = useState(false);
  const [wantDeleteMessage, setWantDeleteMessage] = useState("");
  const onOk = () => {
    deleteMessage(wantDeleteMessage)
      .then((response) => {
        setVisible(false);
        setWantDeleteMessage("");
        fetchMessages(params.ticketId);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onCancel = () => {
    setVisible(false);
    setWantDeleteMessage("");
  };
  const handleAskModal = (id) => {
    setVisible(true);
    setWantDeleteMessage(id);
  };
  const renderMessages = () => {
    if (apiData && apiData.length > 0) {
      return apiData.map((item, index) => {
        return (
          <Comment
            key={index}
            /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
            author={
              userInfo[item.subscriberId]
                ? userInfo[item.subscriberId].extraInfo
                  ? userInfo[item.subscriberId].extraInfo.fullName
                    ? userInfo[item.subscriberId].extraInfo.fullName
                    : ""
                  : ""
                : ""
            }
            avatar={
              <Avatar
                src={
                  userInfo[item.subscriberId]
                    ? userInfo[item.subscriberId].extraInfo
                      ? userInfo[item.subscriberId].extraInfo.avatarImageUrl
                        ? userInfo[item.subscriberId].extraInfo.avatarImageUrl
                        : "/images/profile.png"
                      : "/images/profile.png"
                    : "/images/profile.png"
                }
                alt="Han Solo"
              />
            }
            actions={[
              <Tooltip key="remove" title="">
                <span onClick={() => handleAskModal(item.id)}>
                  {t("administration::remove")}
                </span>
              </Tooltip>,
            ]}
            content={<p>{item.content}</p>}
            datetime={
              <Tooltip title={moment().format("YYYY-MM-DD HH:mm:ss")}>
                <span>{showDetailDate(item.createdDateMilli)}</span>
              </Tooltip>
            }
          />
        );
      });
    } else {
      return <div>{t("general::noMessageError")}</div>;
    }
  };

  return (
    <Container>
      <Button
        type="primary"
        style={{ marginBottom: "1em" }}
        onClick={() => {
          history.push({
            pathname: "/administration/tickets",
          });
        }}
      >
        Back
      </Button>
      <div className="chat-box" id="chat-list">
        {renderMessages()}
      </div>
      <div>
        <Form.Item style={{ marginTop: "40px" }}>
          <Input.TextArea
            rows={4}
            value={message}
            onChange={handleChangeInput}
          />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            disabled={loading || message.trim().length < 1}
            onClick={handleAddMessage}
          >
            {t("general::send")}
          </Button>
        </Form.Item>
      </div>
      <Modal
        title={t("general::delete")}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        okText={t("general::confirm")}
        cancelText={t("general::cancel")}
      >
        {t("general::deleteQuestion")}
      </Modal>
    </Container>
  );
}
