import { Form, Input, Select } from "antd";
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const CreateUserForm = () => {
  const { rolesReducer } = useSelector((state) => state);
  const { t } = useTranslation();

  let children = [];
  if (rolesReducer && rolesReducer.content) {
    rolesReducer.content.forEach((role) => {
      children.push(
        <Option key={role.id} value={role.name} name={role.name}>
          {role.name}
        </Option>
      );
    });
  }

  return (
    <>
      <Form.Item
        label={t("userManagement::username")}
        name={"username"}
        rules={[{ required: true }]}
        hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        label={t("userManagement::password")}
        rules={[
          {
            required: true,
            message: "Password Is Required",
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name={"roles"}
        label={t("userManagement::roles")}
        rules={[
          { required: true, message: "At least one item must be selected" },
        ]}
        hasFeedback
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder={t("userManagement::chooseTheRoles")}
          dependencies={"options"}
        >
          {children}
        </Select>
      </Form.Item>
    </>
  );
};

CreateUserForm.propTypes = {
  getRoles: PropTypes.object,
};

export default CreateUserForm;
