import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./UpdaterReferral";

const UpdaterReferral = () => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Item label={t("general::name")} name={"name"}>
        <Input placeholder="name campaign" />
      </Form.Item>

      <Form.Item
        label={t("referral::ownerCampaignType")}
        name={"ownerCampaignType"}
        initialValue={t("referral::Wallet")}
      >
        <Input value={t("referral::Wallet")} disabled />
      </Form.Item>

      <Form.Item
        label={t("referral::ownerChargeType")}
        name={"ownerChargeType"}
        initialValue={t("referral::Credit")}
      >
        <Input value={"referral::Credit"} disabled />
      </Form.Item>

      <Form.Item
        label={t("referral::ownerChargeValue")}
        name={"ownerChargeValue"}
      >
        <Input type="number" placeholder="100" />
      </Form.Item>

      <Form.Item
        label={t("referral::targetCampaignType")}
        name={"targetCampaignType"}
        initialValue={t("referral::Wallet")}
      >
        <Input value={t("referral::Wallet")} disabled />
      </Form.Item>

      <Form.Item
        label={t("referral::targetChargeType")}
        name={"targetChargeType"}
        initialValue={t("referral::Credit")}
      >
        <Input value={"referral::Credit"} disabled />
      </Form.Item>
      <Form.Item
        label={t("referral::targetChargeValue")}
        name={"targetChargeValue"}
      >
        <Input type="number" palceholder="100" />
      </Form.Item>
    </>
  );
};

export default UpdaterReferral;
