import React, { useEffect, useState } from "react";
import { Button, Form, message, Modal, Row } from "antd";
import { errorMessageHandler, Log, logType } from "../../../app/util";
import { createService, updateService } from "../crud.api";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Create = (props) => {
  const {
    title = "Create",
    apiService,
    formProps,
    api,
    modal,
    button,
    successMessage = "Done successfully",
    // errorMessage = "Error sending information",
    justLog = false,
    fetchData,
    forUpdate = false,
    otherButtons,
    buttonInitial,
    modalInitial, // only for update
    formPropsInitial, // only for update
    // record, // only for update
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      forUpdate &&
      formPropsInitial &&
      JSON.stringify(formPropsInitial.initialValues) !== "{}"
    ) {
      form.setFieldsValue(formPropsInitial.initialValues);
    }
    // eslint-disable-next-line
  }, [formPropsInitial]);

  const handleCancel = () => {
    form.resetFields();
    setShowModal(!showModal);
  };

  function onSubmit(values) {
    setLoading(true);
    let data = { ...values };

    if (
      data &&
      formPropsInitial &&
      formPropsInitial.initialValues &&
      formPropsInitial.initialValues.id
    ) {
      data.id = formPropsInitial.initialValues.id;
    }

    if (formProps && formProps.onFinish) {
      data = formProps.onFinish(
        data,
        formPropsInitial && formPropsInitial.initialValues
          ? formPropsInitial.initialValues
          : {}
      );
    }

    if (justLog) {
      return false;
    }

    if (api) {
      if (forUpdate) {
        updateService(api, data)
          .then((response) => {
            message.success(successMessage, 3);
            setLoading(false);
            if (fetchData) {
              fetchData();
            }
            modalInitial.onCancel();
          })
          .catch((error) => {
            setLoading(false);
            message.error(errorMessageHandler(error), 3);
            Log(error, logType.error);
          });
      } else {
        createService(api, data)
          .then((response) => {
            message.success(successMessage, 3);
            setLoading(false);
            if (fetchData) {
              fetchData();
            }
            setShowModal(false);
          })
          .catch((error) => {
            setLoading(false);
            message.error(errorMessageHandler(error), 3);
            Log(error, logType.error);
          });
      }
    } else {
      apiService(data)
        .then((response) => {
          message.success(successMessage, 3);
          setLoading(false);
          if (fetchData) {
            fetchData();
          }
          if (forUpdate) {
            modalInitial.onCancel();
          } else {
            setShowModal(!showModal);
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(errorMessageHandler(error), 3);
          Log(error, logType.error);
        });
    }
  }

  return (
    <div className={"crud"}>
      <Row className="header">
        {!forUpdate && (
          <Button
            onClick={() => setShowModal(!showModal)}
            {...buttonInitial}
            {...button}
            className={"add-button-style"}
          >
            {button && button.title ? button.title : title}
          </Button>
        )}
        {otherButtons && otherButtons}
        <Modal
          title={modal && modal.title ? modal.title : title}
          visible={showModal}
          onCancel={handleCancel}
          cancelText={t("general::cancel")}
          okText={t("general::accept")}
          okType={"primary"}
          okButtonProps={{ loading: loading }}
          {...modalInitial}
          {...modal}
          onOk={() => {
            form.submit();
          }}
        >
          <Form
            form={form}
            name="register"
            scrollToFirstError
            {...formProps}
            onFinish={onSubmit}
          >
            {React.cloneElement(props.children, {
              showModal: forUpdate ? modalInitial.visible : showModal,
              form,
            })}
          </Form>
        </Modal>
      </Row>
    </div>
  );
};

Create.propTypes = {
  title: PropTypes.string,
  apiService: PropTypes.func,
  formProps: PropTypes.object,
  api: PropTypes.string,
  modal: PropTypes.object,
  button: PropTypes.object,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  justLog: PropTypes.bool,
  fetchData: PropTypes.func,
  forUpdate: PropTypes.bool,
  modalInitial: PropTypes.object,
  formPropsInitial: PropTypes.object,
  otherButtons: PropTypes.node,
  buttonInitial: PropTypes.object,
};

export default Create;
