import React, { useState } from "react";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import {
  addCodeStroller,
  addStrollerService,
  getStrollerService,
  updateStrollerService,
} from "./stroller.api";
import CreateStrollerForm from "./components/createStrollerForm/CreateStrollerForm";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Badge, Card, Image, Modal, Progress } from "antd";
import QRCode from "qrcode.react";
import "./stroller.style.scss";
import plus from "../../../../assets/plus.svg";
import CustomSingleSelectMall from "../../../customFilters/CustomSingleSelectMall";
import CustomFilterAdditionalCode from "../../../customFilters/CustomFilterAdditionalCode";
import CustomFilterCode from "../../../customFilters/CustomFilterCode";
import ExtraInfo from "./components/extraInfo/ExtraInfo";
import { showDetailDate } from "../../../../app/util";

const Stroller = () => {
  const { t } = useTranslation();
  const { parentId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [records, setRecords] = useState({});
  const [loading, setLoading] = useState(false);
  const [oldCode, setOldCode] = useState();
  const [extraInfoPopup, setExtraInfoPopup] = useState(false);

  const renderStrokeColor = (batteryCapacity) => {
    return batteryCapacity > 61
      ? "#52c41a"
      : batteryCapacity >= 31 && batteryCapacity <= 60
      ? "#FF8000"
      : batteryCapacity > 0 && batteryCapacity <= 30
      ? "#CC0000"
      : "#000";
  };
  const updateStroller = async (params) => {
    if (oldCode === params.code) {
      const resUpdate = await updateStrollerService(params);
      return resUpdate;
    } else {
      const resCode = await addCodeStroller(params);
      const resUpdate = await updateStrollerService(params);
      return [resCode, resUpdate];
    }
  };
  const columns = [
    {
      title: t("carts::cartId"),
      dataIndex: "additionalCode",
      key: "additionalCode",
      customFilter: CustomFilterAdditionalCode,
    },
    {
      title: "Operator",
      dataIndex: "mallName",
      key: "mallName",
      customFilter: CustomSingleSelectMall,
    },
    {
      title: "Lock QR Code Status",
      dataIndex: "currentState",
      key: "code",
      customFilter: CustomFilterCode,
      render: (currentState) => {
        switch (currentState) {
          case "OUT_OF_SERVICE":
            return (
              <Badge
                className="badge-width "
                color={"black"}
                text={t("carts::outOfService")}
              />
            );
          case "LOCKED":
            return (
              <Badge
                className="badge-width locked-badge"
                color={"green"}
                text={"Online"}
              />
            );
          case "UNLOCKED":
            return (
              <Badge className="badge-width " color={"red"} text={"Rented"} />
            );
          default:
            return currentState;
        }
      },
    },
    {
      title: t("general::batteryCapacity"),
      dataIndex: "batteryCapacity",
      key: "batteryCapacity",
      searchable: true,
      render: (text) => {
        if (!text) {
          return "-";
        }
        text = Number(text);
        return (
          <div style={{ textAlign: "center", marginBottom: "1em" }}>
            <Progress
              percent={text}
              strokeColor={renderStrokeColor(text)}
              steps={3}
              showInfo={false}
            />
            <div>{text}%</div>
          </div>
        );
      },
    },
    {
      title: t("general::publishDate"),
      dataIndex: "createdDateMilli",
      key: "createdDateMilli",
      sorter: true,
      render: (text) => {
        return showDetailDate(text);
      },
    },
  ];

  function toggle() {
    setIsOpen(!isOpen);
  }

  async function fetchStroller(params) {
    setLoading(true);
    let resp = await getStrollerService(params, parentId);
    setLoading(false);
    return resp;
  }

  return (
    <Container classNames="contents" loading={loading}>
      <Crud
        otherAction={[
          {
            name: t("qrCode"),
            func: (record) => {
              setIsOpen(!isOpen);
              setRecords(record);
            },
          },
          {
            name: t("lock"),
            func: (record) => {
              setExtraInfoPopup(record && record.locks);
            },
          },
        ]}
        get={{
          apiService: (params) => fetchStroller(params),
          columns,
        }}
        create={{
          apiService: (params) => addStrollerService(params),
          modal: {
            title: t("carts::createStroller"),
          },
          button: {
            title: t("carts::createStroller"),
            icon: (
              <Image src={plus} className="icon-add-items" preview={false} />
            ),
          },
        }}
        update={{
          apiService: (params) => updateStroller(params),
          onInitial: (val) => {
            setOldCode(val.code);
            return val;
          },
          modal: {
            title: t("Update"),
          },
        }}
        // remove={{
        //   api: API,
        // }}
      >
        <CreateStrollerForm />
      </Crud>
      <Modal
        centered
        title={t("carts::qrCode")}
        visible={isOpen}
        onCancel={toggle}
        // okText={t("general::close")}
        width={"45%"}
        onOk={() => window.print()}
        okText={t("general::print")}
      >
        <Card type="flex" align="middle" style={{ width: "100%" }}>
          <QRCode value={records.code} size={150} />
          <p
            className="code"
            style={{
              marginTop: "-.5rem",
              marginBottom: "unset",
              fontSize: "3.5rem",
              fontWeight: "400",
            }}
          >
            {records.code}
          </p>
        </Card>
      </Modal>

      <ExtraInfo
        extraInfoPopup={extraInfoPopup}
        setExtraInfoPopup={setExtraInfoPopup}
      />
    </Container>
  );
};

export default Stroller;
