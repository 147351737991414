import React from "react";
import { Layout } from "antd";
import PropTypes from "prop-types";
import { adminRoutes } from "../../../../app/router";
import RenderMenuItems from "./RenderMenuItems";
import Config from "../../../../app/config";
import "./sidebar.style.scss";
import AppVersion from "./components/AppVersion";

const { Sider } = Layout;

const SideBar = (props) => {
  const { collapsed, setCollapsed } = props;

  return (
    <Sider
      trigger={null}
      collapsible={false}
      collapsed={collapsed}
      theme={"light"}
      width={270}
      className={"sidebar hide-print"}
      breakpoint={"md"}
      onBreakpoint={(broken) => setCollapsed(broken)}
    >
      <div className="logo">
        <img src={Config.appLogo} alt="logo" className={"image"} />
        <h1
          className={"text"}
          style={{ display: `${collapsed ? "none" : ""}` }}
        >
          {Config.appName}
        </h1>
      </div>
      <RenderMenuItems routes={adminRoutes} />
      <AppVersion />
    </Sider>
  );
};

SideBar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
};

export default SideBar;
