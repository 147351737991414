import HttpService from "../../../../app/apiService";

const API = "v1/subscriber/profile";
const ADDRESS = "/v1/location/countries";
const StripeConnectAPI = "/v1/cuddle/pay/stripe-connect";
const IMAGE = "/v1/subscriber/profile";

export const getProfile = () => {
  return HttpService.get(API);
};

export const updateProfileService = (data) => {
  return HttpService.patch(API, data);
};

export const getCountryService = () => {
  return HttpService.get(ADDRESS);
};

export const getStateService = (data) => {
  let params;
  if (data) {
    params = {
      country: data,
    };
  }
  return HttpService.get(`${ADDRESS}/states`, { params });
};

export const getCityService = (data) => {
  let params;
  if (data) {
    params = { country: data.country, state: data.state };
  }

  return HttpService.get(`${ADDRESS}/states/cities`, { params });
};

export const hasStripeConnect = () => {
  return HttpService.get(StripeConnectAPI);
};

export const processStripeConnectFlow = () => {
  return HttpService.post(StripeConnectAPI);
};

export const getProfilePhoto = () => {
  return HttpService.get(IMAGE, {
    responseType: "arraybuffer",
  });
};

export const addProfilePhoto = (params) => {
  return HttpService.post(IMAGE, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
