import React, { useState } from "react";
import { Button, Modal } from "antd";
import SideLocation from "./components/sideLocation/SideLocation";
import { useTranslation } from "react-i18next";

const Location = ({ onChange, value, titleTranslate }) => {
  const { t } = useTranslation();
  const [openAddLocationModal, setOpenAddLocationModal] = useState(false);
  const showAddLocationModal = () => setOpenAddLocationModal(true);
  const handleOk = () => setOpenAddLocationModal(false);
  const handleCancel = () => setOpenAddLocationModal(false);

  return (
    <div className="flex-center">
      <Button type="primary" onClick={showAddLocationModal}>
        {value && value.lat && value.lng ? (
          <> {t("content::editLocation")} </>
        ) : (
          <> {t("content::addLocation")} </>
        )}
      </Button>

      <Modal
        title={titleTranslate}
        visible={openAddLocationModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t("general::accept")}
        cancelText={t("general::close")}
        // name={title}
        className="add-location-modal"
        width={"70vw"}
      >
        <div className="add-location-wrapper">
          <SideLocation
            locationInfo={value ? value : ""}
            setLocationInfo={(val) => {
              if (val && val.lat && val.lng) {
                onChange(val);
              }
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Location;
