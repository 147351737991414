import { Button, Form, Input, Modal, Tree } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getPrivilegesChildren } from "../../../../../userManagement/components/privileges/privileges.action";
import { UnUpdatedRoles } from "../../roles.constant";

const FormComponent = (props) => {
  const { selectedRows, setSelectedRows, showModal } = props;
  const [apiData, setApiData] = useState({});
  const { privilegesReducer } = useSelector((state) => state);
  const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation();
  const roleName = props.record && props.record.name;

  const checkRolesName = (roleName) => {
    const result = UnUpdatedRoles.find(
      (unUpdatedRole) => roleName === unUpdatedRole
    );
    setDisabled(result);
  };
  useEffect(() => {
    let temp = [];
    temp = getPrivilegesChildren(privilegesReducer.apiList);
    setApiData(temp);
    checkRolesName(roleName);
    // eslint-disable-next-line
  }, []);

  // Modal
  const [visible, setVisible] = useState(false);
  const openModal = () => setVisible(!visible);
  const closeModal = () => setVisible(false);

  useEffect(() => {
    if (!showModal) {
      setSelectedRows([]);
    }
    // eslint-disable-next-line
  }, [showModal]);

  const onCheck = (checkedKeys) => {
    setSelectedRows(checkedKeys);
  };
  useEffect(
    () => {
      checkRolesName(roleName);
    },
    // eslint-disable-next-line
    [roleName]
  );

  return (
    <>
      <Form.Item
        label={t("userManagement::roleName")}
        name={"name"}
        rules={[{ required: true }]}
        hasFeedback
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label={t("userManagement::privileges")}
        name={"privileges"}
        hasFeedback
        dependencies={selectedRows}
      >
        <Button type="primary" onClick={openModal}>
          {t("userManagement::selectPrivileges")}
        </Button>
        <div>
          {t("userManagement::numberOfSelectedPrivileges")}:
          {selectedRows ? selectedRows.length : "0"}
        </div>
        <Modal
          title="Access"
          visible={visible}
          onOk={closeModal}
          onCancel={closeModal}
          cancelText={"Cancel"}
          okText={"Ok"}
          okType={"primary"}
        >
          <Tree
            checkable
            onCheck={onCheck}
            treeData={apiData}
            defaultCheckedKeys={selectedRows}
            className={"ltr"}
          />
        </Modal>
      </Form.Item>
    </>
  );
};

export default FormComponent;
