import { languageSelectorConstant } from "./languageSelector.constant";

export const changeLanguage = (language) => (dispatch) => {
  dispatch({
    type: languageSelectorConstant.CHANGE_LANGUAGE,
    payload: {
      language,
    },
  });
};
