import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { getCookieLanguages } from "./util";

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: getCookieLanguages() || "en",
    fallbackLng: "en",
    detection: {
      // order and from where user language should be detected
      order: ["cookie", "querystring", "localStorage", "navigator", "htmlTag"],
      caches: ["cookie"],
      cookieMinutes: 160,
      lookupQuerystring: "lang",
      lookupFromPathIndex: 0,
    },
    backend: {
      /* translation file path */
      loadPath: "/i18n/translations/{{lng}}.json",
      allowMultiLoading: false,
    },
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    fallbackNS: "translations",
    nsSeparator: "::",
    keySeparator: "::",
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: false,
      useSuspense: false,
    },
  });

export default i18n;
