import React, { useEffect, useState } from "react";
import { Select } from "antd";

const { Option } = Select;

const CustomFilterTicket = (props) => {
  const { setFilter, item, title, reset, setReset } = props;
  const [value, setValue] = useState();
  const customFilterData = [
    {
      label: "Opened",
      value: "OPENED",
    },
    // {
    //   label: "Processing",
    //   value: "PROCESSING",
    // },
    {
      label: "Closed",
      value: "CLOSED",
    },
  ];

  const handleConfirm = (value) => {
    setValue(value);
  };

  useEffect(
    () => {
      setFilter((prevData) => ({
        ...prevData,
        [item]: value,
      }));
    },
    // eslint-disable-next-line
    [value]
  );

  useEffect(
    () => {
      setValue("");
      setReset(false);
    },
    // eslint-disable-next-line
    [reset]
  );

  return (
    <Select
      allowClear
      style={{ width: "70%" }}
      onSelect={handleConfirm}
      value={value}
      placeholder={`Enter ${title}`}
    >
      {customFilterData.map((item, index) => {
        return (
          <Option key={index} value={item.value}>
            {item.label}
          </Option>
        );
      })}
    </Select>
  );
};
export default CustomFilterTicket;
