import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, Input, message, Row, Spin } from "antd";
import "./login.style.scss";
import Config from "../../app/config";
import { useHistory } from "react-router-dom";
import { isLogin } from "./login.utils";
import { handleLogin } from "./login.api";
import { useTranslation } from "react-i18next";

const FormItem = Form.Item;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  if (isLogin()) {
    history.push("/");
  }
  const { t } = useTranslation();

  const handleSubmit = (values) => {
    setLoading(true);
    handleLogin(values)
      .then((resp) => {
        message.success(t("general::loginSuccessfully"), 3);
        const {
          access_token,
          refresh_token,
          expires_in /*token_type, scope*/,
        } = resp.data;

        if (access_token) {
          localStorage.setItem("access_token", access_token);
        }

        if (refresh_token) {
          localStorage.setItem("refresh_token", refresh_token);
        }

        if (expires_in) {
          localStorage.setItem("expires_in", expires_in);
        }

        if (window.location.pathname.search("/login") > -1) {
          window.location.pathname = "/";
        } else {
          window.location.reload();
          debugger;
          // TODO: to better, why here?
        }
      })
      .catch(() => {
        setLoading(false);
        message.error(t("general::errorOnReceivingSecurityKey"), 3);

        localStorage.clear();
        sessionStorage.clear();

        if (window.location.pathname.search("/login") < 0) {
          window.location.pathname = "/login";
        }
      });
  };

  return (
    <div className="login">
      <div className={"form"}>
        <div className={"logo"}>
          <img alt="logo" src={Config.loginLogo} />
        </div>
        <Spin spinning={loading}>
          <Form onFinish={handleSubmit}>
            <FormItem name="username" rules={[{ required: true }]} hasFeedback>
              <Input placeholder={t("general::username")} />
            </FormItem>
            <FormItem name="password" rules={[{ required: true }]} hasFeedback>
              <Input.Password
                type="password"
                placeholder={t("general::password")}
              />
            </FormItem>
            <Row>
              <Button type="primary" htmlType="submit">
                {t("general::login")}
              </Button>
            </Row>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

Login.propTypes = {
  form: PropTypes.object,
};

export default Login;
