import { HttpService } from "../../../../app/apiService";

export const API = "v1/faq/category";

export const getCategoriesService = (
  params = { pageNumber: 0, pageSize: 10 }
) => {
  return HttpService.get(`${API}`, { params });
};

export const editCategoryService = (data) => {
  return HttpService.put(`${API}/${data.id}`, data);
};
