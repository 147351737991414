import { HttpService } from "../../../../app/apiService";
import { HistoyLink } from "./history.constant";

export const API = "v1/cuddle/rents";
export const API_QA = "v1/faq/que-ans";
export const API_REPORT = "/v1/cuddle/rents/reports";

export const getReportFinancialService = (
  params = { pageNumber: 0, pageSize: 10 }
) => {
  if (params) {
    params = {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      sort: "DESC",
    };
  }

  return HttpService.get(`${API_REPORT}/integrated`, { params });
};

export const getDataHistory = (params, historyType, parentId) => {
  let filter;
  if (params) {
    if (parentId) {
      if (historyType === HistoyLink.STROLLER) {
        params.strollerIdSet = parentId;
      } else if (historyType === HistoyLink.SUBSCRIBER) {
        params.subscriberIdSet = parentId;
      }
    }

    if (!params.strollerId) {
      params.strollerIdSet = null;
    } else {
      params.strollerIdSet = params.strollerId;
      delete params.strollerId;
    }

    if (params.from && params.to) {
      filter = {
        rentFrom: params.from.format("YYYY-MM-DDTHH:mm:ss"),
        rentTo: params.to.format("YYYY-MM-DDTHH:mm:ss"),
      };
      delete params.from;
      delete params.to;
    }
    if (params.mobile === "") {
      params.mobile = undefined;
    }
    if (params.email === "") {
      params.email = undefined;
    }
    params = {
      ...params,
      ...filter,
      sortKey: "startTime",
      sort: "DESC",
    };

    return HttpService.get(`${API}/reports/integrated`, { params });
  }
};

export const postEndRentService = (rentId) => {
  return HttpService.post(`${API}/end/${rentId}`);
};

export const getQueAnsService = (id) => {
  return HttpService.get(`${API_QA}/${id}`);
};

export const updatePriceService = (rentId, values) => {
  return HttpService.patch(`${API}/${rentId}/invoice`, values);
};
