import React, { useState } from "react";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import { getReportFinancialService } from "../../reports.api";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import ExtraInfo from "./components/extraInfo/ExtraInfo";
import { showDetailDate, convertSecondsToDhms } from "../../../../app/util";
import CustomSelectMall from "../../../customFilters/CustomSelectMall";

const Stroller = () => {
  const { t } = useTranslation();
  const [extraInfoPopup, setExtraInfoPopup] = useState(false);

  const columns = [
    {
      title: t("order::orderNumber"),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      searchable: true,
    },

    {
      title: t("general::mobile"),
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: t("general::email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("order::duration") + " (DD:HH:MM:SS)",
      dataIndex: "durationInSeconds",
      key: "durationInSeconds",
      render: (durationInSeconds) => {
        return convertSecondsToDhms(durationInSeconds);
      },
    },
    {
      title: t("order::cartId"),
      dataIndex: "additionalCode",
      key: "additionalCode",
      // customFilter: CustomFilterStroller,
      searchable: true,
    },
    {
      title: t("order::state"),
      dataIndex: "rentState",
      key: "rentState",
      customTitle: "Operator",
      customFilter: CustomSelectMall,
      render: (text) => {
        if (text && text === "FREE") {
          return <span style={{ color: "green" }}>Available</span>;
        } else if (text && text === "RENTED") {
          return <span>Rented</span>;
        }
      },
    },
    {
      title: t("order::createDate"),
      dataIndex: "rentStartTime",
      key: "rentStartTime",
      sorter: true,
      filter: "fromTo",
      render: (text) => {
        return showDetailDate(text);
      },
    },
    {
      title: t("general::extraInfo"),
      dataIndex: "usageDuration",
      key: "usageDuration",
      render: (text, record) => {
        return (
          <Button type="primary" onClick={() => setExtraInfoPopup(record)}>
            {t("general::viewInformation")}
          </Button>
        );
      },
    },
  ];

  // const handleEndRent = (rentId) => {
  //   setLoading(true);
  //   postEndRentService(rentId)
  //     .then((response) => {
  //       message.success(t("general::successEndRent"), 3);
  //     })
  //     .catch((error) => {
  //       message.error(t("general::errorGettingInformation"), 3);
  //     })
  //     .finally(() => setLoading(false));
  // };

  return (
    <Container>
      <Crud
        get={{
          apiService: (params) => {
            return getReportFinancialService(params);
          },
          columns,
        }}
      />

      <ExtraInfo
        extraInfoPopup={extraInfoPopup}
        setExtraInfoPopup={setExtraInfoPopup}
      />
    </Container>
  );
};

export default Stroller;
