import React, { useEffect, useState } from "react";
import Container from "../container/Container";
import "./dashboard.style.scss";
import { Button, Col, message, Result, Row } from "antd";
import DashboardCard from "./dasboardCard";
import { getReportsService } from "./dashboard.api";
import { convertCentToDollar, convertMinuteToDhm } from "../../app/util";
import { useTranslation } from "react-i18next";

import {
  ApiOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import FilterFromTo from "../crud/components/FilterFromTo";

const dashboardIcon = {
  numberOfMalls: <ShopOutlined />,
  numberOfRents: <ApiOutlined />,
  numberOfStrollers: <ShoppingCartOutlined />,
  numberOfSubscribers: <UserOutlined />,
  totalDurationMinutes: <ClockCircleOutlined />,
  totalPrice: <DollarOutlined />,
};

const dashboardColor = {
  numberOfMalls: "#ab40e8",
  numberOfRents: "#5cb8e7",
  numberOfStrollers: "#f0daa4",
  numberOfSubscribers: "#4ec5a5",
  totalDurationMinutes: "#7788e0",
  totalPrice: "#ff5f31",
};
const Dashboard = (props) => {
  const { t } = useTranslation();
  const [reports, setReports] = useState({
    numberOfMalls: 0,
    numberOfRents: 0,
    numberOfStrollers: 0,
    numberOfSubscribers: 0,
    totalDurationMinutes: 0,
    totalPrice: 0,
  });
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [filter, setFilter] = useState();
  const [reset, setReset] = useState(false);

  useEffect(() => {
    getReports();
    // eslint-disable-next-line
  }, []);

  function getReports(filter) {
    setLoading(true);
    setHasError(false);

    getReportsService(filter)
      .then((res) => {
        setReports({
          ...reports,
          ...res.data,
        });
      })
      .catch((err) => {
        message.error(t("general::errorConnectingToTheServer"), 3);
        setHasError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const resetHolder = () => {
    setFilter(null);
    setReset(true);
    setFilter(null);
    getReports();
  };

  const confirmHolder = () => {
    getReports(filter);
  };

  return (
    <Container className="dashboard" loading={loading}>
      <Row className="filters">
        <Col xs={24} sm={24} md={24} lg={8}>
          <div>Date Filter:</div>
          <FilterFromTo
            setFilter={setFilter}
            setReset={setReset}
            reset={reset}
          />
        </Col>
        <Col
          style={{
            alignItems: "flex-end",
            display: "flex",
            marginTop: "5px",
          }}
          xs={24}
          sm={24}
          md={10}
          lg={3}
        >
          <Button
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
            type="primary"
            onClick={confirmHolder}
          >
            Apply
          </Button>
        </Col>
        <Col
          style={{
            alignItems: "flex-end",
            display: "flex",
            marginTop: "5px",
          }}
          xs={24}
          sm={24}
          md={10}
          lg={3}
        >
          <Button
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
            type="primary"
            onClick={resetHolder}
          >
            Reset All
          </Button>
        </Col>
      </Row>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ marginTop: "2em" }}
      >
        {!hasError ? (
          Object.keys(reports).map((item, index) => {
            return (
              <Col
                className="card-space"
                key={`index-${index}`}
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
              >
                {item === "totalPrice" ? (
                  <DashboardCard
                    title={item}
                    value={
                      convertCentToDollar(reports[item]) === "-"
                        ? 0
                        : convertCentToDollar(reports[item])
                    }
                    icons={dashboardIcon}
                    color={dashboardColor}
                  />
                ) : item === "totalDurationMinutes" ? (
                  <DashboardCard
                    title={item}
                    value={
                      convertMinuteToDhm(reports[item]) === "-"
                        ? 0
                        : convertMinuteToDhm(reports[item])
                    }
                    icons={dashboardIcon}
                    color={dashboardColor}
                  />
                ) : (
                  <DashboardCard
                    title={item}
                    value={reports[item]}
                    icons={dashboardIcon}
                    color={dashboardColor}
                  />
                )}
              </Col>
            );
          })
        ) : (
          <div className="result">
            <Result
              status="error"
              title="data Failed"
              subTitle="error GettingIn formation."
              extra={[
                <Button key="buy" onClick={getReports}>
                  {t("general::tryAgain")}
                </Button>,
              ]}
            />
          </div>
        )}
      </Row>
    </Container>
  );
};

export default Dashboard;
