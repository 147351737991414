import React, { useEffect } from "react";
import "../assets/styles/app.scss";
import { BrowserRouter, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import AppContainer from "../components/appContainer/AppContainer";
import PrivateRoute from "../components/privateRoute/PrivateRoute";
import { LoginRoutes } from "../components/login/login.routes";
import { DashboardRoutes } from "../components/dashboard/dashboard.route";
import { AdministrationRoutes } from "../components/administration/administration.route";
import { haveAccess } from "../components/userManagement/components/privileges/privileges.utils";
import { OperationRoutes } from "../components/operation/operation.route";
import { ReportsRoutes } from "../components/reports/reports.route";

export let appRoutes = [
  ...DashboardRoutes,
  ...OperationRoutes,
  ...ReportsRoutes,
  ...LoginRoutes,
  ...AdministrationRoutes,

  // {
  //   titleFa: "404",
  //   component: NotFound,
  //   exact: false,
  //   showInSideBar: false,
  //   isPublicRoute: true,
  // },
];
appRoutes.forEach((item, index) => {
  let id = index + 1 + "-";
  let children = item.children;

  if (children) {
    children.forEach((child, childIndex) => {
      children[childIndex] = { ...child, id: `${id}${childIndex}` };
    });
  }
  appRoutes[index] = { ...item, id, ...children };
});

export const adminRoutes = appRoutes;

export default function App({ setLanguage }) {
  const { languageSelectorReducer } = useSelector((state) => state);
  useEffect(() => {
    setLanguage(languageSelectorReducer.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageSelectorReducer.language]);
  return (
    <>
      <AppContainer>
        <BrowserRouter>
          {adminRoutes.map((item, index) => {
            let access = false;
            if (item.children && item.children.length > 0) {
              item.children.forEach((child) => {
                if (access === false && haveAccess(child.permissions)) {
                  access = true;
                }
              });
            } else {
              access = true;
            }
            if (access && item.permissions && item.permissions.length > 0) {
              access = haveAccess(item.permissions);
            }

            if (!access) {
              return <React.Fragment key={`route-wrapper-child-${index}`} />;
            }
            return (
              <React.Fragment key={`route-wrapper-${index}`}>
                {item.isPublicRoute ? (
                  <Route
                    exact={item.exact}
                    path={item.path}
                    component={item.component}
                    item={{ ...item }}
                    key={`route-${index}`}
                  />
                ) : (
                  <PrivateRoute
                    exact={item.exact}
                    path={item.path}
                    component={item.component}
                    item={{ ...item }}
                    key={`route-private-${index}`}
                  />
                )}
                {item.children &&
                  item.children.length > 0 &&
                  item.children.map((child, indexChild) => {
                    let access = child.permissions
                      ? haveAccess(child.permissions)
                      : true;

                    if (!access) {
                      return (
                        <React.Fragment
                          key={`route-wrapper-child-${indexChild}`}
                        />
                      );
                    }
                    return (
                      <React.Fragment key={`route-wrapper-child-${indexChild}`}>
                        {item.isPublicRoute ? (
                          <Route
                            exact={child.exact}
                            path={child.path}
                            component={child.component}
                            item={{ ...child }}
                            key={`route-child-${indexChild}`}
                          />
                        ) : (
                          <PrivateRoute
                            exact={child.exact}
                            path={child.path}
                            component={child.component}
                            key={`route-child-private-${indexChild}`}
                            item={{ ...child }}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            );
          })}
        </BrowserRouter>
      </AppContainer>
    </>
  );
}
