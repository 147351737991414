import React, { useEffect, useState } from "react";
import { Divider, Form, Input, Select, Space } from "antd";
import Location from "../../../../operation/pages/location/Location";
import {
  getCityService,
  getCountryService,
  getStateService,
} from "../profile.api";

const AddressList = (props) => {
  const { field, index } = props;
  const { Option } = Select;
  const { TextArea } = Input;
  const [country, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("United States");
  const [province, setProvince] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [city, setCity] = useState([]);

  useEffect(
    () => {
      getCountryService().then((response) => {
        setCountry(response.data);
      });
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (selectedCountry) {
        getStateService(selectedCountry).then((response) => {
          setProvince(response.data);
        });
      }
    },
    // eslint-disable-next-line
    [selectedCountry]
  );

  useEffect(
    () => {
      if (selectedProvince) {
        getCityService({
          country: selectedCountry,
          state: selectedProvince,
        }).then((response) => {
          setCity(response.data);
        });
      }
    },
    // eslint-disable-next-line
    [selectedProvince]
  );

  return (
    <>
      <Space key={index} style={{ width: "100%" }} direction="vertical">
        {window.outerWidth > 480 && <Divider />}
        <Form.Item
          {...field}
          label="Address"
          name={[field.name, "address"]}
          fieldKey={[field.fieldKey, "address"]}
        >
          <TextArea placeholder={"Enter address"} />
        </Form.Item>
        <Form.Item
          {...field}
          label="Country"
          name={[field.name, "country"]}
          fieldKey={[field.fieldKey, "country"]}
        >
          <Select
            showSearch
            placeholder="Search country"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            value={selectedCountry}
            onChange={(val) => setSelectedCountry(val)}
          >
            {country.map((country, index) => (
              <Option key={country.name} value={country.name}>
                {country.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          {...field}
          label="Province"
          name={[field.name, "province"]}
          fieldKey={[field.fieldKey, "province"]}
        >
          <Select
            showSearch
            placeholder="Search province"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            value={selectedProvince}
            onChange={(val) => setSelectedProvince(val)}
          >
            {province.map((province, index) => (
              <Option key={province.name} value={province.name}>
                {province.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          {...field}
          label="City"
          name={[field.name, "city"]}
          fieldKey={[field.fieldKey, "city"]}
        >
          <Select
            showSearch
            placeholder="Search city"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {selectedProvince &&
              city.map((city, index) => (
                <Option key={city.name} value={city.name}>
                  {city.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          {...field}
          label="Location"
          name={[field.name, "location"]}
          fieldKey={[field.fieldKey, "location"]}
        >
          <Location />
        </Form.Item>
      </Space>
    </>
  );
};
export default AddressList;
