import React from "react";
import { Descriptions, Modal } from "antd";
import PropTypes from "prop-types";
import {
  convertCentToDollar,
  showDetailDate,
} from "../../../../../../app/util";

const ExtraInfo = (props) => {
  const { extraInfoPopup, setExtraInfoPopup } = props;

  return (
    <Modal
      title={"Information"}
      visible={extraInfoPopup !== false}
      onOk={() => setExtraInfoPopup(false)}
      onCancel={() => setExtraInfoPopup(false)}
      cancelText={"Close"}
      okButtonProps={{ hidden: true }}
    >
      <Descriptions className={"subscriber-extra-info"} bordered>
        <Descriptions.Item span={24} label={"Start Time"}>
          {(extraInfoPopup &&
            extraInfoPopup.rentStartTime &&
            showDetailDate(extraInfoPopup.rentStartTime)) ||
            "-"}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={"End Time"}>
          {(extraInfoPopup &&
            extraInfoPopup.rentEndTime &&
            showDetailDate(extraInfoPopup.rentEndTime)) ||
            "-"}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={"Payment"}>
          {convertCentToDollar(extraInfoPopup.price)}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={"Time Of Payment"}>
          {(extraInfoPopup &&
            extraInfoPopup.paymentTime &&
            showDetailDate(extraInfoPopup.paymentTime.replace("+00:00", ""))) ||
            "-"}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={"Operator"}>
          {extraInfoPopup.mallName}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={"Lock ID"}>
          {extraInfoPopup.strollerId}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={"Customer Review"}>
          {extraInfoPopup.rate ? (
            <>
              <p>
                <label>Value</label> : {extraInfoPopup.rate.value}
              </p>
              <p>
                <label>Positive Opinions</label> :
                {extraInfoPopup.rate &&
                extraInfoPopup.rate.positiveOpinions &&
                extraInfoPopup.rate.positiveOpinions !== null &&
                extraInfoPopup.rate.positiveOpinions.length !== 0 ? (
                  extraInfoPopup.rate.positiveOpinions.map((item) => (
                    <li>{item}</li>
                  ))
                ) : (
                  <li>No positive opinions have been posted</li>
                )}
              </p>
              <p>
                <label>Negative Opinions</label> :
                {extraInfoPopup.rate &&
                extraInfoPopup.rate.negativeOpinions &&
                extraInfoPopup.rate.negativeOpinions !== null &&
                extraInfoPopup.rate.negativeOpinions.length !== 0 ? (
                  extraInfoPopup.rate.negativeOpinions.map((item) => (
                    <li>{item}</li>
                  ))
                ) : (
                  <li>No negative opinions have been posted</li>
                )}
              </p>
              <p>
                <label>Comment</label> :
                {extraInfoPopup.rate.comment
                  ? extraInfoPopup.rate.comment
                  : "No comment opinions have been posted"}
              </p>
            </>
          ) : (
            "No Customer Review has been registered"
          )}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

ExtraInfo.propTypes = {
  extraInfo: PropTypes.any,
  setExtraInfoPopup: PropTypes.func,
};

export default ExtraInfo;
