import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
// import CustomImagePicker from "../../../../../crud/pages/CustomImagePicker";

const FormComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item
        label={t("content::categoryTitle")}
        name={"title"}
        rules={[{ required: true }]}
        hasFeedback
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("general::description")}
        name={"description"}
        rules={[{ required: true }]}
        hasFeedback
      >
        <Input />
      </Form.Item>
      {/* <Form.Item
        label={t("content::imagePath")}
        name={"imagePath"}
        rules={[{ required: true }]}
        hasFeedback
      >
        <CustomImagePicker />
      </Form.Item> */}
    </>
  );
};

export default FormComponent;
