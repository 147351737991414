import React from "react";
import { Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";

const RateUpdateForm = (props) => {
  const { record } = props;
  const { t } = useTranslation();

  return (
    <>
      <Form.Item label={t("rate::rateValueEnum")} name={"rateValueEnum"}>
        <Input disabled />
      </Form.Item>
      {(record.rateValueEnum === "THREE" ||
        record.rateValueEnum === "FOUR") && (
        <Form.Item
          label={t("rate::opinions")}
          name={"positiveOpinions"}
          rules={[{ required: true }]}
        >
          <Select placeholder="Positive Opinions" mode="tags"></Select>
        </Form.Item>
      )}
      {(record.rateValueEnum === "ONE" || record.rateValueEnum === "TWO") && (
        <Form.Item
          label={t("rate::opinions")}
          name={"negativeOpinions"}
          rules={[{ required: true }]}
        >
          <Select placeholder="Negative Opinion" mode="tags"></Select>
        </Form.Item>
      )}

      <Form.Item
        label={t("rate::title")}
        name={"title"}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
    </>
  );
};

export default RateUpdateForm;
