import { RolesReduxTypes } from "./roles.constant";
import { getRoles } from "./roles.api";

export const fetchRoles = () => (dispatch) => {
  dispatch({
    type: RolesReduxTypes.FETCH_ROLES,
  });

  getRoles()
    .then((r) => {
      const list = roleParse(r.data.content);
      sessionStorage.setItem("roles", JSON.stringify(list));
      dispatch({
        type: RolesReduxTypes.FETCH_ROLES_SUCCESS,
        payload: {
          list,
          content: r.data.content,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: RolesReduxTypes.FETCH_ROLES_FAILURE,
        payload: error,
      });
    });
};

function roleParse(roles) {
  if (!roles || (roles && roles.length === 0)) {
    return {};
  }

  let temp = {};
  roles.forEach((r) => {
    const { name, privileges } = r;
    temp[name] = privileges;
  });

  return temp;
}
