import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isLogin } from "../login/login.utils";

const isAuthenticated = isLogin();
const PrivateRoute = ({ component: Component, render: Render, ...props }) => {
  return (
    <Route
      {...props}
      render={() =>
        isAuthenticated ? (
          Render ? (
            <Render {...props} auth={isAuthenticated} />
          ) : (
            <Component {...props} auth={isAuthenticated} />
          )
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
};

export default PrivateRoute;
