import React, { useEffect, useState } from "react";
import { Input, message, Select } from "antd";
import { useTranslation } from "react-i18next";
import { getDateString } from "../../../app/util";

let fullYear = new Date().getFullYear();
const CustomDateInput = (props) => {
  let { id, value, onChange } = props;
  const { t } = useTranslation();
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(0);
  const yearList = [...Array(90).keys()];
  const daysList = [...Array(31).keys()];

  useEffect(() => {
    if ((day === 31 && month > 6) || (day === 30 && month === 12)) {
      message.error(t("general::incorrectDate"), 3);
    }
    // eslint-disable-next-line
  }, [day, month]);

  useEffect(() => {
    if (value && day === 0) {
      if (value.toString().search("/") > -1) {
        setStates(value);
      } else {
        let time = getDateString(value, {
          en: "YYYY/MM/DD",
          fa: "YYYY/MM/DD",
        });

        if (time && time.toString().search("/") > -1) {
          setStates(time);
        }
      }
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (day || year || month) {
      const result = `${checkVal(year)}/${checkVal(month)}/${checkVal(day)}`;
      onChange(result);
    }
  }, [year, month, day, onChange]);

  function setStates(time) {
    if (!time) {
      return false;
    }
    let result = time.split("/").map((element) => Number(element));
    setYear(result[0]);
    setMonth(result[1]);
    setDay(result[2]);
  }

  function checkVal(value) {
    let result = value;
    if (value < 10) {
      result = `0${value}`;
    }
    return result;
  }

  return (
    <Input.Group id={id} compact>
      <Select
        defaultValue={0}
        value={day}
        onChange={(value) => setDay(value)}
        style={{ width: 70 }}
      >
        <Select.Option value={0}>{t("general::day")}</Select.Option>
        {daysList.map((index) => {
          const day = index + 1;
          return (
            <Select.Option key={`day-${index}`} value={day}>
              {day}
            </Select.Option>
          );
        })}
      </Select>
      <Select
        defaultValue={0}
        value={month}
        onChange={(value) => setMonth(value)}
        style={{ width: 110 }}
      >
        <Select.Option value={0}>{t("general::month")}</Select.Option>
        <Select.Option key={`month-${1}`} value={1}>
          {" "}
          January{" "}
        </Select.Option>
        <Select.Option key={`month-${2}`} value={2}>
          {" "}
          February{" "}
        </Select.Option>
        <Select.Option key={`month-${3}`} value={3}>
          {" "}
          March{" "}
        </Select.Option>
        <Select.Option key={`month-${4}`} value={4}>
          {" "}
          April{" "}
        </Select.Option>
        <Select.Option key={`month-${5}`} value={5}>
          {" "}
          May{" "}
        </Select.Option>
        <Select.Option key={`month-${6}`} value={6}>
          {" "}
          June{" "}
        </Select.Option>
        <Select.Option key={`month-${7}`} value={7}>
          {" "}
          July{" "}
        </Select.Option>
        <Select.Option key={`month-${8}`} value={8}>
          {" "}
          August{" "}
        </Select.Option>
        <Select.Option key={`month-${9}`} value={9}>
          {" "}
          September{" "}
        </Select.Option>
        <Select.Option key={`month-${10}`} value={10}>
          {" "}
          October{" "}
        </Select.Option>
        <Select.Option key={`month-${11}`} value={11}>
          {" "}
          November{" "}
        </Select.Option>
        <Select.Option key={`month-${12}`} value={12}>
          {" "}
          December{" "}
        </Select.Option>
      </Select>
      <Select
        defaultValue={0}
        value={year}
        onChange={(value) => setYear(value)}
        style={{ width: 90 }}
      >
        <Select.Option value={0}>{t("general::year")}</Select.Option>
        {yearList.map((index) => {
          const year = fullYear - index;
          return (
            <Select.Option key={`year-${index}`} value={year}>
              {year}
            </Select.Option>
          );
        })}
      </Select>
    </Input.Group>
  );
};

export default CustomDateInput;
