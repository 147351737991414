import { Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Log, logType } from "../../../../../../app/util";
import "./UpdaterSubScribers";
import { getRoles } from "../../../../../administration/pages/roles/roles.api";

const { Option } = Select;
const UpdaterSubScribers = (props) => {
  const { t } = useTranslation();
  const [apiData, setApiData] = useState([]);
  const fetchRoles = () => {
    getRoles()
      .then((resp) => {
        let data = [];
        if (resp && resp.data) {
          data = resp.data.content;
        }

        // if response is array for better filter convert to object
        if (data.length) {
          let temp = [];
          data.forEach((item, index) => {
            temp.push(
              <Option key={index} value={item.name} name="options">
                {item.name}
              </Option>
            );
          });

          setApiData(temp);
        } else {
          setApiData(data);
        }
      })
      .catch((error) => {
        message.error(t("general::errorGettingInformation"), 3);
        Log(error, logType.error);
      });
  };

  function handleChange(value) {
    // console.log(`selected ${value}`);
  }

  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Input
        placeholder="id"
        type="hidden"
        name={"id"}
        rules={[{ required: true }]}
      />

      <Form.Item
        label={t("general::username")}
        name={"username"}
        rules={[{ required: true }]}
      >
        <Input placeholder="Name" disabled />
      </Form.Item>

      <Form.Item
        label={t("general::mobile")}
        name={"mobile"}
        rules={[{ required: true }]}
      >
        <Input placeholder="Mobile" />
      </Form.Item>

      <Form.Item
        label={t("general::roles")}
        name={"roles"}
        rules={[{ required: true }]}
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder={t("general::chooseTheRoles")}
          onChange={handleChange}
          dependencies={"options"}
          rules={[{ required: true }]}
        >
          {apiData}
        </Select>
      </Form.Item>
    </>
  );
};

export default UpdaterSubScribers;
