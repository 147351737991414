import { getPermissions } from "./privileges.api";
import { PrivilegesReduxTypes } from "./privileges.constant";

export const fetchPermissions = () => (dispatch) => {
  dispatch({
    type: PrivilegesReduxTypes.FETCH_PERMISSIONS,
  });

  getPermissions()
    .then((r) => {
      const list = parsPrivileges(r.data.apiList);
      sessionStorage.setItem("privileges", JSON.stringify(list));

      dispatch({
        type: PrivilegesReduxTypes.FETCH_PERMISSIONS_SUCCESS,
        payload: {
          list,
          apiList: r.data.apiList,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: PrivilegesReduxTypes.FETCH_PERMISSIONS_FAILURE,
        payload: error,
      });
    });
};

export function parsPrivileges(privileges = []) {
  let result = {};
  privileges.forEach((privilege, index) => {
    if (privilege && privilege.search("api.v1") > -1) {
      let privilegeArray = privilege.split(".");
      const apiVersion = privilegeArray[1];
      // check api version
      if (apiVersion !== "v1") {
        // Log("api version changed!: " + apiVersion, logType.warn);
      }
      // skip api/v1
      let privilegeUsable = privilegeArray.slice(2);
      const resultIndex = privilegeUsable[0];
      const resultVal = privilegeUsable.slice(1).join(".");

      if (result[resultIndex]) {
        result[resultIndex] = [...result[resultIndex], resultVal];
      } else {
        result[resultIndex] = [resultVal];
      }
    } else {
      // Log("api not valid: " + privilege, logType.warn);
    }
  });

  return result;
}

export function getPrivilegesChildren(privileges = []) {
  let result = [];
  let permissions = parsPrivileges(privileges);

  for (let title in permissions) {
    let children = permissions[title];
    children = children.map((i) => {
      return {
        title: `${title}.${i}`,
        key: `api.v1.${title}.${i}`,
      };
    });

    result.push({
      title,
      key: title,
      children,
    });
  }

  return result;
}
