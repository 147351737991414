import moment from "moment";
import axios from "axios";
import FileDownload from "js-file-download";
import { message } from "antd";
import setMultipleLang from "./config";

export const logType = {
  info: "information",
  warn: "warning",
  error: "error",
};
export const Log = (entry, type = logType.info) => {
  let environment = process.env.NODE_ENV || "development";
  if (environment !== "development") {
    return false;
  }

  if (type === logType.info) {
    console.log(entry);
  } else if (type === logType.warn) {
    console.warn(entry);
  } else if (type === logType.error) {
    console.error(entry);
  }
};

export function convertArrayToTitleObject(data) {
  if (!data || (data && JSON.stringify(data) === "{}")) {
    Log("not found data!", logType.error);
    return {};
  }

  let temp = [];
  data.forEach((item, index) => {
    temp.push({
      title: item,
      key: index + 1,
    });
  });

  return temp;
}

export function getNormalizeObj(array, index = "id") {
  let normalize = {};
  if (array && array.length > 0) {
    array.forEach((i) => {
      normalize[i[index]] = i;
    });
  }
  return normalize;
}

export function checkValue(val) {
  return typeof val !== "undefined" && val !== null && val !== "";
}

export function parseParentChildren(data, extraFields) {
  function searchAndGetChildren(data, result, item, fields) {
    let res = [];

    data.forEach((i) => {
      if (i.parentId === item.id) {
        let d = {};
        for (let prop in fields) {
          let val = fields[prop];
          d[prop] = i[val];
        }
        d.children = searchAndGetChildren(data, result, i, fields);
        res.push(d);
      }
    });

    return res;
  }

  let fields = { id: "id", parentId: "parentId", ...extraFields };
  let result = [];

  // set parent with parentId===null
  data.forEach((c) => {
    if (!c.parentId) {
      let d = {};
      for (let prop in fields) {
        let val = fields[prop];
        d[prop] = c[val];
      }

      result.push(d);
    }
  });

  result.forEach((i, index) => {
    result[index]["children" ? "children" : "childFolders"] =
      searchAndGetChildren(data, result, i, fields);
  });

  return result;
}

export function getFieldValue(data) {
  if (!data || !data.fields) {
    return "";
  }
  let result = "";
  if (data && data.fields) {
    result = [...data.fields];
  }
  return result;
}

export function downloadURL(url, name) {
  const element = document.createElement("a");
  element.setAttribute("href", url);
  element.innerText = "hello";
  element.setAttribute("download", name);
  // element.setAttribute("target", "_blank");
  element.style.display = "none";
  element.dispatchEvent(
    new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window })
  );
  document.body.appendChild(element);
  setTimeout(() => {
    document.body.removeChild(element);
  }, 100);
}

export function downloadWithAxios(url, name, setLoading) {
  function handleSetLoading(status = false) {
    if (setLoading) {
      setLoading(status);
    }
  }
  handleSetLoading(true);
  axios({
    url,
    method: "GET",
    responseType: "blob", // Important
  })
    .then((r) => {
      FileDownload(r.data, name);
      handleSetLoading();
    })
    .catch((e) => {
      message.error("Error downloading file", 3);
      console.log(e);
      handleSetLoading();
    });
}

export function getCookieLanguages() {
  let value = "; " + document.cookie;
  let parts = value.split("; i18next=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export function getDateString(
  date,
  format = {
    en: "YYYY/MM/DD - hh:mm",
    fa: "YYYY/MM/DD - hh:mm",
  }
) {
  if (!date) {
    return "";
  }

  try {
    let time = "";
    const isEn = getCookieLanguages() === "en";
    if (isEn || setMultipleLang === false) {
      time = moment(date).format(format.en);
    }
    time = moment(date).format(format.fa);

    return time;
  } catch (e) {
    return date;
  }
}

export function milliSecondToTime(millisecond) {
  let millisecondNumber;
  if (!millisecond || millisecond === 0) {
    return "-";
  }
  if (typeof millisecond !== "number") {
    millisecondNumber = Number(millisecond);
  } else {
    millisecondNumber = millisecond;
  }
  return new Date(millisecondNumber * 1000).toISOString().substr(11, 8);
}

export function convertCentToDollar(cent) {
  let centNumber;
  if (cent === 0 || !cent) {
    return "-";
  }
  if (typeof cent !== "number") {
    centNumber = Number(cent);
  } else {
    centNumber = cent;
  }
  try {
    if (centNumber > 0) {
      return `$${centNumber / 100}`;
    }
  } catch (e) {
    return centNumber;
  }
}

export function hasOwnNestedProperty(obj, key) {
  return key.split(".").every(function (x) {
    // eslint-disable-next-line
    if (typeof obj != "object" || obj === null || !x in obj) return false;
    obj = obj[x];
    return true;
  });
}

export function errorMessageHandler(error) {
  if (
    hasOwnNestedProperty(error, "response.data.errors") &&
    error.response.data.errors !== undefined &&
    error.response.data.errors !== null &&
    error.response.data.errors.length > 0
  ) {
    let errors = "";
    error.response.data.errors.forEach((errorText) => {
      errors += errorText.message + "\r\n";
    });
    return errors;
  } else if (
    hasOwnNestedProperty(error, "response.data.message") &&
    error.response.data.message !== undefined &&
    error.response.data.message !== "" &&
    error.response.data.message !== null
  ) {
    return error.response.data.message;
  } else {
    return "Error sending information";
  }
}

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function camelCaseToText(string) {
  return string.replace(/([a-z](?=[A-Z]))/g, "$1 ");
}

export function just_digit(str) {
  const p = /^\d+$/;
  if (!p.test(str)) {
    return false;
  } else {
    return true;
  }
}

export const just_float_digit = (str) => {
  const p = /^[0-9]*([.,])?[0-9]*$/;
  return p.test(str);
};

export const getSystemTimeZone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const showDetailDate = (date) => {
  // if (!date) {
  //   return "-";
  // }
  // if (typeof date === "string" && date.substr(date.length - 1) !== "Z") {
  //   date = date + "Z";
  // }
  // try {
  //   let time = moment(date);
  //
  //   if (!time.isDST()) {
  //     time.add(1, "hour");
  //   }
  //
  //   time = time.tz(getSystemTimeZone()).format("YYYY/MM/DD HH:mm:ss");
  //
  //   return time;
  // } catch (e) {
  //   return date;
  // }
  return moment(date).format("YYYY/MM/DD HH:mm:ss");
};

export const timestampToUTC = (date, format = "YYYY/MM/DD HH:mm:ss") => {
  return moment.utc(date).format("YYYY/MM/DD HH:mm:ss");
};

export const convertSecondsToDhms = (secondsData) => {
  if (!secondsData) return "-";
  secondsData = Number(secondsData);
  let days = Math.floor(secondsData / (3600 * 24));
  let hours = Math.floor((secondsData % (3600 * 24)) / 3600);
  let minutes = Math.floor((secondsData % 3600) / 60);
  let seconds = Math.floor(secondsData % 60);

  let daysDisplay =
    days >= 10
      ? days + (days === 1 ? ":" : ":")
      : days < 10
      ? `0${days}` + (days === 1 ? ":" : ":")
      : "0";

  let hoursDisplay =
    hours >= 10
      ? hours + (hours === 1 ? ":" : ":")
      : hours < 10
      ? `0${hours}` + (hours === 1 ? ":" : ":")
      : "0";

  let minutesDisplay =
    minutes >= 10
      ? minutes + (minutes === 1 ? ":" : ":")
      : minutes < 10
      ? `0${minutes}` + (minutes === 1 ? ":" : ":")
      : "0";

  let secondsDisplay =
    seconds >= 10
      ? seconds + (seconds === 1 ? ":" : "")
      : seconds < 10
      ? `0${seconds}` + (seconds === 1 ? ":" : "")
      : "0";

  return daysDisplay + hoursDisplay + minutesDisplay + secondsDisplay;
};

export const convertMinuteToDhm = (minuteData) => {
  if (!minuteData) return "-";
  minuteData = Number(minuteData);
  let days = Math.floor(minuteData / (60 * 24));
  let hours = Math.floor((minuteData % (60 * 24)) / 60);
  let minutes = Math.floor(minuteData % 60);

  let daysDisplay =
    days >= 10
      ? days + (days === 1 ? ":" : ":")
      : days < 10
      ? `0${days}` + (days === 1 ? ":" : ":")
      : "0";

  let hoursDisplay =
    hours >= 10
      ? hours + (hours === 1 ? ":" : ":")
      : hours < 10
      ? `0${hours}` + (hours === 1 ? ":" : ":")
      : "0";

  let minutesDisplay =
    minutes >= 10
      ? minutes + (minutes === 1 ? "" : "")
      : minutes < 10
      ? `0${minutes}` + (minutes === 1 ? "" : "")
      : "0";

  return daysDisplay + hoursDisplay + minutesDisplay + ":00";
};

export function dollarToCent(price) {
  const dollars = Number(price.toString().replace(/\D/g, "")) / 100;
  return dollars.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}
