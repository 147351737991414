import React, { useEffect, useState } from "react";
import Container from "../../../container/Container";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Radio,
  Row,
  Spin,
  Upload,
} from "antd";
import CustomDateInput from "../../../crud/components/CustomDateInput";
import { fetchProfile } from "./profile.action";
import "./profile.style.scss";
import AddressList from "./components/AddressList";
import moment from "moment";
import defaultImage from "../../../../assets/profile/profile.png";
import {
  hasStripeConnect,
  processStripeConnectFlow,
  updateProfileService,
} from "./profile.api";
import {
  CheckCircleTwoTone,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { HttpService } from "../../../../app/apiService";
import { fetchProfileImage } from "./profileImage.action";

const Profile = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.profileReducer);
  const { image, loading: profileImageUploadLoading } = useSelector(
    (state) => state.profileImageReducer
  );
  const [loading, setLoading] = useState(false);
  const [hasStripe, setHasStripe] = useState(false);
  const [flagProfile, setFlagProfile] = useState(false);
  const [profileImageUploading, setProfileImageUploading] = useState(false);

  useEffect(
    () => {
      if (flagProfile) {
        dispatch(fetchProfileImage());
        setFlagProfile(false);
      }
    },
    // eslint-disable-next-line
    [flagProfile]
  );

  const onFinish = (values) => {
    if (
      values &&
      values.extraInfo &&
      values.extraInfo.addressList &&
      values.extraInfo.addressList.length > 0
    ) {
      values.extraInfo.addressList = values.extraInfo.addressList.map(
        (item) => {
          let lat, lon;
          if (item.location) {
            lat = item.location.lat;
            lon = item.location.lng;
          }
          delete item.location;

          return {
            ...item,
            lat,
            lon,
          };
        }
      );
    }
    values.extraInfo.birthDay = moment(values.extraInfo.birthDay).format("x");
    setLoading(true);
    return updateProfileService(values)
      .then((res) => {
        setLoading(false);
        message.success("Profile updated successfully");
      })
      .catch((err) => {
        message.error("Profile could not be updated");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const hasStripeConnectFunc = () => {
    hasStripeConnect().then((res) => {
      setHasStripe(res.data.connected);
    });
  };

  const processStripeConnectFlowFunc = () => {
    if (
      data &&
      data.roles &&
      data.roles.find((item) => item === "ROLE_STRIPE_CONNECT")
    ) {
      processStripeConnectFlow().then((response) => {
        if (response.status === 200) {
          window.location.href = response.data;
        }
      });
    } else {
      message.error("You are not allowed to connect");
    }
  };
  useEffect(
    () => {
      dispatch(fetchProfile());
      dispatch(fetchProfileImage());
    },
    // eslint-disable-next-line
    [loading]
  );

  useEffect(() => {
    hasStripeConnectFunc();
  }, []);
  const IMAGE = "/v1/subscriber/profile";

  const onChange = (data) => {
    if (!data) {
      return;
    }
    setProfileImageUploading(true);
    let formData = new FormData();
    formData.append("file", data.file);
    return HttpService.post(IMAGE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 202) {
        setProfileImageUploading(false);
        setFlagProfile(true);
        message.success("Profile image updated successfully");
      }
    });
  };
  return (
    <Container classNames="contents" loading={loading}>
      <Form
        name="basic"
        onFinish={onFinish}
        fields={[
          {
            name: ["username"],
            value: data.username,
          },
          {
            name: ["extraInfo", "firstName"],
            value: data.extraInfo && data.extraInfo.firstName,
          },
          {
            name: ["extraInfo", "avatarImageUrl"],
            value: data.extraInfo && data.extraInfo.avatarImageUrl,
          },
          {
            name: ["extraInfo", "lastName"],
            value: data.extraInfo && data.extraInfo.lastName,
          },
          // {
          //   name: ["extraInfo", "fullName"],
          //   value: data.extraInfo && data.extraInfo.fullName,
          // },
          {
            name: ["extraInfo", "birthDay"],
            value: data.extraInfo && data.extraInfo.birthDay,
          },
          {
            name: ["extraInfo", "nationalCode"],
            value: data.extraInfo && data.extraInfo.nationalCode,
          },
          {
            name: ["mobile"],
            value: data.mobile,
          },
          {
            name: ["email"],
            value: data.email,
          },
          {
            name: ["extraInfo", "addressList"],
            value: data.extraInfo && data.extraInfo.addressList,
          },
          {
            name: ["extraInfo", "gender"],
            value: data.extraInfo && data.extraInfo.gender,
          },
        ]}
      >
        <Row className={"user-profile"}>
          <Col
            lg={6}
            md={10}
            sm={24}
            xs={24}
            className={"profile-image flex-center"}
          >
            <Row>
              <Spin
                spinning={profileImageUploading || profileImageUploadLoading}
              >
                <img
                  src={image ? `data:image/*;base64,${image}` : defaultImage}
                  alt="img"
                />
              </Spin>
            </Row>
            <Row>
              <Upload
                // onChange={onChange}
                showUploadList={false}
                customRequest={onChange}
              >
                <Button icon={<UploadOutlined />} className={"upload-button"}>
                  Click to Upload
                </Button>
              </Upload>
            </Row>
          </Col>

          <Col lg={10} md={14} sm={24} xs={24}>
            <Form.Item label="UserName" name="username">
              <Input size="large" disabled />
            </Form.Item>
            <Form.Item label="FirstName" name={["extraInfo", "firstName"]}>
              <Input size="large" />
            </Form.Item>
            <Form.Item label="LastName" name={["extraInfo", "lastName"]}>
              <Input size="large" />
            </Form.Item>
            {/*<Form.Item label="FullName" name={["extraInfo", "fullName"]}>*/}
            {/*  <Input size="large" />*/}
            {/*</Form.Item>*/}
            <Form.Item label="Birthday" name={["extraInfo", "birthDay"]}>
              <CustomDateInput />
            </Form.Item>
            <Form.Item label="Mobile" name="mobile">
              <Input size="large" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>

            <Form.Item label="Gender" name={["extraInfo", "gender"]}>
              <Radio.Group>
                <Radio value={"MALE"}>Male</Radio>
                <Radio value={"FEMALE"}>Female</Radio>
                <Radio value={"NONE"}>None</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.List
              name={["extraInfo", "addressList"]}
              className="content-description"
            >
              {(fields, { add }) => {
                return (
                  <>
                    {fields.map((field, index) => (
                      <AddressList field={field} index={index} />
                    ))}
                    {fields.length === 0 && (
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          className={"field-width"}
                        >
                          <PlusOutlined /> Add field
                        </Button>
                      </Form.Item>
                    )}
                  </>
                );
              }}
            </Form.List>
            <Form.Item label="Stripe Connect">
              <Button
                className={
                  hasStripe ? "stripe-connect white" : "stripe-connect"
                }
                onClick={processStripeConnectFlowFunc}
                disabled={hasStripe}
              >
                <span className={"title-stripe-button"}>
                  {hasStripe ? (
                    <>
                      <CheckCircleTwoTone
                        twoToneColor="#52c41a"
                        className={"check-circle"}
                      />
                      Connected
                    </>
                  ) : (
                    "Connect"
                  )}{" "}
                  to Stripe
                </span>
              </Button>
            </Form.Item>
            <div className="update-button">
              <Button type="primary" htmlType="submit" size="large">
                Update
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default Profile;
