import { pathToRegexp } from "path-to-regexp";

export function getActivePath(routes, location) {
  let isChild = false;
  let currentMenu = undefined;
  let result = {};
  let found = false;

  routes.forEach((item) => {
    if (item.path && pathToRegexp(item.path).exec(location.pathname)) {
      isChild = false;
      currentMenu = pathToRegexp(item.path).exec(location.pathname) && item;
    }

    if (item.children) {
      item.children.forEach((child) => {
        if (child.path && pathToRegexp(child.path).exec(location.pathname)) {
          isChild = true;
          currentMenu =
            pathToRegexp(child.path).exec(location.pathname) && child;
        }
      });
    }

    if (currentMenu && found === false) {
      found = true;
      result = {
        hasChildren: isChild,
        currentMenu: currentMenu,
        item,
      };
    }
  });

  return result;
}
