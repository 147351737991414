import { HttpService } from "../../../../app/apiService";

const API = "v1/cuddle/config";

export const getConfigs = (
  data = {
    keys: "RENT_LIMIT, CHANGE_INVOICE_QUESTION_ID, FOLDER_ID",
  }
) => {
  return HttpService.get(`${API}`, { params: data });
};
