import { HttpService } from "../../app/apiService";

export const createService = (api, data) => {
  return HttpService.post(api, data);
};

export const getService = (
  api,
  params = { pageNumber: 0, pageSize: 10, sort: "ASC" }
) => {
  return HttpService.get(api, { params });
};

export const updateService = (api, data) => {
  return HttpService.put(api, data);
};

export const deleteService = (api, config = {}) => {
  return HttpService.delete(api, config);
};
