import Config from "../../app/config";
import axios from "axios";

export const handleLogin = ({ username, password }) => {
  const params = new URLSearchParams();
  params.append("grant_type", "password");
  params.append("username", username);
  params.append("password", password);

  return axios.post(Config.baseUrl + Config.getTokenApi, params, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    auth: {
      username: Config.credential.username,
      password: Config.credential.password,
    },
  });
};
