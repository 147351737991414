import React from "react";
import PropTypes from "prop-types";
import { BarsOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";

const DropOption = (props) => {
  const {
    menuOptions = [],
    buttonStyle,
    record,
    fetchData,
    otherActionCustom,
  } = props;
  const { t } = useTranslation();

  const handleMenuClick = (event) => {
    menuOptions.forEach((item) => {
      if (item.key === event.key) {
        item.func(record, fetchData, otherActionCustom);
      }
    });
  };

  const menu = menuOptions.map((item) => {
    return (
      <Menu.Item
        key={item.key}
        disabled={item.notShowFunc ? item.notShowFunc(record) : false}
      >
        {item.name && item.name.search("::") > -1
          ? t(`${item.name}`)
          : t(`general::${item.name}`)}
      </Menu.Item>
    );
  });

  return (
    <Dropdown
      overlay={<Menu onClick={handleMenuClick}>{menu}</Menu>}
      trigger={["click"]}
    >
      <Button style={{ border: "none", ...buttonStyle }}>
        <BarsOutlined style={{ marginRight: 2 }} />
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

DropOption.propTypes = {
  menuOptions: PropTypes.array,
  buttonStyle: PropTypes.object,
};

export default DropOption;
