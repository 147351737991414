import React, { useEffect, useState } from "react";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import { deleteStationService, getStationService } from "./station.api";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Image, Tag } from "antd";
import "./station.style.scss";
import plus from "../../../../assets/plus.svg";
import { showDetailDate, getFieldValue } from "../../../../app/util";

const Station = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { parentId, catId } = useParams();
  const [mallName, setMallName] = useState("");
  const [stations, setStations] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: t("general::title"),
      dataIndex: "title",
      key: "title",
      render: (text) => {
        return getFieldValue(text);
      },
    },
    {
      title: t("general::status"),
      dataIndex: "status",
      key: "status",
    },
    /* {
            title: t("general::category"),
            dataIndex: "category",
            key: "category",
            render: (text) => {
                if (text && catId[text] && catId[text]["title"]) {
                    if (typeof text === "object") {
                        text.map((item) => (item && item.title ? item.title : ""));
                    }
                    return catId[text]["title"];
                }

                return `-`;
            },
        },*/

    {
      title: t("general::publishDate"),
      dataIndex: "publishDate",
      key: "publishDate",
      sorter: true,
      render: (text) => {
        try {
          return showDetailDate(text);
        } catch {
          return text;
        }
      },
    },
  ];

  useEffect(
    () => {
      fetchStation();
    },
    // eslint-disable-next-line
    []
  );

  function fetchStation() {
    setLoading(true);
    getStationService(parentId)
      .then((resp) => {
        setLoading(false);
        if (resp.data && resp.data.title && resp.data.title.fields[0]) {
          setMallName(resp.data.title.fields[0]);
        } else {
          setMallName("There is no name");
        }
        if (resp.data && resp.data.children) {
          setStations(resp.data.children);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  function deleteStation(id) {
    return deleteStationService(id).then((res) => {
      window.location.reload();
    });
  }

  return (
    <Container classNames="contents" loading={loading}>
      <Link
        to={`/operations/mall-management/${catId}/station/${parentId}/create`}
      >
        <Button>
          <Image src={plus} preview={false} />
          Add Station
        </Button>
      </Link>
      <Crud
        otherButtons={
          <Tag color="#108ee9" className="add-mallName">
            {t("general::mallName")}
            <span className="title-mallName">{`: ${mallName}`} </span>
          </Tag>
        }
        otherAction={[
          {
            name: "edit",
            func: (record) => {
              history.push(
                `/operations/mall-management/${catId}/station/${parentId}/edit/${record.id}`
              );
            },
          },
        ]}
        get={{
          dataSource: stations,
          columns,
          formProps: {
            onFinish: (values) => {
              values.contentId = parentId;
              return values;
            },
          },
          fetchDataDependency: [parentId],
          fetchDataFunc: () => {
            fetchStation();
          },
        }}
        remove={{
          apiService: deleteStation,
        }}
      ></Crud>
    </Container>
  );
};

export default Station;
