import React, { useEffect, useState } from "react";
import { AutoComplete, Button, Input } from "antd";
import { getSubscribers } from "../operation/pages/subscribers/subscribers.api";
import { DownOutlined } from "@ant-design/icons";

function CustomFilterEmail(props) {
  const { setFilter, item, reset, setReset } = props;
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState({ value: "" });
  const [title, setTitle] = useState("");

  useEffect(
    () => {
      if (searchResult && searchResult.value !== undefined) {
        setTitle(searchResult.value);
      }
    },
    // eslint-disable-next-line
    [searchResult]
  );

  useEffect(
    () => {
      setFilter((prevData) => ({
        ...prevData,
        [item]: title,
      }));
    },
    // eslint-disable-next-line
    [title, value]
  );

  const onSearch = (searchText) => {
    setValue(searchText);
  };

  const onSelect = (data, option) => {
    setSearchResult(option);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => fetchData(), 1000);
    return () => clearTimeout(timeOutId);

    // eslint-disable-next-line
  }, [value]);

  useEffect(
    () => {
      setValue("");
      setSearchResult();
      setTitle();
      setReset(false);
    },
    // eslint-disable-next-line
    [reset]
  );
  const onChange = (option) => {
    setTitle(option);
    setValue(option);
  };

  function fetchData() {
    setLoading(true);
    setOptions([]);
    getSubscribers({ email: value, pageSize: 10 })
      .then((r) => {
        let temp = r.data.content.map((i) => {
          if (i.email.includes(value)) {
            setTitle(value);
          }
          return {
            value: i.email,
          };
        });
        setOptions(temp);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  }

  return (
    <AutoComplete
      allowClear
      options={options}
      style={{
        width: "100%",
      }}
      onSelect={onSelect}
      onChange={onChange}
      onSearch={onSearch}
      placeholder="Enter Email"
      loading={loading}
      value={title || value}
    >
      <Input
        suffix={
          <Button className={"down-icon"}>
            <DownOutlined />
          </Button>
        }
      />
    </AutoComplete>
  );
}

export default CustomFilterEmail;
