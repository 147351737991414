import React, { useState } from "react";
import { Button, Col, Row, Space, Table } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SearchFilter from "./SearchFilter";
import FilterFromTo from "./FilterFromTo";

const Read = (props) => {
  const {
    dataSource,
    apiData,
    loading,
    fetchData,
    hasError = false,
    tableColumn,
    handleChange,
    tableProps,
    paginationState,
    setPaginationState,
    showTotalItems,
    filter,
    setFilter,
    // setApiData,
    isFilter,
    fromToInitialValue
  } = props;
  const { t } = useTranslation();
  const [reset, setReset] = useState(false);
  if (tableColumn.length === 0) {
    return <span />;
  }
  const resetHolder = () => {
    // setApiData([]);
    setPaginationState((prevData) => {
      return {
        ...prevData,
        filters: {},
        current: 1,
      };
    });
    setReset(true);
  };

  const confirmHolder = () => {
    setPaginationState({
      ...paginationState,
      filters: filter,
      current: 1,
    });
  };

  const handlerCustomFilter = (CustomFilter, item) => {
    return (
      <CustomFilter
        {...props}
        style={{ width: "100%" }}
        item={item.key}
        title={item.customTitle || item.title}
        setFilter={setFilter}
        reset={reset}
        setReset={setReset}
      />
    );
  };
  const filterData = tableColumn.map((item, index) => {
    if (item.searchable) {
      return (
        <Col key={index} xs={24} sm={24} md={20} lg={7}>
          <div className="label">
            {item.key === item.dataIndex ? item.title : item.key}:
          </div>
          <SearchFilter
            {...props}
            style={{ width: "100%" }}
            item={item.key}
            title={item.title}
            setFilter={setFilter}
            reset={reset}
            setReset={setReset}
          />
        </Col>
      );
    } else if (item.filter && item.filter === "fromTo") {
      return (
        <Col key={index} xs={24} sm={24} md={24} lg={10}>
          <div>Date Filter:</div>
          <FilterFromTo
            {...props}
            fromToInitialValue={fromToInitialValue}
            setFilter={setFilter}
            item={item.key}
            reset={reset}
            setReset={setReset}
          />
        </Col>
      );
    } else if (item.customFilter) {
      return (
        <Col key={index} xs={24} sm={24} md={24} lg={7}>
          <div className="label">
            {item.customTitle
              ? item.customTitle
              : item.key === item.dataIndex
              ? item.title
              : item.key === "code"
              ? "Lock QR Code"
              : item.key === "mallIds"
              ? "Operator"
              : item.key === "strollerIdSet"
              ? "Cart ID"
              : item.key}
            :
          </div>
          {handlerCustomFilter(item.customFilter, item)}
        </Col>
      );
    } else {
      return "";
    }
  });

  return (
    <>
      {showTotalItems && (
        <Space align="center" style={{ marginBottom: 16 }}>
          {`All: ${
            typeof paginationState.total !== "undefined"
              ? `${paginationState.total} item`
              : "Unregistered"
          }`}
        </Space>
      )}
      {isFilter && (
        <Row className={"filters"}>
          <Col xs={18} sm={18} md={18} lg={18}>
            <Row> {filterData}</Row>
          </Col>

          <Col xs={6} sm={6} md={6} lg={6} className={"btn-filter"}>
            <Row>
              <Col
                style={{
                  alignItems: "flex-end",
                  display: "flex",
                  marginTop: "5px",
                }}
                xs={24}
                sm={24}
                md={12}
                lg={12}
              >
                <Button
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  type="primary"
                  onClick={confirmHolder}
                >
                  Apply
                </Button>
              </Col>

              <Col
                style={{
                  alignItems: "flex-end",
                  display: "flex",
                  marginTop: "5px",
                }}
                xs={24}
                sm={24}
                md={12}
                lg={12}
              >
                <Button
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  type="primary"
                  onClick={resetHolder}
                >
                  Reset All
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Table
        bordered
        columns={tableColumn}
        dataSource={dataSource ? dataSource : apiData}
        loading={loading}
        locale={{
          emptyText: hasError ? (
            <Button className={"try-again"} onClick={fetchData}>
              {t("general::tryAgain")}
            </Button>
          ) : undefined,
        }}
        onChange={handleChange}
        rowKey={"id"}
        {...tableProps}
        pagination={paginationState}
      />
    </>
  );
};

Read.propTypes = {
  data: PropTypes.string,
  apiData: PropTypes.array,
  loading: PropTypes.bool,
  fetchData: PropTypes.func,
  hasError: PropTypes.bool,
  tableColumn: PropTypes.array,
  handleChange: PropTypes.func,
  tableProps: PropTypes.object,
  pagination: PropTypes.object,
  showTotalItems: PropTypes.bool,
};

export default Read;
