import React, { useCallback, useEffect, useState } from "react";
import { AutoComplete, Button, Input, Tag } from "antd";
import { getMallService } from "../operation/operation.api";
import { DownOutlined } from "@ant-design/icons";
import { debounce } from "debounce";
const { Option } = AutoComplete;

function CustomSelectMall(props) {
  const { setFilter, item, reset, setReset } = props;
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState();
  const [chosenItems, setChosenItems] = useState([]);

  useEffect(
    () => {
      setFilter((prevData) => ({
        ...prevData,
        [item]: chosenItems.map((i) => i.value),
      }));

      let result = options.map((item, index) => {
        if (chosenItems && chosenItems.length > 0) {
          let f = chosenItems.find((el) => item.value === el.value);
          if (f) {
            return {
              ...item,
              disabled: true,
            };
          } else {
            return {
              ...item,
              disabled: false,
            };
          }
        } else {
          return {
            ...item,
            disabled: false,
          };
        }
      });

      setOptions(result);
    },
    // eslint-disable-next-line
    [chosenItems]
  );

  const onSearch = (searchText) => {
    setValue(searchText);
  };

  const onSelect = (data, option) => {
    setSearchResult(option, { disabled: true });
  };
  //
  // useEffect(
  //   () => {
  //     fetchData();
  //   },
  //   // eslint-disable-next-line
  //   [value]
  // );

  useEffect(
    () => {
      verify(value);
    },
    // eslint-disable-next-line
    [value]
  );

  const verify = useCallback(
    debounce((value) => {
      fetchData(value);
    }, 1000),
    []
  );

  useEffect(
    () => {
      if (chosenItems && searchResult && searchResult.value !== undefined) {
        setChosenItems([...chosenItems, searchResult]);
      }
    },
    // eslint-disable-next-line
    [searchResult]
  );

  const fetchData = (value) => {
    setLoading(true);
    setOptions([]);
    getMallService({ title: value, pageSize: 10 })
      .then((r) => {
        let temp = r.data.content.map((i) => {
          return {
            text: i.title.fields[0],
            value: i.id,
          };
        });
        setOptions(temp);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const removeChosenItems = (id) => {
    setChosenItems(chosenItems.filter((item) => item.value !== id));
  };

  useEffect(
    () => {
      setValue("");
      setSearchResult();
      setChosenItems([]);
      setReset(false);
    },
    // eslint-disable-next-line
    [reset]
  );
  const forMap = (item) => {
    const tagElem = (
      <Tag closable onClose={() => removeChosenItems(item.value)}>
        {item.children}
      </Tag>
    );
    return (
      <span
        key={item.children}
        style={{ display: "inline-block", margin: "1px" }}
      >
        {tagElem}
      </span>
    );
  };

  return (
    <>
      <AutoComplete
        allowClear
        backfill
        style={{ width: "100%" }}
        onSelect={onSelect}
        onSearch={onSearch}
        placeholder="Enter Name"
        value={value}
        loading={loading}
      >
        {options.map((i) => {
          return (
            <Option value={i.value} disabled={i.disabled} key={i.value}>
              {i.text}
            </Option>
          );
        })}
        <Input
          suffix={
            <Button className={"down-icon"}>
              <DownOutlined />
            </Button>
          }
        />
      </AutoComplete>

      <div
        className={
          chosenItems && chosenItems.length > 0 ? "multi-select-mall" : ""
        }
      >
        {chosenItems.map((item) => forMap(item))}
        <span />
      </div>
    </>
  );
}

export default CustomSelectMall;
