import React from "react";
import {
  ApiOutlined,
  CloseCircleOutlined,
  ReadOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Reports from "./Reports";
import Financial from "./pages/financial/Financial";
import Mall from "./pages/mall/Mall";
import Stroller from "./pages/stroller/Stroller";
import Battery from "./pages/battery/Battery";
import Carts from "./pages/carts/Carts";
import Subscriber from "./pages/subscriber/Subscriber";
import FailedPayment from "./pages/failedPayment/FailedPayment";
// import History from "../operation/pages/history/history";
// import { HistoyLink } from "../operation/pages/history/history.constant";
import { APP_Permissions } from "../userManagement/components/privileges/privileges.constant";

const root = "/reports/";

export const ReportsPathNames = {
  root,
  financial: root + "financial",
  mall: root + "mall",
  carts: root + "carts",
  stroller: root + "stroller",
  battery: root + "battery",
  subscriber: root + "customer",
  failedPayment: root + "failedPayment",
  history: root + "history",
};

export const ReportsRoutes = [
  {
    title: "Reports",
    path: ReportsPathNames.root,
    component: Reports,
    exact: true,
    icon: <ReadOutlined />,
    children: [
      {
        title: "financial",
        path: ReportsPathNames.financial,
        component: Financial,
        exact: true,
        icon: <TeamOutlined />,
        permissions: [
          APP_Permissions["reports"]["cuddle_rents.reports.integrated"],
        ],
      },
      {
        title: "operator",
        path: ReportsPathNames.mall,
        component: Mall,
        exact: true,
        icon: <ShopOutlined />,
        permissions: [
          APP_Permissions["reports"]["cuddle_rents.reports.mall-grouped"],
        ],
      },
      {
        title: "salesByCartSummary",
        path: ReportsPathNames.carts,
        component: Carts,
        exact: true,
        icon: <ShoppingCartOutlined />,
        permissions: [
          APP_Permissions["reports"]["cuddle_rents.reports.stroller-grouped"],
        ],
      },
      {
        title: "byCart",
        path: ReportsPathNames.stroller,
        component: Stroller,
        exact: true,
        icon: <ShoppingCartOutlined />,
        permissions: [
          APP_Permissions["reports"]["cuddle_rents.reports.integrated"],
        ],
      },
      {
        title: "bySubscriber",
        path: ReportsPathNames.subscriber,
        component: Subscriber,
        exact: true,
        icon: <UserOutlined />,
        permissions: [
          APP_Permissions["reports"]["cuddle_rents.reports.integrated"],
        ],
      },
      {
        title: "battery",
        path: ReportsPathNames.battery,
        component: Battery,
        exact: true,
        icon: <ApiOutlined />,
        permissions: [
          APP_Permissions["reports"]["cuddle_rents.reports.battery"],
        ],
      },
      {
        title: "failedPayment",
        path: ReportsPathNames.failedPayment,
        component: FailedPayment,
        exact: true,
        icon: <CloseCircleOutlined />,
        permissions: [
          APP_Permissions["transactions"]["cuddle_transactions.getAll"],
        ],
      },
      // {
      //   title: "stroller",
      //   path: `${ReportsPathNames.history}/stroller/:parentId`,
      //   component: History,
      //   exact: true,
      //   icon: <HistoryOutlined />,
      //   showInSideBar: false,
      //   historyType: HistoyLink.STROLLER,
      // },
      // {
      //   title: "subscriber",
      //   path: `${ReportsPathNames.history}/subscriber/:parentId`,
      //   component: History,
      //   exact: true,
      //   showInSideBar: false,
      //   historyType: HistoyLink.SUBSCRIBER,
      // },
    ],
  },
];
