import { Button, Form, Modal, Tree } from "antd";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getPrivilegesChildren } from "../../../../../userManagement/components/privileges/privileges.action";

const ShowPrivilegesInTable = (props) => {
  const { rolePermissions } = props;
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState([]);
  // Modal stuff
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(!visible);
  const closeModal = () => setVisible(false);

  useEffect(() => {
    if (rolePermissions && Array.isArray(rolePermissions)) {
      let data = getPrivilegesChildren(rolePermissions);
      setPermissions(data);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Form.Item
      name={"privileges"}
      rules={[{ array: { min: 1 } }]}
      style={{ marginBottom: 0 }}
    >
      <Button type="primary" onClick={showModal}>
        {t("userManagement::showPrivileges")}
      </Button>
      <Modal
        title={t("userManagement::privileges")}
        visible={visible}
        onOk={closeModal}
        onCancel={closeModal}
        okText={t("general::close")}
        cancelButtonProps={{
          hidden: true,
        }}
      >
        <Tree treeData={permissions} className={"ltr"} />
      </Modal>
    </Form.Item>
  );
};

ShowPrivilegesInTable.propTypes = {
  rolePermissions: PropTypes.array,
};

export default ShowPrivilegesInTable;
