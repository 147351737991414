import { HttpService } from "../../app/apiService";
import { message } from "antd";
import { getCookieLanguages } from "../../app/util";

export const API = "v1/file";

const language = getCookieLanguages();

export const getRootFolders = () => {
  return HttpService.get(`${API}/folder/root`);
};

export const getFolderContent = (folderId) => {
  if (!folderId) {
    message.error(language === "en" && "folderId not found!", 3);
  }
  return HttpService.get(`${API}/folder/${folderId}`);
};

export const createNewFolder = (data) => {
  return HttpService.post(`${API}/folder`, data);
};

export const uploadFileService = (file, folderId, setUuid) => {
  if (!file) {
    return false;
  }
  let formData = new FormData();
  formData.append("file", file);
  formData.append("name", file.name);
  formData.append("folderId", folderId);

  return HttpService.post(`${API}/file`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getFileUrlService = (uuid) => {
  return HttpService.get(`${API}/${uuid}`);
};
