import React from "react";
import { Button } from "antd";
import { getCookieLanguages } from "../../../../../../app/util";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeLanguage } from "./languageSelector.action";
import setMultipleLang from "../../../../../../app/config";

export default function LanguageSelector() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  function handleChange(value) {
    if (setMultipleLang === false) {
      i18n.changeLanguage("en");
      dispatch(changeLanguage("en"));
    } else if (value === "en") {
      i18n.changeLanguage("en");
      dispatch(changeLanguage("en"));
    } else {
      i18n.changeLanguage("-");
      dispatch(changeLanguage("en"));
    }
  }

  return (
    <Button
      type="primary"
      onClick={() => handleChange(getCookieLanguages())}
      className="language-selector"
    >
      {getCookieLanguages() === "en" ? "English" : "English"}
    </Button>
  );
}
