import { HttpService } from "../../../../app/apiService";

const API = "v1/cuddle/config";

export const updateConfigLimitRentService = (data) => {
  let params = {
    key: "RENT_LIMIT",
    nameSet: [
      {
        lang: "rent limit",
        name: "en",
      },
    ],
    value: data,
  };

  return HttpService.put(API, params);
};

export const updateConfigFreeRentTime = (data) => {
  let params = {
    key: "FREE_RENT_TIME",
    nameSet: [
      {
        lang: "string",
        name: "string",
      },
    ],
    value: data,
  };

  return HttpService.put(API, params);
};
