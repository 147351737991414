import { HttpService } from "../../app/apiService";

export const API = "/v1/cuddle/rents/reports/statistical";

export const getReportsService = (data) => {
  let params;
  if (data) {
    params = {
      from: data.from.format("YYYY-MM-DDTHH:mm:ss"),
      to: data.to.format("YYYY-MM-DDTHH:mm:ss"),
    };
  }

  return HttpService.get(API, { params });
};
