import React, { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Container from "../../../container/Container";
import MediaSection from "../mediaSection/MediaSection";
import {
  createStationService,
  getStationService,
  updateStationService,
} from "./station.api";
import { errorMessageHandler, getFieldValue } from "../../../../app/util";

const StationCreate = () => {
  const { catId, parentId, stationId } = useParams();

  const history = useHistory();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [stationData, setStationData] = useState(null);

  useEffect(() => {
    if (stationId) {
      fetchStation(stationId);
    }
  }, [stationId]);

  useEffect(
    () => {
      if (stationData) {
        // set temp data for edit mode
        const temp = { ...stationData };

        // title
        const title = getFieldValue(stationData.title);
        temp.title = Array.isArray(title) ? title.join(" ") : title;

        // summary
        const summary = getFieldValue(stationData.summary);
        temp.summary = Array.isArray(summary) ? title.join(" ") : summary;
        // lat, lng = location
        temp.location = {
          lat: stationData.lat,
          lng: stationData.lng,
        };
        delete temp.lng;
        delete temp.lat;
        // closingTime, openingTime

        // temp.closingTime = moment(mallData.closingTime).format("HH:mm")
        // temp.openingTime = moment(mallData.openingTime).format("HH:mm")

        // banner
        const banner = getFieldValue(stationData.banner);
        temp.banner = Array.isArray(banner) ? banner.join(" ") : banner;

        if (temp.banner === "-") {
          temp.banner = null;
        }

        form.setFieldsValue(temp);
      }
    },
    // eslint-disable-next-line
    [stationData]
  );

  function fetchStation(item) {
    setLoading(true);
    getStationService(item)
      .then((r) => {
        if (r.data && r.data) setStationData(r.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onFinish(values) {
    const result = { ...values };
    delete result.location;
    result.title = {
      fields: [values.title],
      type: "text",
    };
    result.language = "EN";
    result.publishDate = stationData
      ? stationData.publishDate
      : new Date().getTime();
    result.banner = {
      fields: [values.banner ? values.banner : "-"],
      type: "text",
    };
    result.summary = {
      fields: [values.summary ? values.summary : "-"],
      type: "text",
    };
    result.parentId = parentId;
    result.type = "Station";
    result.status = "PUBLISHED";

    setLoading(true);
    if (stationId) {
      result.id = stationId;
      updateStationService(result)
        .then(() => {
          message.success(t("content::contentCreatedSuccessfully"), 3);
          history.push(
            `/operations/mall-management/${catId}/station/${parentId}`
          );
        })
        .catch((error) => {
          message.error(errorMessageHandler(error), 3);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createStationService(result)
        .then(() => {
          message.success(t("content::contentCreatedSuccessfully"), 3);
          history.push(
            `/operations/mall-management/${catId}/station/${parentId}`
          );
        })
        .catch((error) => {
          message.error(errorMessageHandler(error), 3);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <Container loading={loading}>
      <Form onFinish={onFinish} form={form}>
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true }]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Summary"
          name="summary"
          rules={[{ required: true }]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Banner image"
          name="banner"
          // rules={[{ required: true }]}
          hasFeedback
        >
          <MediaSection name="banner" accept="image/*" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default StationCreate;
