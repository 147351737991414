export const SectionTypes = {
  text: "text",
  image: "image",
  location: "location",
  link: "link",
  video: "video",
  music: "music",
  keyValue: "keyValue",
};

export const ContentLanguages = {
  fa: "FA",
  en: "EN",
};

export const PublishStatusTypes = {
  published: "PUBLISHED",
  draft: "DRAFT",
};
