import React from "react";
import { Descriptions, Modal } from "antd";
import PropTypes from "prop-types";
import {
  convertCentToDollar,
  showDetailDate,
} from "../../../../../../app/util";

const ExtraInfo = (props) => {
  const { extraInfoPopup, setExtraInfoPopup } = props;

  return (
    <Modal
      title={"Information"}
      visible={extraInfoPopup !== false}
      onOk={() => setExtraInfoPopup(false)}
      onCancel={() => setExtraInfoPopup(false)}
      cancelText={"Close"}
      okButtonProps={{ hidden: true }}
    >
      <Descriptions className={"subscriber-extra-info"} bordered>
        <Descriptions.Item span={24} label={"Start Time"}>
          {(extraInfoPopup &&
            extraInfoPopup.rentStartTime &&
            showDetailDate(extraInfoPopup.rentStartTime)) ||
            "-"}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={"End Time"}>
          {(extraInfoPopup &&
            extraInfoPopup.rentEndTime &&
            showDetailDate(extraInfoPopup.rentEndTime)) ||
            "-"}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={"Payment"}>
          {(extraInfoPopup && convertCentToDollar(extraInfoPopup.price)) || "-"}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={"Time Of Payment"}>
          {(extraInfoPopup &&
            extraInfoPopup.paymentTime &&
            showDetailDate(extraInfoPopup.paymentTime)) ||
            "-"}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={"Operator"}>
          {(extraInfoPopup && extraInfoPopup.mallName) || "-"}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={"Lock ID"}>
          {(extraInfoPopup && extraInfoPopup.additionalCode) || "-"}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

ExtraInfo.propTypes = {
  extraInfo: PropTypes.any,
  setExtraInfoPopup: PropTypes.func,
};

export default ExtraInfo;
