import React, { useEffect } from "react";
import "../../subscriber.style.scss";
import { Form, Input, message, Modal } from "antd";
import { changeSubscriberPassword } from "../../subscribers.api";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ChangePassword = (props) => {
  const { t } = useTranslation();
  const { showPasswordPopup, setShowPasswordPopup } = props;
  const [form] = Form.useForm();

  useEffect(() => {}, [showPasswordPopup]);

  const handleChangePassword = (data) => {
    let result = { ...data, id: showPasswordPopup.id };

    changeSubscriberPassword(result)
      .then((response) => {
        message.success(t("general::changesWereRecorded"), 3);
        setShowPasswordPopup(false);
      })
      .catch((error) => {
        console.error(error);
        message.error(t("general::errorConnectingToTheServer"), 3);
      });
  };

  return (
    <Modal
      title={t("general::changePassword")}
      visible={showPasswordPopup !== false}
      onOk={() => form.submit()}
      onCancel={() => setShowPasswordPopup(false)}
      okText={t("general::confirm")}
      cancelText={t("general::close")}
    >
      <Form form={form} onFinish={handleChangePassword} name={"pass"}>
        <Form.Item
          name="oldPassword"
          label={t("general::currentPassword")}
          rules={[
            {
              required: true,
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label={t("general::newPassword")}
          rules={[
            {
              required: true,
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ChangePassword.propTypes = {
  showPasswordPopup: PropTypes.any,
  setShowPasswordPopup: PropTypes.func,
};

export default ChangePassword;
