import { HttpService } from "../../../../app/apiService";

export const API = "v1/faq/que-ans";

export const getMessagesService = (params) => {
  params = { ...params, targetWord: params.subject };
  return HttpService.get(API, { params });
};

export const updateFaqService = (data) => {
  return HttpService.put(`${API}/${data.id}`, data);
};
