import { languageSelectorConstant } from "./languageSelector.constant";
import { getCookieLanguages } from "../../../../../../app/util";

const initialState = {
  language: getCookieLanguages(),
};

export const languageSelectorReducer = (
  state = initialState,
  { payload, ...action }
) => {
  switch (action.type) {
    case languageSelectorConstant.CHANGE_LANGUAGE:
      return {
        language: payload.language,
      };

    default:
      return state;
  }
};
