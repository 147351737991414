import React, { useState } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./app/serviceWorker";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import App from "./app/router";
import "./assets/styles/app.scss";
import { applyMiddleware, compose, createStore } from "redux";
import reducerApp from "./app/reducers";
import thunk from "redux-thunk";
import ValidateMessages from "./components/crud/components/ValidateMessages";
import i18n from "./app/i18n";
import { I18nextProvider } from "react-i18next";
import { getCookieLanguages } from "./app/util";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducerApp, composeEnhancers(applyMiddleware(thunk)));

const englishAppConfig = {
  direction: "ltr",
  validateMessages: ValidateMessages.English,
};

const IndexWrapper = () => {
  const [language, setLanguage] = useState(getCookieLanguages());
  const languageAppConfig = language === "en" && englishAppConfig;

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <ConfigProvider {...languageAppConfig}>
            <App setLanguage={setLanguage} />
          </ConfigProvider>
        </Provider>
      </I18nextProvider>
    </>
  );
};

ReactDOM.render(<IndexWrapper />, document.getElementById("root"));

// If you want your appContainer to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
