import React, { useCallback, useEffect, useState } from "react";
import { AutoComplete, Button, Input } from "antd";
import { getMallService } from "../operation/operation.api";
import { DownOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { debounce } from "debounce";

function CustomSingleSelectMall(props) {
  const { catId } = useParams();
  const { setFilter, item, reset, setReset } = props;
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState({ label: "", value: "" });
  const [title, setTitle] = useState("");

  useEffect(
    () => {
      if (searchResult && searchResult.value !== undefined) {
        setTitle(searchResult.label);
      }
    },
    // eslint-disable-next-line
    [searchResult]
  );

  useEffect(
    () => {
      setFilter((prevData) => ({
        ...prevData,
        [item]: title,
      }));
    },
    // eslint-disable-next-line
    [title, value]
  );

  const onSearch = (searchText) => {
    setValue(searchText);
  };

  const onSelect = (data, option) => {
    setSearchResult(option);
  };

  const fetchData = (title, category) => {
    setLoading(true);
    setOptions([]);
    getMallService({ title: title, pageSize: 10, catId: category })
      .then((r) => {
        let temp = r.data.content.map((i) => {
          if (i.id === value) {
            setTitle(i.label);
          }
          return {
            label: i.title.fields[0],
            value: i.id,
          };
        });
        setOptions(temp);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  // useEffect(() => {
  //   fetchData(value, catId);
  //   // eslint-disable-next-line
  // }, [value, catId]);

  useEffect(
    () => {
      verify(value, catId);
    },
    // eslint-disable-next-line
    [value, catId]
  );

  const verify = useCallback(
    debounce((value, catId) => {
      fetchData(value, catId);
    }, 1000),
    []
  );

  useEffect(
    () => {
      setValue("");
      setSearchResult();
      setTitle();
      setReset(false);
    },
    // eslint-disable-next-line
    [reset]
  );
  const onChange = (option) => {
    setTitle(option);
    setValue(option);
  };
  return (
    <AutoComplete
      allowClear
      options={options}
      style={{
        width: "100%",
      }}
      onSelect={onSelect}
      onChange={onChange}
      onSearch={onSearch}
      placeholder="Enter Name"
      loading={loading}
      value={title || value}
    >
      <Input
        suffix={
          <Button className={"down-icon"}>
            <DownOutlined />
          </Button>
        }
      />
    </AutoComplete>
  );
}

export default CustomSingleSelectMall;
