import {
  ContainerOutlined,
  FileTextOutlined,
  HistoryOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import React from "react";
import Operation from "./Operation";
import Stroller from "./pages/stroller/Stroller";
import Station from "./pages/station/Station";
import Properties from "./pages/properties/Properties";
import PropertyCreate from "./pages/properties/PropertyCreate";
import StationCreate from "./pages/station/StationCreate";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import Subscriber from "./pages/subscribers/Subscribers";
import Users from "./pages/users/Users";
import { APP_Permissions } from "../userManagement/components/privileges/privileges.constant";
import History from "./pages/history/history";
import OwnerClub from "./pages/owner/OwnerClub";

const root = `/operations`;

export const OperationPathNames = {
  mallManagement: root + "/mall-management",
  messages: root + "/tickets/messages/:ticketId",
  stroller: root + "/list/carts",
  systemUsers: root + "/users",
  customer: root + "/subscribers",
  history: root + "/history",
};

export const OperationRoutes = [
  {
    title: "operations",
    path: root,
    component: Operation,
    exact: true,
    icon: <ContainerOutlined />,
    children: [
      {
        path: OperationPathNames.mallManagement + `/:catId`,
        component: Properties,
        icon: <FileTextOutlined />,
        exact: true,
        hasCategory: true,
        permissions: [
          APP_Permissions["malls"]["cuddle_malls.admin.getMallPage"],
          APP_Permissions["malls"]["cuddle_malls.get"],
          APP_Permissions["malls"]["cuddle_malls.getMallList"],
        ],
      },
      {
        path: OperationPathNames.mallManagement + `/:catId/create`,
        component: PropertyCreate,
        icon: <FileTextOutlined />,
        exact: true,
        showInSideBar: false,
        permissions: [
          APP_Permissions["malls"]["cuddle_malls.admin.getMallPage"],
          APP_Permissions["malls"]["cuddle_malls.get"],
          APP_Permissions["malls"]["cuddle_malls.getMallList"],
          APP_Permissions["malls"]["cuddle_malls.create"],
        ],
      },
      {
        path: OperationPathNames.mallManagement + `/:catId/edit/:mallId`,
        component: PropertyCreate,
        icon: <FileTextOutlined />,
        exact: true,
        showInSideBar: false,
        permissions: [
          APP_Permissions["malls"]["cuddle_malls.admin.getMallPage"],
          APP_Permissions["malls"]["cuddle_malls.get"],
          APP_Permissions["malls"]["cuddle_malls.getMallList"],
          APP_Permissions["malls"]["cuddle_malls.create"],
          APP_Permissions["malls"]["cuddle_malls.update"],
        ],
      },
      {
        title: "stroller",
        path: OperationPathNames.stroller,
        component: Stroller,
        exact: true,
        icon: <TeamOutlined />,
        hideBreadCrumb: true,
        permissions: [APP_Permissions["strollers"]["cuddle_strollers.getAll"]],
      },
      {
        title: "systemUsers",
        path: OperationPathNames.systemUsers,
        component: Users,
        icon: <UserOutlined />,
        permissions: [
          APP_Permissions["subscriber"]["subscriber_admin-panel.getAll"],
          APP_Permissions["subscriber"]["subscriber_getAll"],
        ],
      },
      {
        title: "customers",
        path: OperationPathNames.customer,
        component: Subscriber,
        exact: true,
        icon: <UserOutlined />,
        permissions: [
          APP_Permissions["subscriber"]["subscriber_admin-panel.getAll"],
        ],
      },
      {
        title: "Station",
        path: OperationPathNames.mallManagement + `/:catId/owner/:mallId`,
        component: OwnerClub,
        exact: true,
        icon: <TeamOutlined />,
        showInSideBar: false,
        hideBreadCrumb: true,
        permissions: [
          APP_Permissions["malls"]["cuddle_malls.admin.getMallPage"],
          APP_Permissions["malls"]["cuddle_malls.get"],
          APP_Permissions["malls"]["cuddle_malls.getMallList"],
          APP_Permissions["malls"]["cuddle_malls.create"],
          APP_Permissions["malls"]["cuddle_malls.update"],
        ],
      },
      {
        title: "Station",
        path: OperationPathNames.mallManagement + `/:catId/station/:parentId`,
        component: Station,
        exact: true,
        icon: <TeamOutlined />,
        showInSideBar: false,
        hideBreadCrumb: true,
        permissions: [
          APP_Permissions["malls"]["cuddle_malls.admin.getMallPage"],
          APP_Permissions["malls"]["cuddle_malls.get"],
          APP_Permissions["malls"]["cuddle_malls.getMallList"],
          APP_Permissions["malls"]["cuddle_malls.create"],
          APP_Permissions["malls"]["cuddle_malls.update"],
        ],
      },
      {
        // titleFa: "-",
        path:
          OperationPathNames.mallManagement +
          `/:catId/station/:parentId/create`,
        component: StationCreate,
        icon: <FileTextOutlined />,
        exact: true,
        showInSideBar: false,
        permissions: [
          APP_Permissions["malls"]["cuddle_malls.admin.getMallPage"],
          APP_Permissions["malls"]["cuddle_malls.get"],
          APP_Permissions["malls"]["cuddle_malls.getMallList"],
          APP_Permissions["malls"]["cuddle_malls.create"],
          APP_Permissions["malls"]["cuddle_malls.update"],
        ],
      },
      {
        // titleFa: "-",
        path:
          OperationPathNames.mallManagement +
          `/:catId/station/:parentId/edit/:stationId`,
        component: StationCreate,
        icon: <FileTextOutlined />,
        exact: true,
        showInSideBar: false,
        permissions: [
          APP_Permissions["malls"]["cuddle_malls.admin.getMallPage"],
          APP_Permissions["malls"]["cuddle_malls.get"],
          APP_Permissions["malls"]["cuddle_malls.getMallList"],
          APP_Permissions["malls"]["cuddle_malls.create"],
          APP_Permissions["malls"]["cuddle_malls.update"],
        ],
      },
      {
        title: "history",
        path: OperationPathNames.history,
        component: History,
        exact: true,
        icon: <HistoryOutlined />,
        permissions: [
          APP_Permissions["reports"]["cuddle_rents.reports.integrated"],
          APP_Permissions["reports"]["cuddle_rents.reports.integrated.csv"],
        ],
      },
    ],
  },
];
