import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../container/Container";

const UserManagement = () => {
  const { t } = useTranslation();
  return <Container>{t("general::administration")}</Container>;
};

export default UserManagement;
