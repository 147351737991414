import { getProfilePhoto } from "./profile.api";
import { ProfileImageReduxTypes } from "./profileImage.constant";

export const fetchProfileImage = () => (dispatch) => {
  dispatch({
    type: ProfileImageReduxTypes.FETCH_PROFILE_IMAGE,
  });

  getProfilePhoto()
    .then((response) => {
      let value = Buffer.from(response.data, "binary").toString("base64");
      if (!response.data) {
        return value;
      }
      sessionStorage.setItem("image", value);
      dispatch({
        type: ProfileImageReduxTypes.FETCH_PROFILE_IMAGE_SUCCESS,
        payload: value,
      });
    })
    .catch((error) => {
      dispatch({
        type: ProfileImageReduxTypes.FETCH_PROFILE_IMAGE_FAILURE,
        payload: error,
      });
    });
};
