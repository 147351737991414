import React, { useState, useEffect, useRef, useCallback } from "react";
import { Col, Row } from "antd";
import PropTypes from "prop-types";
import Config from "../../../../../../app/config";
import Pin from "./Pin";
import ReactMapGL, { Marker } from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const LocationSection = (props) => {
  const mapRef = useRef();
  const geocoderContainerRef = useRef();
  const { locationInfo, setLocationInfo } = props;
  const [viewport, setViewport] = useState({
    width: "100vw",
    height: "100vh",
    latitude: 40.20546438134333,
    longitude: -100.83379669562898,
    center: [40.20546438134333, -100.83379669562898],
    zoom: 16,
  });

  const [markerViewPort, setMarkerViewPort] = useState({
    latitude: viewport.latitude,
    longitude: viewport.longitude,
  });

  useEffect(
    () => {
      navigator.geolocation.getCurrentPosition((pos) => {
        setViewport({
          ...viewport,
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
          center: [pos.coords.latitude, pos.coords.longitude],
        });
      });
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (locationInfo && locationInfo.lat && locationInfo.lng) {
        if (
          locationInfo.lat !== markerViewPort.latitude &&
          locationInfo.lng !== markerViewPort.longitude
        ) {
          setMarkerViewPort({
            ...markerViewPort,
            latitude: locationInfo.lat,
            longitude: locationInfo.lng,
          });

          setViewport({
            ...viewport,
            latitude: locationInfo.lat,
            longitude: locationInfo.lng,
            center: [locationInfo.lat, locationInfo.lng],
          });
        }
      }
    },
    // eslint-disable-next-line
    [locationInfo]
  );

  const handleViewportChange = useCallback(
    (newViewport) => setViewport(newViewport),
    // eslint-disable-next-line
    []
  );

  const onClick = (event) => {
    const { lngLat } = event;
    let newVewPort = {
      ...markerViewPort,
      latitude: lngLat[1],
      longitude: lngLat[0],
      center: [lngLat[1], lngLat[0]],
    };

    setMarkerViewPort({ ...newVewPort });
    setLocationInfo({
      lat: lngLat[1],
      lng: lngLat[0],
    });
  };

  const handleGeocoderViewportChange = useCallback(
    (newViewport) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 };

      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides,
      });
    },
    // eslint-disable-next-line
    []
  );

  return (
    <Row>
      <Col span={24} className="input-sections flex-center">
        <ReactMapGL
          {...viewport}
          ref={mapRef}
          container={viewport}
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={Config.mapBoxToken}
          onClick={onClick}
          mapStyle="mapbox://styles/mapbox/streets-v11?optimize=true"
          width={"65vw"}
          height={"85vh"}
          isMapTransitioning={true}
        >
          <Geocoder
            mapRef={mapRef}
            containerRef={geocoderContainerRef}
            onViewportChange={handleGeocoderViewportChange}
            mapboxApiAccessToken={Config.mapBoxToken}
            position="top-left"
            placeholder="search"
          />

          <Marker
            {...markerViewPort}
            // offsetLeft={-20}
            // offsetTop={-10}
            draggable
          >
            <Pin sixe={20} />
          </Marker>
          {locationInfo.length !== 0 && ""}
        </ReactMapGL>
      </Col>
    </Row>
  );
};

LocationSection.propTypes = {
  index: PropTypes.number,
  deleteFunction: PropTypes.func,
  locationInfo: PropTypes.any,
  setLocationInfo: PropTypes.func,
};

export default LocationSection;
