import { Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { changeTicketState } from "../pages/ticket/ticket.api";

const CloseTicketForm = ({
  setChangeStateVisible,
  changeStateVisible,
  fetchData,
}) => {
  const { t } = useTranslation();
  const { Option } = Select;

  const [selectedState, setSelectedState] = useState("");
  const handleChangeStateOk = () => {
    changeTicketState(changeStateVisible.id, selectedState)
      .then((response) => {
        setChangeStateVisible(false);
        fetchData();
        window.updateBadge()
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleChangeStateCancel = () => {
    setChangeStateVisible(false);
  };

  const handleChangeStatusSelect = (value) => {
    setSelectedState(value);
  };
  return (
    <Modal
      title={t("administration::changeTicketState")}
      visible={changeStateVisible !== false}
      onOk={handleChangeStateOk}
      onCancel={handleChangeStateCancel}
    >
      <Select
        style={{ width: "100%" }}
        defaultValue={
          changeStateVisible
            ? changeStateVisible.state
              ? changeStateVisible.state.current
                ? changeStateVisible.state.current.state
                  ? changeStateVisible.state.current.state
                  : ""
                : ""
              : ""
            : ""
        }
        onChange={handleChangeStatusSelect}
      >
        <Option value="OPENED">{t("administration::opened")}</Option>
        {/*<Option value="PROCESSING">{t("administration::processing")}</Option>*/}
        <Option value="CLOSED">{t("administration::closed")}</Option>
      </Select>
    </Modal>
  );
};

export default CloseTicketForm;
