import React, { useState } from "react";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import {
  createCategory,
  deleteCategoryService,
  updateCategory,
} from "./categories.api";
import FormComponent from "./components/FormComponent/FromComponents";
import { getCategories } from "../../content.api";
import { useTranslation } from "react-i18next";
import plus from "../../../../assets/plus.svg";
import { Image } from "antd";

const Categories = ({ type, item }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const { t } = useTranslation();
  const columns = [
    {
      title: t("general::title"),
      dataIndex: "title",
      key: "title",
      width: "30%",
    },
    {
      title: t("general::description"),
      dataIndex: "description",
      key: "description",
    },
  ];
  const contentType = "mall";
  type = contentType;

  const createCategories = (params, type) => {
    return createCategory(params, type).then((res) => {
      window.location.reload();
    });
  };

  const updateCategories = (params, type) => {
    return updateCategory(params, type).then((res) => {
      window.location.reload();
    });
  };
  const deleteCategory = (params) => {
    return deleteCategoryService(params).then((res) => {
      window.location.reload();
    });
  };

  return (
    <Container classNames="contents">
      <Crud
        create={{
          apiService: (data) => {
            return createCategories({ ...data, imagePath: "test.jpg" }, type); // added based on jira 864
          },
          modal: {
            title: t("content::createCategory"),
          },
          button: {
            title: t("content::createCategory"),
            icon: (
              <Image src={plus} preview={false} className="icon-add-items" />
            ),
          },
        }}
        get={{
          apiService: (params) => {
            params.type = contentType;
            return getCategories(params);
          },
          columns,
        }}
        remove={{
          apiService: deleteCategory,
        }}
        update={{
          apiService: (data) => {
            return updateCategories(data, type);
          },
          modal: {
            title: t("general::update"),
          },
        }}
      >
        <FormComponent
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </Crud>
    </Container>
  );
};

export default Categories;
