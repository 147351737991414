import { combineReducers } from "redux";
import { privilegesReducer } from "../components/userManagement/components/privileges/privileges.reducer";
import { profileReducer } from "../components/dashboard/pages/profile/profile.reducer";
import { rolesReducer } from "../components/administration/pages/roles/roles.reducer";
import { ContentNewsReducer } from "../components/contentComponent/content.reducer";
import { languageSelectorReducer } from "../components/container/components/header/components/languageSelector/languageSelector.reducer";
import { configReducer } from "../components/configManagement/components/config/config.reducer";
import { profileImageReducer } from "../components/dashboard/pages/profile/profileImage.reducer";

const reducerApp = combineReducers({
  privilegesReducer,
  profileImageReducer,
  profileReducer,
  rolesReducer,
  ContentNewsReducer,
  languageSelectorReducer,
  configReducer,
});

export default reducerApp;
