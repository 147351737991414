import React, { useState, useEffect } from "react";
import Container from "../../../container/Container";
import { useSelector, useDispatch } from "react-redux";
import { Input, Button, Row, Col, Divider, message } from "antd";
import {
  updateConfigLimitRentService,
  updateConfigFreeRentTime,
} from "./configContoller.api";
import { fetchConfigs } from "../../../configManagement/components/config/config.action";

const ConfigController = () => {
  const { configReducer } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [rentLimit, setRentLimit] = useState("");
  const [freeTime, setFreeTime] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setRentLimit(
      configReducer.data !== undefined && configReducer.data.RENT_LIMIT
        ? configReducer.data.RENT_LIMIT
        : null
    );
    setFreeTime(
      configReducer.data !== undefined && configReducer.data.FREE_RENT_TIME
        ? configReducer.data.FREE_RENT_TIME
        : null
    );
  }, [configReducer]);

  const handlerUpdateRentLimit = () => {
    setLoading(true);
    updateConfigLimitRentService(rentLimit)
      .then((res) => {
        message.success("Updated Successfully");
        dispatch(fetchConfigs());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlerUpdateFreeRentTime = () => {
    setLoading(true);
    updateConfigFreeRentTime(freeTime)
      .then((res) => {
        message.success("Updated Successfully");
        dispatch(fetchConfigs());
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Container loading={loading}>
      <Divider orientation="left">Number of Carts Rent Limit</Divider>
      <Row>
        <Col className="gutter-row">
          <Input
            addonBefore={"Number of Carts Rent Limit"}
            type={"number"}
            value={rentLimit}
            onChange={(e) => setRentLimit(e.target.value)}
          />
        </Col>
        <Col className="gutter-row" style={{ margin: "0 5px" }}>
          <Button type="primary" onClick={handlerUpdateRentLimit}>
            Change
          </Button>
        </Col>
      </Row>
      <Divider orientation="left">Initial Free Rent Time (Minutes) </Divider>
      <Row>
        <Col className="gutter-row">
          <Input
            addonBefore={"Initial Free Rent Time (Minutes) "}
            type={"number"}
            value={freeTime}
            onChange={(e) => setFreeTime(e.target.value)}
          />
        </Col>
        <Col className="gutter-row" style={{ margin: "0 5px" }}>
          <Button type="primary" onClick={handlerUpdateFreeRentTime}>
            Change
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ConfigController;
