import React from "react";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import { getReportBatteryService } from "../../reports.api";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import CustomSingleSelectMall from "../../../customFilters/CustomSingleSelectMall";

const Battery = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: "Operators",
      dataIndex: "mallName",
      key: "mallName",
      customFilter: CustomSingleSelectMall,
    },
    {
      title: t("general::lockId"),
      dataIndex: "lockId",
      key: "lockId",
      render: (text) => {
        return <span style={{ color: "#096dd9" }}>{text}</span>;
      },
    },

    {
      title: t("general::batteryCapacity"),
      dataIndex: "batteryCapacity",
      key: "batteryCapacity",
      searchable: true,
      sorter: true,
      render: (text) => {
        return <span style={{ color: "#96053c" }}>{text}%</span>;
      },
    },
  ];

  return (
    <Container>
      <Row>
        <Col span={24}>
          <Crud
            get={{
              apiService: (params) => {
                return getReportBatteryService(params);
              },
              columns,
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Battery;
