import React, { useEffect, useState } from "react";
import { Descriptions, Modal, Spin } from "antd";
import { failedPaymentInfoService } from "../../../../reports.api";
import {
  convertCentToDollar,
  timestampToUTC,
} from "../../../../../../app/util";

const ExtraInfo = (props) => {
  const { extraInfoPopup, setExtraInfoPopup } = props;

  const [Times, setTimes] = useState({ startTime: null, endTime: null });
  const [loading, setloading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [ResponseData, setResponseData] = useState();

  const getFaildTransactionInfo = (id) => {
    setloading(true);
    failedPaymentInfoService(id)
      .then((response) => {
        const { startTime, endTime } = response.data;
        setResponseData(response.data);
        const convertedStartTime = timestampToUTC(startTime);
        const convertedEndTime = timestampToUTC(endTime);
        setTimes({ startTime: convertedStartTime, endTime: convertedEndTime });
      })
      .catch((error) => {
        setErrorMessage("Some network error occured . Try again later ");

        console.log("error in getting info", error, id);
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (extraInfoPopup && extraInfoPopup.orderId) {
      getFaildTransactionInfo(extraInfoPopup.orderId);
    } else {
      setErrorMessage("Order info is not available");
    }

    return () => {
      setTimes({ startTime: null, endTime: null });
      setErrorMessage();
      setResponseData();
    };
  }, [extraInfoPopup]);

  return (
    <Modal
      title={"Information"}
      visible={extraInfoPopup !== false}
      onOk={() => setExtraInfoPopup(false)}
      onCancel={() => setExtraInfoPopup(false)}
      cancelText={"Close"}
      okButtonProps={{ hidden: true }}
      destroyOnClose
    >
      {!errorMessage && loading ? (
        <Spin />
      ) : !errorMessage && !loading ? (
        <Descriptions className={"subscriber-extra-info"} bordered>
          <Descriptions.Item span={24} label={"StartTime"}>
            {Times.startTime}
          </Descriptions.Item>
          <Descriptions.Item span={24} label={"EndTime"}>
            {Times.endTime}
          </Descriptions.Item>
          <Descriptions.Item span={24} label={"Lock Id"}>
            {ResponseData?.invoice.additionalCode}
          </Descriptions.Item>
          <Descriptions.Item span={24} label={"Payment"}>
            {convertCentToDollar(ResponseData?.invoice.finalCost)}
          </Descriptions.Item>
        </Descriptions>
      ) : errorMessage && !loading ? (
        <p>{errorMessage}</p>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default ExtraInfo;
