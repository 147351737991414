import { AutoComplete, Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getMallService } from "../../../../operation.api";
import { getFieldValue } from "../../../../../../app/util";

const { Option } = Select;

const CreateStrollerForm = ({ record, form }) => {
  const { t } = useTranslation();
  const [disableItem, setDisableItem] = useState(true);
  const [mallList, setMallList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState({ label: "", value: "" });

  const changeDisableItem = () => setDisableItem(!disableItem);

  const onSearch = (searchText) => setSearchValue(searchText);

  const onSelect = (data, option) => {
    setSearchResult(option);
    if (form) {
      form.setFieldsValue({ mallId: option.value, mallName: option.label });
    }
  };

  function fetchAllMalls() {
    setLoading(true);
    getMallService({ title: searchValue, pageSize: 10 })
      .then((r) => {
        const { content } = r.data;
        const temp = content.map((i) => {
          if (i.id === searchValue) {
            setMallList(getFieldValue(i.title));
          }

          return {
            label: i.title && i.title.fields[0],
            value: i.id,
          };
        });
        setMallList(temp);
      })
      .finally(() => setLoading(false));
  }

  useEffect(
    () => {
      fetchAllMalls();
    },
    // eslint-disable-next-line
    [searchValue]
  );

  return (
    <>
      <Form.Item label="Lock QR Code" name="code" hasFeedback>
        <Input
          suffix={
            record && (
              <Button onClick={changeDisableItem}>
                {disableItem ? "Change" : "be changed"}
              </Button>
            )
          }
          disabled={record && record.code ? disableItem : false}
        />
      </Form.Item>

      <Form.Item label="Additional Code" name="additionalCode" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="mallId" rules={[{ required: true }]} hidden>
        <Input />
      </Form.Item>

      <Form.Item
        label="Operator"
        name="mallName"
        rules={[{ required: true }]}
        hasFeedback
      >
        <AutoComplete
          allowClear
          options={mallList}
          style={{
            width: "100%",
          }}
          onSelect={onSelect}
          onSearch={onSearch}
          placeholder="Enter Name"
          loading={loading}
          value={searchResult && searchResult.label}
        />
      </Form.Item>

      <Form.Item
        name="currentState"
        label={t("carts::currentState")}
        rules={[
          {
            required: true,
            message: t("general::atLeastOneOptionShouldSelect"),
          },
        ]}
        hasFeedback
      >
        <Select name="currentState" style={{ width: "100%" }}>
          <Option value="LOCKED">{t("carts::locked")}</Option>
          {/* <Option value="UNLOCKED">{t("carts::unlocked")}</Option> */}
          <Option value="OUT_OF_SERVICE">{t("carts::outOfService")}</Option>
        </Select>
      </Form.Item>
    </>
  );
};

export default CreateStrollerForm;
