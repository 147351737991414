import { APP_Permissions } from "./privileges.constant";

// const permissions_example = [
// APP_Permissions.file["all"]
// ];

// Todo: refactor async
// Todo: check and better performance
// Todo: show loading when function is in-progress
export function haveAccess(modulePermissions) {
  if (!modulePermissions) {
    return true;
  }

  let serverPrivileges = sessionStorage.getItem("privileges");
  if (!serverPrivileges) {
    // return "loading";
    return false;
  } else {
    serverPrivileges = JSON.parse(serverPrivileges);
  }
  //console.log(serverPrivileges, "serverPrivileges");
  let roles = sessionStorage.getItem("roles");
  if (!roles) {
    // return "loading";\
    return false;
  } else {
    roles = JSON.parse(roles);
  }

  let userRoles = sessionStorage.getItem("profile");
  if (!userRoles) {
    // return "loading";\
    return false;
  } else {
    userRoles = JSON.parse(userRoles).roles;
  }

  let result = true;
  modulePermissions.forEach((p) => {
    let permission = p.split("_");
    let permission_module = permission[0];
    let permission_val = permission[1];

    // TODO: check "all"
    // let AppPermission = APP_Permissions[permission_module];

    let AppAlias = APP_Permissions["alias"][permission_module];

    // generate api.v1.module.bla
    let names = [`${permission_module}.${permission_val}`];
    if (AppAlias && AppAlias.length > 0) {
      AppAlias.forEach((n) => {
        names.push(`${n}.${permission_val}`);
      });
    }

    // this permission in which of Roles
    let whichRoles = [];
    for (let roleName in roles) {
      let rolePrivileges = roles[roleName];
      rolePrivileges.forEach((p) => {
        names.forEach((n) => {
          n = `api.v1.${n}`;
          if (n === p) {
            whichRoles.push(roleName);
          }
        });
      });
    }

    if (whichRoles.length === 0) {
      // console.warn(`permission is Not in Privileges! : ${permission}`);
    }

    // user have that's roles?
    if (userRoles.some((i) => whichRoles.some((j) => j === i)) === false) {
      result = false;
    }
  });

  return result;
}
