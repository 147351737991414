import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

const FilterFromTo = (props) => {
  const { setFilter, reset, setReset, fromToInitialValue } = props;
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const handleCallback = (start, end) => {
    setFrom(start);
    setTo(end);
  };
  const handleEvent = (event, picker) => {
    setFrom(picker.startDate);
    setTo(picker.endDate);
  };
  const handlerCancel = (event, picker) => {
    setFrom("");
    setTo("");
  };
  useEffect(
    () => {
      setFilter((prevData) => ({
        ...prevData,
        from: from,
        to: to,
      }));
    },
    // eslint-disable-next-line
    [from, to]
  );

  useEffect(
    () => {
        if (fromToInitialValue){
            setTo(fromToInitialValue.to);
            setFrom(fromToInitialValue.from);
        }
      setFrom();
      setTo();
      setFilter();
      setReset(false);
    },
    // eslint-disable-next-line
    [reset]
  );

    useEffect(() => {
        if (fromToInitialValue){
        setTo(fromToInitialValue.to);
        setFrom(fromToInitialValue.from);
        }
    }, [fromToInitialValue]);


  return (
    <div className={"range-picker"}>
      <DateRangePicker
        initialSettings={{
          // startDate: from,
          // endDate: to,
          autoUpdateInput: false,
          timePicker: true,
          locale: {
            cancelLabel: "Clear",
          },
          ranges: {
            Today: [moment().set({ hour: 0, minute: 0, second: 1 }), moment()],
            Yesterday: [
              moment()
                .subtract(1, "days")
                .set({ hour: 0, minute: 0, second: 1 }),
              moment()
                .subtract(1, "days")
                .set({ hour: 23, minute: 59, second: 59 }),
            ],
            "Last 7 Days": [
              moment()
                .subtract(6, "days")
                .set({ hour: 0, minute: 0, second: 1 }),
              moment(),
            ],
            "Last 30 Days": [
              moment()
                .subtract(29, "days")
                .set({ hour: 0, minute: 0, second: 1 }),
              moment(),
            ],
            "This Month": [
              moment().startOf("month").set({ hour: 0, minute: 0, second: 1 }),
              moment().endOf("month"),
            ],
            "Last Month": [
              moment()
                .subtract(1, "month")
                .startOf("month")
                .set({ hour: 0, minute: 0, second: 1 }),
              moment().subtract(1, "month").endOf("month"),
            ],
          },
        }}
        onCallback={handleCallback}
        onEvent={handleEvent}
        onCancel={handlerCancel}
        placeholder="Select a range"
      >
        <input
          style={{ width: "100%" }}
          value={
            from !== undefined && to !== undefined
              ? `${moment(from).local().format("YYYY/MM/DD HH:mm:ss")}-${moment(
                  to
                ).local().format("YYYY/MM/DD HH:mm:ss")}`
              : ""
          }
        />
      </DateRangePicker>
    </div>
  );
};

export default FilterFromTo;
