import React, { useState } from "react";
import { Layout } from "antd";
import "./container.style.scss";
import PropTypes from "prop-types";
import SideBar from "./components/sidebar/SideBar";
import PageContainer from "./components/pageContainer/PageContainer";
import Header from "./components/header/Header";

const Container = ({
  children,
  loading = false,
  classNames = "",
  ...props
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => setCollapsed(!collapsed);

  return (
    <Layout style={{ width: "100vw", height: "100vh" }}>
      <SideBar collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout className="site-layout">
        <Header collapsed={collapsed} toggle={toggle} />
        <PageContainer
          children={children}
          loading={loading}
          classNames={classNames}
        />
      </Layout>
    </Layout>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  classNames: PropTypes.string,
};

export default Container;
