import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCategoriesService } from "../../faqCategory/faqCategory.api";

const { Option } = Select;
const FaqCreateForm = ({ forUpdate = false, ...props }) => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCategories();
  }, []);

  function getCategories() {
    setLoading(true);
    getCategoriesService({ pageNumber: 0, pageSize: 100 })
      .then((r) => {
        const { content } = r.data;
        if (content) {
          setCategories(content);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Form.Item
        label={t("administration::subject")}
        name={"subject"}
        rules={[{ required: true }]}
        hasFeedback
      >
        <Input disabled={forUpdate} />
      </Form.Item>
      <Form.Item
        label={t("administration::question")}
        name={"question"}
        rules={[{ required: true }]}
        hasFeedback
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("administration::answer")}
        name={"answer"}
        rules={[{ required: true }]}
        hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("administration::category")}
        name={"categoryId"}
        rules={[{ required: true }]}
        hasFeedback
      >
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          disabled={loading || forUpdate}
        >
          {categories &&
            categories.length > 0 &&
            categories.map((item, i) => (
              <Option key={i} value={item.id}>
                {item.title}
              </Option>
            ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default FaqCreateForm;
