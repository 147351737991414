import { Form, Input, Select } from "antd";
import React from "react";

import { useTranslation } from "react-i18next";

const FormComponent = (props) => {
  const { t } = useTranslation();
  const { Option } = Select;

  return (
    <>
      <Form.Item
        name={"applicationName"}
        label={t("forceUpdate::applicationName")}
        rules={[{ required: true, message: "applicationName is required" }]}
        hasFeedback
      >
        <Input placeholder={"Foo"} />
      </Form.Item>

      <Form.Item
        name={"versionName"}
        label={t("forceUpdate::versionName")}
        hasFeedback
        rules={[
          {
            required: true,
            message: "versionName is required",
          },

          {
            pattern: /^\d+(?:\.\d+){2}$/,
            message: "Please enter a valid version",
          },
        ]}
      >
        <Input placeholder={"1.0.1"} />
      </Form.Item>

      <Form.Item
        name={"packageName"}
        label={t("forceUpdate::packageName")}
        hasFeedback
        rules={[
          {
            required: true,
            message: "packageName is required",
          },
          {
            pattern: /^[a-z0-9-]+(.[a-z0-9-]+)*/i,
            message: "Please enter a valid name",
          },
        ]}
      >
        <Input placeholder={"x.y.z"} />
      </Form.Item>

      <Form.Item
        name={"versionCode"}
        label={t("forceUpdate::versionCode")}
        hasFeedback
        rules={[
          {
            required: true,
            message: "versionCode is required",
          },
          {
            pattern: /^[0-9]$/,
            message: "Please enter a valid name",
          },
        ]}
      >
        <Input placeholder={5} />
      </Form.Item>

      <Form.Item
        name={"link"}
        label={t("forceUpdate::link")}
        rules={[
          {
            required: true,
            message: "link is required",
          },

          {
            pattern:
              /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/,
            message: "Please enter a valid link",
          },
        ]}
      >
        <Input placeholder={"please enter link "} />
      </Form.Item>

      <Form.Item
        name={"applicationType"}
        label={t("forceUpdate::applicationType")}
        rules={[
          {
            required: true,
            message: "applicationType is required",
          },
        ]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder={t("forceUpdate::chooseTheApplicationType")}
        >
          <Option key="IOS" value="IOS" name="IOS">
            {t("forceUpdate::IOS")}
          </Option>
          <Option key="ANDROID" value="ANDROID" name="ANDROID">
            {t("forceUpdate::ANDROID")}
          </Option>
        </Select>
      </Form.Item>

      <Form.Item
        name={"updateMode"}
        label={t("forceUpdate::updateMode")}
        rules={[
          {
            required: true,
            message: "link is updateMode",
          },
        ]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder={t("forceUpdate::chooseTheApplicationMode")}
        >
          <Option key="RECOMMENDED" value="RECOMMENDED" name="RECOMMENDED">
            {t("forceUpdate::RECOMMENDED")}
          </Option>
          <Option key="FORCE" value="FORCE" name="FORCE">
            {t("forceUpdate::FORCE")}
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={["metaData", "title"]}
        label={t("forceUpdate::title")}
        rules={[{ required: true, message: "title is required" }]}
        hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["metaData", "changes"]}
        label={t("forceUpdate::changes")}
        rules={[{ required: true, message: "changes is required" }]}
        hasFeedback
      >
        <Select mode="tags" placeholder="Tags Mode" />
      </Form.Item>
    </>
  );
};

export default FormComponent;
