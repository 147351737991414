import React, { useEffect, useState } from "react";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import {
  getRentsReportsStatisticalService,
  getReportExcelService,
  getReportFinancialService,
} from "../../reports.api";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, message, Row } from "antd";
import "./financial.style.scss";
import {
  convertCentToDollar,
  errorMessageHandler,
  convertSecondsToDhms,
  convertMinuteToDhm,
} from "../../../../app/util";
import moment from "jalali-moment";
import CustomSelectMall from "../../../customFilters/CustomSelectMall";

const Financial = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState({});
  const [rentStatistical, setRentStatistical] = useState({
    numberOfMalls: 0,
    numberOfRents: 0,
    numberOfStrollers: 0,
    numberOfSubscribers: 0,
    totalDurationMinutes: 0,
    totalPrice: 0,
  });

  const columns = [
    {
      title: t("general::email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("general::mobile"),
      dataIndex: "mobile",
      key: "mobile",
      render: (mobile) => {
        if (mobile === null) return "-";
        else {
          return mobile;
        }
      },
    },

    {
      title: "Operators",
      dataIndex: "mallName",
      key: "mallIds",
      customFilter: CustomSelectMall,
    },
    {
      title: t("financial::cartId"),
      dataIndex: "additionalCode",
      key: "additionalCode",
    },
    {
      title: t("financial::sales"),
      dataIndex: "price",
      key: "price",
      render: (price) => {
        return convertCentToDollar(price);
      },
    },
    {
      title: t("financial::paymentTime"),
      dataIndex: "paymentTime",
      key: "paymentTime",
      filter: "fromTo",
      render: (text) => {
        if (text) {
          return moment(text).format("YYYY/MM/DD ");
        } else {
          return "-";
        }
      },
    },
    {
      title: t("financial::duration") + " (DD:HH:MM:SS)",
      dataIndex: "durationInSeconds",
      key: "durationInSeconds",
      render: (durationInSeconds) => {
        return convertSecondsToDhms(durationInSeconds);
      },
    },
  ];

  useEffect(
    () => {
      if (dateFilter && dateFilter.from) {
        getRentsReportsStatistical(dateFilter);
      }

      if (dateFilter && dateFilter.from === undefined) {
        setRentStatistical({
          ...rentStatistical,
          totalDurationMinutes: 0,
          totalPrice: 0,
        });
      }
    },
    // eslint-disable-next-line
    [dateFilter]
  );

  const getRentsReportsStatistical = (params) => {
    getRentsReportsStatisticalService(params)
      .then((res) => {
        setRentStatistical(res.data);
      })
      .catch((error) => {
        message.error(errorMessageHandler(error), 3);
      });
  };

  function downloadExcel() {
    const params = new URLSearchParams();
    dateFilter &&
      dateFilter.to &&
      params.append("rentTo", dateFilter.to.format("YYYY-MM-DDTHH:mm:ss"));

    dateFilter &&
      dateFilter.from &&
      params.append("rentFrom", dateFilter.from.format("YYYY-MM-DDTHH:mm:ss"));

    dateFilter.mallIdSet &&
      dateFilter.mallIdSet.forEach((mallId) => {
        params.append("mallIdSet", mallId);
      });

    getReportExcelService(params, "integrated.csv", setLoading);
  }

  return (
    <Container loading={loading}>
      <Row gutter={16} className={"total"}>
        <Col
          span={6}
          className={"center-flex"}
          style={{
            margin: "10px 0px",
          }}
        >
          <Button onClick={() => downloadExcel()}> Excel Download </Button>
        </Col>
        <Col span={6} />
        <Col span={6}>
          <Card>
            Total Sales :
            <strong>{convertCentToDollar(rentStatistical.totalPrice)}</strong>
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            {t("general::totalDuration")} :
            <strong>
              {convertMinuteToDhm(rentStatistical.totalDurationMinutes)}
            </strong>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Crud
            get={{
              // api: `${API}/integrated`,
              apiService: (params) => {
                console.log(params, "paramdddds");
                // if (
                //   params &&
                //   params.from !== undefined &&
                //   params.to !== undefined
                // )
                setDateFilter({
                  from: params.from,
                  to: params.to,
                  mallIdSet: params.mallIds,
                });
                return getReportFinancialService(params);
              },
              columns,
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Financial;
