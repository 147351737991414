import React, { useEffect, useMemo, useState } from "react";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  errorMessageHandler,
  getFieldValue,
  getNormalizeObj,
} from "../../../../app/util";
import { getCategories } from "../../../contentComponent/content.api";
import { Button, Descriptions, Image, message } from "antd";
import { API, endRentByMall, getMallService } from "../../operation.api";
import CustomSingleSelectMall from "../../../customFilters/CustomSingleSelectMall";
import moment from "moment";
import plus from "../../../../assets/plus.svg";
import "./properties.scss";

export const Properties = () => {
  const { catId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [categoryIds, setCategoryIds] = useState({});
  const [newValue, setNewValue] = useState(false);

  useMemo(() => {
    console.log("useMemo", catId);

    // updateState(catId);setNewValuesetNewValuesetNewValue
    setNewValue(true);
  }, [catId]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      customFilter: CustomSingleSelectMall,
      sorter: true,
      sortKey: "title",
      render: (text) => {
        return getFieldValue(text);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        if (text && text === "PUBLISHED") {
          return <span style={{ color: "green" }}>ACTIVE</span>;
        } else if (text && text === "DRAFT")
          return <span style={{ color: "red" }}>INACTIVE</span>;
      },
    },
    {
      title: "Active Rent",
      dataIndex: ["strollersCount", "unlocked"],
      key: "unlocked",
    },
    {
      title: "Publish Date",
      dataIndex: "publishDate",
      key: "publishDate",
      sorter: true,
      render: (text) => {
        if (text) {
          return moment(Number(text)).format("YYYY/MM/DD");
        }
      },
    },
  ];

  const expandable = {
    expandedRowRender: (record) => {
      return (
        <Descriptions className={"content-table-description"}>
          <Descriptions.Item
            label={"address"}
            children={record.metaData.address}
          />
          <Descriptions.Item label={"lat"} children={record.lat} />
          <Descriptions.Item label={"lng"} children={record.lng} />
          <Descriptions.Item
            label={"banner"}
            children={
              record.banner.fields
                ? record.banner.fields.map((field, index) => {
                    return (
                      <img
                        src={field}
                        key={index}
                        alt={"field"}
                        width={150}
                        height={150}
                      />
                    );
                  })
                : "-"
            }
          />
        </Descriptions>
      );
    },
  };

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line
  }, []);

  const fetchCategories = () => {
    setLoading(true);
    getCategories()
      .then((response) => {
        setCategoryIds(getNormalizeObj(response.data.content));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(errorMessageHandler(error), 3);
      });
  };

  return (
    <Container loading={loading}>
      <Link to={`/operations/mall-management/${catId}/create`}>
        <Button className={"create-button"}>
          <Image preview={false} src={plus} />
          Create{" "}
          {categoryIds &&
          catId &&
          categoryIds[catId] &&
          categoryIds[catId]["title"]
            ? categoryIds[catId]["title"]
            : ""}
        </Button>
      </Link>

      <Crud
        loading={setNewValue && loading}
        otherAction={[
          {
            name: "edit",
            func: (record) => {
              history.push(
                `/operations/mall-management/${record.catsId[0]}/edit/${record.id}`
              );
            },
          },
          {
            name: "stations",
            func: (record, fetchData) => {
              history.push(
                `/operations/mall-management/${record.catsId[0]}/station/${record.id}`
              );
            },
          },
          {
            name: "endRent",
            func: (record, fetchData) => {
              return endRentByMall(record);
            },
          },
          {
            name: "owner",
            func: (record, fetchData) => {
              history.push(
                `/operations/mall-management/${catId}/owner/${record.id}`
              );
            },
          },
        ]}
        get={{
          // api: "/v1/cuddle/malls",
          apiService: (params) => {
            params.categoryId = catId;
            if (newValue) {
              params.current=1;
              params.pageNumber = 0;
              setNewValue(false);
            }
            return getMallService(params);
          },
          columns,
          tableProps: {
            expandable,
            catId,
            properties:newValue
          },
          fetchDataDependency: [catId],
        }}
        remove={{
          api: API,
        }}
      />
    </Container>
  );
};

export default Properties;
