import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../container/Container";

const Reports = () => {
  const { t } = useTranslation();
  return <Container className="dashboard">{t("general::reports")}</Container>;
};

export default Reports;
