import { HttpService } from "../../app/apiService";
import { message } from "antd";

export const API = "/v1/cuddle/malls";
export const API_RENT = "/v1/cuddle/rents/end-all";
export const MallsApiAdmin = "v1/cuddle/malls/admin";

export const stationService = (data) => {
  if (!data.id) {
    message.error("id not found!", 3);
    return false;
  }

  let params = {
    byChildren: true,
    ...data,
  };

  return HttpService.get(`${API}/${data.id}`, { params });
};

export const endRentByMall = (data) => {
  if (!data && !data.id) {
    return;
  }
  let params = {
    mallId: data.id,
  };

  message.success("all strollers end rent was successful", 3);

  return HttpService.post(API_RENT, { params });
};

export const createMallService = (data) => {
  let params = {
    ...data,
    content: [data.title],
  };
  return HttpService.post(API, params);
};

export const updateMallService = (data) => {
  let params = {
    ...data,
    content: [data.title],
  };
  return HttpService.put(`${API}/${data.id}`, params);
};

export const getMallService = (params) => {
  if (params.title === undefined || params.title === "") {
    delete params.title;
  }
  if (params.catsId === undefined) {
    delete params.catsId;
  }
  params = {
    sortKey: "publishDate",
    sort: "DESC",
    pageNumber: 0,
    categoryId: params.catId,
    ...params,
  };
  return HttpService.get(MallsApiAdmin, { params });
};

export const getMallByIdService = (id) => {
  return HttpService.get(`${API}/${id}`);
};
