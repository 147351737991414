import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import FaqCreateForm from "./components/FaqCreateForm";
import { API, getMessagesService, updateFaqService } from "./faq.api";

const Faq = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("support::subject"),
      dataIndex: "subject",
      key: "subject",
      searchable: true,
    },
    {
      title: t("support::question"),
      dataIndex: "question",
      key: "question",
    },
    {
      title: t("support::answer"),
      dataIndex: "answer",
      key: "answer",
    },
  ];

  return (
    <Container classNames="contents">
      <Crud
        get={{
          // api: API,
          apiService: getMessagesService,
          columns,
        }}
        remove={{
          api: API,
        }}
        create={{
          api: API,
          modal: {
            title: t("support::createQuestion"),
          },
          button: {
            title: t("support::createQuestion"),
          },
        }}
        update={{
          apiService: updateFaqService,
          modal: {
            title: t("general::Update"),
          },
        }}
      >
        <FaqCreateForm />
      </Crud>
    </Container>
  );
};

export default Faq;
