import React, { useState } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import FileManager from "../../../fileManager/FileManager";
import DeleteConfirmation from "../../../contentComponent/components/createContent/components/sections/components/deleteSection/DeleteSelection";

const MediaSection = (props) => {
  const { t } = useTranslation();
  const {
    index,
    deleteFunction,
    value: fileUrl,
    onChange: setValue,
    accept,
    name,
    openPopUp = false,
  } = props;
  const [showFileManager, setShowFileManager] = useState(openPopUp);

  return (
    <div key={index ? `index-template-${index}` : "1"}>
      <div className="input-sections">
        <Row className="input-sections-wrapper flex-center">
          <Col span={24}>
            {fileUrl && fileUrl.length !== 0 && (
              <a
                href={fileUrl}
                target="_blank"
                className="flex-center"
                rel="noopener noreferrer"
              >
                <img src={fileUrl} alt="uploaded" width="100px" />
                <p className="mr-1 ml-1">{t("content::clickOnThePicture")}</p>
              </a>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FileManager
              showFileManager={showFileManager}
              setShowFileManager={setShowFileManager}
              setValue={setValue}
              accept={accept}
              name={name}
              directoryName={name}
            />
          </Col>
        </Row>

        {deleteFunction && (
          <DeleteConfirmation deleteFunction={deleteFunction} />
        )}
      </div>
    </div>
  );
};

export default MediaSection;
