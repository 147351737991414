export const PrivilegesReduxTypes = {
  FETCH_PERMISSIONS: "FETCH_PERMISSIONS",
  FETCH_PERMISSIONS_SUCCESS: "FETCH_PERMISSIONS_SUCCESS",
  FETCH_PERMISSIONS_FAILURE: "FETCH_PERMISSIONS_FAILURE",
};

export const APP_Permissions = {
  alias: {},
  // user: {
  //   user_getAll: "user_getAll",
  // },
  malls: {
    "cuddle_malls.getMallList": "cuddle_malls.getMallList",
    "cuddle_malls.admin.getMallPage": "cuddle_malls.admin.getMallPage",
    "cuddle_malls.create": "cuddle_malls.create",
    "cuddle_malls.update": "cuddle_malls.update",
    "cuddle_malls.get": "cuddle_malls.get",
    "cuddle_malls.stroller.get": "cuddle_malls.stroller.get",
    "cuddle_malls.delete": "cuddle_malls.delete",
    all: "malls_all",
  },
  strollers: {
    "cuddle_strollers.update": "cuddle_strollers.update",
    "cuddle_strollers.delete": "cuddle_strollers.delete",
    "cuddle_strollers.count.mallId": "cuddle_strollers.count.mallId",
    "cuddle_strollers.getAll": "cuddle_strollers.getAll",
    "cuddle_strollers.get": "cuddle_strollers.get",
    "cuddle_strollers.create": "cuddle_strollers.create",
    all: "strollers_all",
  },
  rents: {
    "cuddle_rents.create": "cuddle_rents.create",
    "cuddle_rents.get.inprogress": "cuddle_rents.get.inprogress",
    "cuddle_rents.end-by-id": "cuddle_rents.end-by-id",
    "cuddle_rents.invoice.edit": "cuddle_rents.invoice.edit",
    "cuddle_rents.end": "cuddle_rents.end",
    "cuddle_rents.status": "cuddle_rents.status",
    "cuddle_rents.end-all": "cuddle_rents.end-all",
    "cuddle_rents.get": "cuddle_rents.get",
    rents: "rents_all",
  },
  config: {
    "cuddle_config.update": "cuddle_config.update",
    "cuddle_config.getAll": "cuddle_config.getAll",
    all: "config.getAll",
  },
  transactions: {
    "cuddle_transactions.getAll": "cuddle_transactions.getAll",
    "cuddle_transactions.stroller.getAll":
      "cuddle_transactions.stroller.getAll",
    all: "cuddle_transactions.stroller.getAll",
  },
  pivotalPermission: {
    "cuddle_pivotal-permission.values.getAll":
      "cuddle_pivotal-permission.values.getAll",
    all: "cuddle_pivotal-permission.values.getAll",
  },
  reports: {
    "cuddle_rents.create": "cuddle_rents.create",
    "cuddle_rents.get.inprogress": "cuddle_rents.get.inprogress",
    "cuddle_rents.end-by-id": "cuddle_rents.end-by-id",
    "cuddle_rents.invoice.edit": "cuddle_rents.invoice.edit",
    "cuddle_rents.end": "cuddle_rents.end",
    "cuddle_rents.status": "cuddle_rents.status",
    "cuddle_rents.end-all": "cuddle_rents.end-all",
    "cuddle_rents.get": "cuddle_rents.get",
    "cuddle_rents.reports.integrated": "cuddle_rents.reports.integrated",
    "cuddle_rents.reports.mall-grouped": "cuddle_rents.reports.mall-grouped",
    "cuddle_rents.reports.stroller-grouped":
      "cuddle_rents.reports.stroller-grouped",
    "cuddle_rents.reports.battery": "cuddle_rents.reports.battery",
    "cuddle_rents.reports.integrated.csv":
      "cuddle_rents.reports.integrated.csv",
    "cuddle_rents.reports.mall-grouped.csv":
      "cuddle_rents.reports.mall-grouped.csv",
    "cuddle_rents.reports.stroller-grouped.csv":
      "cuddle_rents.reports.stroller-grouped.csv",
    "cuddle_rents.reports.statistical": "cuddle_rents.reports.statistical",
    all: "reports_all",
  },
  subscriber: {
    "subscriber_verification.create": "subscriber_verification.create",
    "subscriber_verification.mobile.verify":
      "subscriber_verification.mobile.verify",
    subscriber_delete: "subscriber_delete",
    subscriber_create: "subscriber_create",
    subscriber_update: "subscriber_update",
    subscriber_selectiveUpdate: "subscriber_selectiveUpdate",
    subscriber_getAll: "subscriber_getAll",
    subscriber_get: "subscriber_get",
    "subscriber_profile.edit": "subscriber_profile.edit",
    subscriber_report: "subscriber_report",
    "subscriber_profile.uploadProfilePicture":
      "subscriber_profile.uploadProfilePicture",
    "subscriber_profile.get": "subscriber_profile.get",
    subscriber_updatePassword: "subscriber_updatePassword",
    subscriber_lock: "subscriber_lock",
    subscriber_unlock: "subscriber_unlock",
    "subscriber_admin-panel.update": "subscriber_admin-panel.update",
    "subscriber_admin-panel.getAll": "subscriber_admin-panel.getAll",
    all: "subscriber_all",
  },
  profile: {},
  version: {
    "application_version.get": "application_version.get",
    "application_version.update": "application_version.update",
    "application_version.delete": "application_version.delete",
    "application_version.create": "application_version.create",
    "application_version.status": "application_version.status",
    "application_version.get.all": "application_version.get.all",
    all: "version_all",
  },
  location: {
    "locations_tags.get": "locations_tags.get",
    "locations_tags.update": "locations_tags.update",
    "locations_tags.delete": "locations_tags.delete",
    "locations_tags.create": "locations_tags.create",
    "locations_tags.getList": "locations_tags.getList",
    "location_countries.states.getAll": "location_countries.states.getAll",
    "location_countries.states.cities.getAll":
      "location_countries.states.cities.getAll",
    "location_countries.getAll": "location_countries.getAll",
    locations_get: "locations_get",
    locations_getPaginatedLocationsList: "locations_getPaginatedLocationsList",
    locations_getLocationsList: "locations_getLocationsList",
    locations_add: "locations_add",
    locations_edit: "locations_edit",
    locations_delete: "locations_delete",
    "locations_categories.add": "locations_categories.add",
    "locations_categories.edit": "locations_categories.edit",
    "locations_categories.delete": "locations_categories.delete",
    "locations_categories.list": "locations_categories.list",
    "locations_categories.get": "locations_categories.get",
    "locations_properties.getProperty": "locations_properties.getProperty",
    "locations_properties.getPropertiesList":
      "locations_properties.getPropertiesList",
    "locations_properties.createProperty":
      "locations_properties.createProperty",
    "locations_properties.updateProperty":
      "locations_properties.updateProperty",
    "locations_properties.deleteProperty":
      "locations_properties.deleteProperty",
    all: "locations_all",
  },
  ratingOpinion: {
    all: "ratingOpinion_all",
  },
  rating: {
    "rating_opinion.get": "rating_opinion.get",
    "rating_opinion.update": "rating_opinion.update",
    "rating_opinion.delete": "rating_opinion.delete",
    "rating_opinion.create": "rating_opinion.create",
    "rating_opinion.addKey": "rating_opinion.addKey",
    "rating_opinion.removeKey": "rating_opinion.removeKey",
    "rating_opinion.getAll": "rating_opinion.getAll",
    rating_get: "rating_get",
    rating_update: "rating_update",
    rating_delete: "rating_delete",
    rating_create: "rating_create",
    rating_getAll: "rating_getAll",
    all: "rating_all",
  },
  mgs: {
    "mgs_push.token.send": "mgs_push.token.send",
    "mgs_push.token.create": "mgs_push.token.create",
    "mgs_push.token.update": "mgs_push.token.update",
    "mgs_push.token.getAll": "mgs_push.token.getAll",
    "mgs_push.token.get": "mgs_push.token.get",
    "mgs_push.token.delete": "mgs_push.token.delete",
    "mgs_mail-server.create": "mgs_mail-server.create",
    "mgs_mail-server.update": "mgs_mail-server.update",
    "mgs_mail-server.delete": "mgs_mail-server.delete",
    "mgs_mail-server.get": "mgs_mail-server.get",
    "mgs_mail-server.getAll": "mgs_mail-server.getAll",
    "mgs_mail-server.activate": "mgs_mail-server.activate",
    "mgs_subscriber.sendSms": "mgs_subscriber.sendSms",
    "mgs_subscriber.sendMail": "mgs_subscriber.sendMail",
    "mgs_push.server.update": "mgs_push.server.update",
    "mgs_push.server.getAll": "mgs_push.server.getAll",
    "mgs_push.server.get": "mgs_push.server.get",
    "mgs_push.server.delete": "mgs_push.server.delete",
    "mgs_push.server.create": "mgs_push.server.create",
    "mgs_sms-server.create": "mgs_sms-server.create",
    "mgs_sms-server.update": "mgs_sms-server.update",
    "mgs_sms-server.delete": "mgs_sms-server.delete",
    "mgs_sms-server.get": "mgs_sms-server.get",
    "mgs_sms-server.getAll": "mgs_sms-server.getAll",
    "mgs_sms-server.activate": "mgs_sms-server.activate",
    all: "mgs_all",
  },
  securityServer: {
    "security-server_role-permission.getAll":
      "security-server_role-permission.getAll",
    "security-server_role-permission.get":
      "security-server_role-permission.get",
    "security-server_role-permission.add":
      "security-server_role-permission.add",
    "security-server_role-permission.edit":
      "security-server_role-permission.edit",
    "security-server_role-permission.selectiveEdit":
      "security-server_role-permission.selectiveEdit",
    "security-server_role-permission.delete":
      "security-server_role-permission.delete",
    "security-server_role-permission.privileges":
      "security-server_role-permission.privileges",
    "security-server_pivotal-permission.delete":
      "security-server_pivotal-permission.delete",
    "security-server_pivotal-permission.getPage":
      "security-server_pivotal-permission.getPage",
    "security-server_pivotal-permission.get":
      "security-server_pivotal-permission.get",
    "security-server_pivotal-permission.add":
      "security-server_pivotal-permission.add",
    "security-server_pivotal-permission.edit":
      "security-server_pivotal-permission.edit",
    "security-server_black-white.delete": "security-server_black-white.delete",
    "security-server_black-white.get": "security-server_black-white.get",
    "security-server_black-white.getPage":
      "security-server_black-white.getPage",
    "security-server_black-white.add": "security-server_black-white.add",
    "security-server_black-white.edit": "security-server_black-white.edit",
    all: "security-server_all",
  },
  wallet: {
    wallet_doTransaction: "wallet_doTransaction",
    "wallet_subscriber.getTransaction": "wallet_subscriber.getTransaction",
    wallet_getAllTransactions: "wallet_getAllTransactions",
    wallet_getAllSubscriberTransactions: "wallet_getAllSubscriberTransactions",
    "wallet_subscriber.doTransaction": "wallet_subscriber.doTransaction",
    wallet_getRelatedSum: "wallet_getRelatedSum",
    wallet_getRelatedRevenueList: "wallet_getRelatedRevenueList",
    wallet_getBalance: "wallet_getBalance",
    "wallet_bank-card.delete": "wallet_bank-card.delete",
    "wallet_bank-card.create": "wallet_bank-card.create",
    "wallet_bank-card.update": "wallet_bank-card.update",
    "wallet_bank-card.getAll": "wallet_bank-card.getAll",
    "wallet_bank-card.get": "wallet_bank-card.get",
    all: "wallet_all",
  },
  campaign: {
    "campaign_Campaign.updateCampaign": "campaign_Campaign.updateCampaign",
    "campaign_Campaign.createCampaign": "campaign_Campaign.createCampaign",
    "campaign_Campaign.getCampaign": "campaign_Campaign.getCampaign",
    "campaign_Campaign.getListCampaign": "campaign_Campaign.getListCampaign",
    "campaign_Campaign.deleteCampaign": "campaign_Campaign.deleteCampaign",
    "campaign_referral.subscriber.createReferralUser":
      "campaign_referral.subscriber.createReferralUser",
    "campaign_referral.subscriber.getReferralUserList":
      "campaign_referral.subscriber.getReferralUserList",
    "campaign_referral.subscriber.getReferralUser":
      "campaign_referral.subscriber.getReferralUser",
    "campaign_referral.subscriber.deleteReferralUser":
      "campaign_referral.subscriber.deleteReferralUser",
    "campaign_referral.subscriber.updateReferralUser":
      "campaign_referral.subscriber.updateReferralUser",
    "campaign_referral.subscriberlog.createSubscriberLog":
      "campaign_referral.subscriberlog.createSubscriberLog",
    all: "campaign_all",
  },
  ipg: {
    "ipg_settings.getAll": "ipg_settings.getAll",
    "ipg_settings.update": "ipg_settings.update",
    "ipg_settings.getByKey": "ipg_settings.getByKey",
    "ipg_transaction.v1.get": "ipg_transaction.v1.get",
    "ipg_transaction.create": "ipg_transaction.create",
    "ipg_transaction.update": "ipg_transaction.update",
    "ipg_transaction.getAll": "ipg_transaction.getAll",
    "ipg_transaction.v1.delete": "ipg_transaction.v1.delete",
    "ipg_bank-gateway.create": "ipg_bank-gateway.create",
    "ipg_bank-gateway.update": "ipg_bank-gateway.update",
    "ipg_bank-gateway.getAll": "ipg_bank-gateway.getAll",
    "ipg_bank-gateway.getAllEnabled": "ipg_bank-gateway.getAllEnabled",
    "ipg_bank-gateway.get": "ipg_bank-gateway.get",
    "ipg_bank-gateway.delete": "ipg_bank-gateway.delete",
    all: "ipg_all",
  },
  faq: {
    "faq_category.get": "faq_category.get",
    "faq_category.update": "faq_category.update",
    "faq_category.delete": "faq_category.delete",
    "faq_category.nodes.get": "faq_category.nodes.get",
    "faq_category.create": "faq_category.create",
    "faq_category.getAll": "faq_category.getAll",
    "faq_queans.update": "faq_queans.update",
    "faq_queans.selectiveUpdate": "faq_queans.selectiveUpdate",
    "faq_queans.getAll": "faq_queans.getAll",
    "faq_queans.get": "faq_queans.get",
    "faq_queans.delete": "faq_queans.delete",
    "faq_queans.create": "faq_queans.create",
    "faq_queans.category.get": "faq_queans.category.get",
    all: "faq_all",
  },
  file: {
    file_createFolder: "file_createFolder",
    file_upload: "file_upload",
    file_get: "file_get",
    file_getRoot: "file_getRoot",
    file_getContent: "file_getContent",
    all: "file_all",
  },
  geo: {
    geo_getList: "geo_getList",
    geo_get: "geo_get",
    geo_update: "geo_update",
    geo_delete: "geo_delete",
    geo_create: "geo_create",
    all: "geo_all",
  },
  contents: {
    "contents_tags.getList": "contents_tags.getList",
    "contents_tags.get": "contents_tags.get",
    "contents_tags.update": "contents_tags.update",
    "contents_tags.delete": "contents_tags.delete",
    "contents_tags.create": "contents_tags.create",
    "contents_categories.list": "contents_categories.list",
    "contents_categories.delete": "contents_categories.delete",
    "contents_categories.get": "contents_categories.get",
    "contents_categories.add": "contents_categories.add",
    "contents_categories.edit": "contents_categories.edit",
    contents_add: "contents_add",
    contents_edit: "contents_edit",
    contents_delete: "contents_delete",
    contents_getContentList: "contents_getContentList",
    "contents_user-rate.add": "contents_user-rate.add",
    "contents_locations.getContentLocationsList":
      "contents_locations.getContentLocationsList",
    contents_get: "contents_get",
    all: "contents_all",
  },
  support: {
    "support_categories.getCategoryList": "support_categories.getCategoryList",
    "support_categories.createCategory": "support_categories.createCategory",
    "support_categories.updateCategory": "support_categories.updateCategory",
    "support_categories.deleteCategory": "support_categories.deleteCategory",
    "support_categories.getCategory": "support_categories.getCategory",
    "support_ticket-messages.updateTicketMessage":
      "support_ticket-messages.updateTicketMessage",
    "support_ticket-messages.ticket.getTicketMessageListByTicketId":
      "support_ticket-messages.ticket.getTicketMessageListByTicketId",
    "support_ticket-messages.getTicketMessage":
      "support_ticket-messages.getTicketMessage",
    "support_ticket-messages.deleteTicketMessage":
      "support_ticket-messages.deleteTicketMessage",
    "support_ticket-messages.createTicketMessage":
      "support_ticket-messages.createTicketMessage",
    "support_ticket.getRates": "support_ticket.getRates",
    "support_ticket.getRate": "support_ticket.getRate",
    "support_ticket.createRate": "support_ticket.createRate",
    "support_tickets.updateTicket": "support_tickets.updateTicket",
    "support_tickets.getListTicket": "support_tickets.getListTicket",
    "support_tickets.admin.getListTicket":
      "support_tickets.admin.getListTicket",
    "support_tickets.getTicket": "support_tickets.getTicket",
    "support_tickets.deleteTicket": "support_tickets.deleteTicket",
    "support_tickets.state.changeState": "support_tickets.state.changeState",
    "support_tickets.createTicket": "support_tickets.createTicket",
    "support_tickets.getSubscriberTickets":
      "support_tickets.getSubscriberTickets",
    all: "support_all",
  },
};
