import React, { useState } from "react";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import { failedPaymentV2 } from "../../reports.api";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import moment from "jalali-moment";
import { convertCentToDollar } from "../../../../app/util";
import ExtraInfo from "./components/extraInfo/ExtraInfo";

const FailedPayment = () => {
  const { t } = useTranslation();
  const [extraInfoPopup, setExtraInfoPopup] = useState(false);

  const columns = [
    {
      title: t("general::Stripe_referenceId"),
      dataIndex: "referenceId",
      key: "referenceId",
    },
    {
      title: t("general::mobile"),
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: t("general::email"),
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: t("general::mallName"),
    //   dataIndex: "mallName",
    //   key: "mallName",
    // },

    {
      title: t("general::status"),
      dataIndex: "paymentException",
      key: "paymentException",
    },
    {
      title: t("general::amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text) => {
        return convertCentToDollar(text);
      },
    },
    {
      title: t("general::paymentTime"),
      dataIndex: "paymentTime",
      key: "paymentTime",
      render: (text) => {
        try {
          return moment(text).format("YYYY/MM/DD");
        } catch {
          return text;
        }
      },
    },
    {
      title: t("general::extraInfo"),
      dataIndex: "usageDuration",
      key: "usageDuration",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              setExtraInfoPopup(record);
            }}
          >
            {t("general::viewInformation")}
          </Button>
        );
      },
    },
  ];

  return (
    <Container>
      <Row>
        <Col span={24}>
          <Crud
            get={{
              apiService: (params) => {
                return failedPaymentV2(params);
              },
              columns,
            }}
          />
        </Col>
        <ExtraInfo
          extraInfoPopup={extraInfoPopup}
          setExtraInfoPopup={setExtraInfoPopup}
        />
      </Row>
    </Container>
  );
};

export default FailedPayment;
