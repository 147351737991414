import Config from "../../../../app/config";
import { HttpService } from "../../../../app/apiService";

const getProfileAPI = "/api/v1/subscriber/profile";
const APIBalance = "v1/cuddle/pay/balance";

export const getBalance = (params) => {
  return HttpService.get(APIBalance);
};

export const getAdminInfo = async () => {
  let token = localStorage.getItem("access_token");
  await fetch(Config.baseUrl + getProfileAPI, {
    method: "get",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
};
