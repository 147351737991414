import React from "react";
import { Button, Table } from "antd";
import { FileTwoTone, FolderTwoTone } from "@ant-design/icons";
import moment from "jalali-moment";

const Files = ({ content, selectFolder, selectFile, getFileUrl }) => {
  const columns = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <>
            {record.type === "FOLDER" ? <FolderTwoTone /> : <FileTwoTone />}
            <span style={{ marginRight: 4 }}> {text ? text : "no-name"} </span>
          </>
        );
      },
    },
    {
      title: "Upload At",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
      render: (text) => {
        try {
          return moment(text).format("YYYY/MM/DD hh:mm");
        } catch {
          return text;
        }
      },
    },
    {
      title: "Opreations",
      render: (record) => {
        return (
          <>
            {record.type !== "FOLDER" && (
              <>
                <Button
                  style={{ marginLeft: 4 }}
                  onClick={() => {
                    getFileUrl(record.url, (url) => window.open(url));
                  }}
                >
                  {" "}
                  Download{" "}
                </Button>
                <Button
                  style={{ marginLeft: 4 }}
                  onClick={() => selectFile(record.url)}
                  type="primary"
                >
                  {" "}
                  Choose File{" "}
                </Button>
              </>
            )}
            {record.type === "FOLDER" && (
              <Button onClick={() => selectFolder([record.url])}>
                {" "}
                Choose{" "}
              </Button>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className="file-card">
      <Table
        columns={columns}
        dataSource={content}
        rowKey={"id"}
        pagination={content.length > 9}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              if (record.type === "FOLDER") {
                selectFolder([record.uuid]);
              }
            },
          };
        }}
      />
    </div>
  );
};

export default Files;
