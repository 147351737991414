import React, { useEffect, useState } from "react";
import { Button, Form, message, Modal, Select, Spin } from "antd";
import PropTypes from "prop-types";
import { errorMessageHandler } from "../../../../../../app/util";
import { useTranslation } from "react-i18next";
import { getQueAnsService, updatePriceService } from "../../history.api";
import { useSelector } from "react-redux";
import CustomInputJustFloatDigit from "../../../../../crud/components/CustomInputJustFloatDigit";

const { Option } = Select;

const ExtraInfo = (props) => {
  const { isOpen, setIsOpen, editPrice } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { configReducer } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [reasons, setReasons] = useState([]);

  useEffect(() => {
    getQueAns();
    // eslint-disable-next-line
  }, []);

  function getQueAns() {
    setIsLoading(true);
    getQueAnsService(configReducer.data.CHANGE_INVOICE_QUESTION_ID)
      .then((res) => {
        setReasons(res.data.answerList);
      })
      .catch((error) => {
        console.error("error2", error);
        message.error(errorMessageHandler(error), 3);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const onFinish = (values) => {
    setIsLoading(true);
    values.price = values.price * 100;

    updatePriceService(editPrice.rentId, {
      editInvoiceReason: values.reason,
      originalCost: values.price,
    })
      .then(() => {
        message.success(t("general::successEndRent"), 3);
        setIsOpen(false);
      })
      .catch((error) => {
        console.error("error", error);
        message.error(errorMessageHandler(error), 3);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      title={t("general::editPrice")}
      visible={isOpen !== false}
      onOk={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      cancelText={"Close"}
      okButtonProps={{ hidden: true }}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          className={"subscriber-extra-info"}
          onFinish={onFinish}
          initialValues={{
            reason: null,
            price: editPrice.price / 100,
          }}
        >
          <Form.Item
            label={"Price ($)"}
            name={"price"}
            rules={[
              {
                required: true,
                message: "Please select a valid price",
              },
            ]}
          >
            <CustomInputJustFloatDigit min={0} placeholder="Price ($)" />
          </Form.Item>
          <Form.Item
            label={"Reason"}
            name={"reason"}
            rules={[
              {
                required: true,
                message: "Please select a reason",
              },
            ]}
          >
            <Select disabled={isLoading} placeholder="Select a Reason">
              {reasons &&
                reasons.map((item) => {
                  return <Option value={item}>{item}</Option>;
                })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

ExtraInfo.propTypes = {
  extraInfo: PropTypes.any,
  setExtraInfoPopup: PropTypes.func,
};

export default ExtraInfo;
