import { HttpService } from "../../../../app/apiService";

export const API = "/v1/application/versions";

export const getVersionService = (params) => {
  params = {
    ...params,
    sortKey: "createdDate",
  };

  return HttpService.get(API, { params });
};

export const updateVersionService = (values) => {
  if (!values.id) {
    return;
  }
  let params = {
    applicationName: values.applicationName,
    versionName: values.versionName,
    packageName: values.packageName,
    versionCode: values.versionCode,
    link: values.link,
    applicationType: values.applicationType,
    updateMode: values.updateMode,
    metaData: values.metaData,
  };

  return HttpService.put(`${API}/${values.id}`, params);
};
