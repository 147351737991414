import {Avatar, Button, Comment, Modal, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import Container from "../../container/Container";
import {deleteMessage, getMessages, getSubscriber,} from "../pages/ticket/ticket.api";
import moment from "jalali-moment";
import "./ticketMessages.style.scss";
import {showDetailDate} from "../../../app/util";
import {useTranslation} from "react-i18next";

export default function StationMonitoringMessages() {
    const {t} = useTranslation();
    const params = useParams();
    const [apiData, setApiData] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [fetchDataFlag, setFetchDataFlag] = useState(false);
    let history = useHistory();


    const fetchMessages = (params) => {
        getMessages(params)
            .then((response) => {
                setApiData(response.data.content);
                let objDiv = document.getElementById("chat-list");
                objDiv.scrollTop = objDiv.scrollHeight;
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchMessages(params.ticketId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let idsModified = {};
        if (apiData && apiData.length > 0 && !fetchDataFlag) {
            setFetchDataFlag(true);
            apiData.forEach((item) => {
                getSubscriber(item.subscriberId)
                    .then((response) => {
                        idsModified[item.subscriberId] = response.data;
                        setUserInfo(idsModified);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiData]);

    //-----------------Delete message

    const [visible, setVisible] = useState(false);
    const [wantDeleteMessage, setWantDeleteMessage] = useState("");
    const onOk = () => {
        deleteMessage(wantDeleteMessage)
            .then((response) => {
                setVisible(false);
                setWantDeleteMessage("");
                fetchMessages(params.ticketId);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const onCancel = () => {
        setVisible(false);
        setWantDeleteMessage("");
    };

    return (
        <Container>
            <Button
                type="primary"
                style={{marginBottom: "1em"}}
                onClick={() => {
                    history.push({
                        pathname: "/administration/station-monitoring",
                    });
                }}
            >
                Back
            </Button>
            <div className="chat-box" id="chat-list">
                {apiData && apiData.length > 0 ? apiData.map((item, index) => <Comment
                    key={index}
                    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                    author={
                        <div>
                            <p>
                                <span>Name: </span>
                                <span>{userInfo[item.subscriberId]?.extraInfo?.fullName ? userInfo[item.subscriberId].extraInfo.fullName : (userInfo[item.subscriberId]?.extraInfo?.firstName || userInfo[item.subscriberId]?.extraInfo?.lastName) ? `${userInfo[item.subscriberId]?.extraInfo?.firstName} ${userInfo[item.subscriberId]?.extraInfo?.lastName}` : 'No Name'}</span>
                            </p>
                            <p>
                                <span>Email: </span>
                                <span>{userInfo[item.subscriberId]?.email || "No Email"}</span>
                            </p>
                            <p>
                                <span>Mobile: </span>
                                <span>{userInfo[item.subscriberId]?.mobile || "No Mobile"}</span>
                            </p>
                        </div>
                    }
                    avatar={
                        <Avatar
                            src={
                                userInfo[item.subscriberId]?.extraInfo?.avatarImageUrl || "/images/profile.png"
                            }
                            alt="Han Solo"
                        />
                    }
                    content={<div className="comment-content">
                        {item.content && <p>{item.content}</p>}
                        {item.fileUrl &&
                            <a href={item.fileUrl} className="comment-content__image-wrapper" target="_blank"
                               rel="noopener noreferrer"><img src={item.fileUrl} alt={item.content}/></a>}
                    </div>}
                    datetime={
                        <Tooltip title={moment().format("YYYY-MM-DD HH:mm:ss")}>
                            <span>{showDetailDate(item.createdDateMilli)}</span>
                        </Tooltip>
                    }
                />) : <div>{t("general::noMessageError")}</div>}
            </div>
            <Modal
                title={t("general::delete")}
                visible={visible}
                onOk={onOk}
                onCancel={onCancel}
                okText={t("general::confirm")}
                cancelText={t("general::cancel")}
            >
                {t("general::deleteQuestion")}
            </Modal>
        </Container>
    );
}
