import { HttpService } from "../../../../app/apiService";

export const API = "/v1/campaign/campaigns";

export const getRoles = (params = { pageNumber: 0, pageSize: 10 }) => {
  if (params) {
    params = {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
    };
  }
  return HttpService.get(`${API}`, {
    params,
  });
};

export const updateDiscountService = (values) => {
  return HttpService.put(`${API}/${values.id}`, { ...values });
};
