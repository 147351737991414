import Dashboard from "./Dashboard";
import React from "react";
import { DashboardOutlined, ProfileOutlined } from "@ant-design/icons";
import Profile from "./pages/profile/Profile";
import { APP_Permissions } from "../userManagement/components/privileges/privileges.constant";

export const DashboardPathNames = {
  dashboard: "/",
  profile: "/profile",
};

export const DashboardRoutes = [
  {
    title: "dashboard",
    path: DashboardPathNames.dashboard,
    component: Dashboard,
    exact: true,
    icon: <DashboardOutlined />,
    children: [
      {
        title: "dashboard",
        path: DashboardPathNames.dashboard,
        component: Dashboard,
        exact: true,
        icon: <DashboardOutlined />,
        permissions: [
          APP_Permissions["reports"]["cuddle_rents.reports.statistical"],
        ],
      },
      {
        title: "profile",
        path: DashboardPathNames.profile,
        component: Profile,
        exact: true,
        icon: <ProfileOutlined />,
      },
    ],
  },
];
