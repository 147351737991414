import { HttpService } from "../../../../app/apiService";

export const API = "v1/support/tickets/filter";

export const getRedFlags = (data) => {
  let filter;
  if (data.from && data.to) {
    filter = {
      from: data.from.format("x"),
      to: data.to.format("x"),
    };
    delete data.from;
    delete data.to;
  }
  let params = {
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    sort: "DESC",
    sortKey: "createdDate",
    ticketStateEnum: data.state,
    categoryId: '65ddcb01a1dae917a67cb971',
    ...filter,
    ...data,
  };

  return HttpService.get(API, { params });
};

export const getMessages = (id, params = { pageNumber: 0, pageSize: 20 }) => {
  return HttpService.get(`v1/support/ticket-messages/ticket/${id}`, { params });
};
export const getSubscriber = (id) => {
  return HttpService.get(`v1/subscriber/${id}`, {});
};
export const addMessage = (data) => {
  return HttpService.post(`v1/support/ticket-messages`, { ...data });
};
export const deleteMessage = (id) => {
  return HttpService.delete(`v1/support/ticket-messages/${id}`);
};
export const changeTicketState = (id, state) => {
  return HttpService.put(`v1/support/tickets/state/${id}/${state}`);
};

export const getCategoriesService = () => {
  return HttpService.get(`/v1/support/categories`);
};
