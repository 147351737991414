import React, { useState } from "react";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import { getSubscribers, updateSubscriberService } from "./subscribers.api";
import moment from "jalali-moment";
import "./subscriber.style.scss";
import { Button } from "antd";
import ExtraInfo from "./components/extraInfo/ExtraInfo";
import ChangePassword from "./components/changePassword/ChangePassword";
import { useTranslation } from "react-i18next";
import { getCookieLanguages, showDetailDate } from "../../../../app/util";
import UpdaterSubScribers from "./components/updaterSubScribers/UpdaterSubScribers";
//const { confirm } = Modal;

const Subscriber = (props) => {
  const [extraInfoPopup, setExtraInfoPopup] = useState(false);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  // eslint-disable-next-line
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const { t } = useTranslation();

  const columns = [
    {
      title: t("general::email"),
      dataIndex: "email",
      key: "email",
      width: "20%",
      searchable: true,
      render: (email) => {
        if (email === null) return "-";
        else {
          return email;
        }
      },
    },
    {
      title: t("general::mobile"),
      dataIndex: "mobile",
      key: "mobile",
      width: "20%",
      searchable: true,
      render: (mobile) => {
        if (mobile === null) return "-";
        else {
          return mobile;
        }
      },
    },
    {
      title: t("general::registerDate"),
      dataIndex: "createdDateMilli",
      key: "createdDateMilli",
      width: "20%",
      render: (date) => {
        return showDetailDate(date);
      },
    },
    {
      title: t("userManagement::extraInfo"),
      dataIndex: "extraInfo",
      key: "extraInfo",
      width: "20%",
      render: (record) => {
        return (
          <Button type="primary" onClick={() => setExtraInfoPopup(record)}>
            {t("general::viewInformation")}
          </Button>
        );
      },
    },
  ];

  function onFinish(values) {
    // delete values.confirmPassword;
    // values.extraInfo.avatarImageUrl = selectedAvatar;
    // if (values.extraInfo.birthDay) {
    //   let toGeorgian = moment
    //     .from(values.extraInfo.birthDay, "en", "YYYY/MM/DD")
    //     .locale("en")
    //     .format("YYYY/MM/DD");

    //   values.extraInfo.birthDay = moment(toGeorgian).format("x");
    //   return { ...values };
    // }
    return values;
  }

  // function showChangeLockStatus(record, fetch) {
  //   confirm({
  //     title: t("general::lockConfirmMessage"),
  //     content: t("general::areYouSureToChangeLockStatus"),
  //     okText: t("general::confirm"),
  //     okType: "primary",
  //     cancelText: t("general::close"),
  //     style: { direction: "rtl" },

  //     onOk() {
  //       if (record.locked !== false) {
  //         return unlockSubscriber(record.id)
  //           .then((response) => {
  //             message.success(t("userManagement::accountUnlocked"));
  //             fetch();
  //           })
  //           .catch((error) =>
  //             message.error(t("general::errorConnectingToTheServer"))
  //           );
  //       } else {
  //         return lockSubscriber(record.id)
  //           .then((response) => {
  //             message.success(t("userManagement::accountLocked"));
  //             fetch();
  //           })
  //           .catch((error) =>
  //             message.error(t("general::errorConnectingToTheServer"))
  //           );
  //       }
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // }

  // const updateSubscriber = (values) => {
  //   return updateSubscriberService(values)
  // }

  return (
    <Container classNames="contents">
      <Crud
        get={{
          apiService: getSubscribers,
          columns,
        }}
        update={{
          modal: {
            title: t("Update"),
          },
          apiService: updateSubscriberService,
          onInitial: (values) => {
            //console.log("values",values)
            if (values && values.extraInfo && values.extraInfo.birthDay) {
              if (getCookieLanguages() === "en") {
                values.extraInfo.birthDay = moment(
                  values.extraInfo.birthDay
                ).format("YYYY/MM/DD");
              } else {
                values.extraInfo.birthDay = moment(
                  values.extraInfo.birthDay
                ).format("YYYY/MM/DD");
              }
            }
            if (values && values.extraInfo && values.extraInfo.avatarImageUrl) {
              setSelectedAvatar(values.extraInfo.avatarImageUrl);
            }
            return values;
          },
          formProps: {
            onFinish,
          },
        }}
        // remove={{
        //   api: API,
        // }}
      >
        <UpdaterSubScribers />
      </Crud>

      <ExtraInfo
        extraInfoPopup={extraInfoPopup}
        setExtraInfoPopup={setExtraInfoPopup}
      />
      <ChangePassword
        showPasswordPopup={showPasswordPopup}
        setShowPasswordPopup={setShowPasswordPopup}
        onFinish={onFinish}
      />
    </Container>
  );
};

export default Subscriber;
