import React from "react";
import "../../subscriber.style.scss";
import { Descriptions, Modal } from "antd";

import PropTypes from "prop-types";

const ExtraInfo = (props) => {
  const { extraInfoPopup, setExtraInfoPopup } = props;

  // eslint-disable-next-line

  return (
    <Modal
      title={"User Information"}
      visible={extraInfoPopup !== false}
      onOk={() => setExtraInfoPopup(false)}
      onCancel={() => setExtraInfoPopup(false)}
      cancelText={"Close"}
      okButtonProps={{ hidden: true }}
    >
      <Descriptions className={"subscriber-extra-info"}>
        <Descriptions.Item span={24} label={"firstName"}>
          {!extraInfoPopup ? "-" : extraInfoPopup.firstName}
        </Descriptions.Item>

        <Descriptions.Item span={24} label={"lastName"}>
          {!extraInfoPopup ? "-" : extraInfoPopup.lastName}
        </Descriptions.Item>
        <Descriptions.Item span={24} label={"fullName"}>
          {!extraInfoPopup ? "-" : extraInfoPopup.fullName}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

ExtraInfo.propTypes = {
  extraInfo: PropTypes.any,
  setExtraInfoPopup: PropTypes.func,
};

export default ExtraInfo;
