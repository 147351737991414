import { HttpService } from "../../../../app/apiService";

export const API = "/v1/rates/opinions";

export const getOpinionRateService = (params) => {
  return HttpService.get(API, { params });
};

export const updateOpinionService = (params) => {
  let rateValueEnum = params.rateValueEnum;
  return HttpService.put(`${API}/${rateValueEnum}`, params);
};
