import React from "react";
import { Button } from "antd";
import {
  FileTwoTone,
  PictureTwoTone,
  SoundTwoTone,
  VideoCameraTwoTone,
} from "@ant-design/icons";

const ButtonTextHolder = ({ text, icon }) => {
  return (
    <>
      {text && <span> {text} </span>}
      {icon && icon}
    </>
  );
};

const ButtonText = ({ accept, editMode }) => {
  if (accept === "image/*") {
    return <ButtonTextHolder text={"Upload Image"} icon={<PictureTwoTone />} />;
  } else if (accept === "video/*") {
    return (
      <ButtonTextHolder text={"Upload Video"} icon={<VideoCameraTwoTone />} />
    );
  } else if (accept === "audio/*") {
    return <ButtonTextHolder text={"Upload Voice"} icon={<SoundTwoTone />} />;
  } else {
    return (
      <ButtonTextHolder
        text={`${editMode ? "Edit" : "Upload"} File`}
        icon={<FileTwoTone />}
      />
    );
  }
};

const ButtonFileManager = ({ accept, value, ...props }) => {
  return (
    <Button {...props} type="primary">
      <ButtonText accept={accept} editMode={!!value} />
    </Button>
  );
};

export default ButtonFileManager;
