import React from "react";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import { useTranslation } from "react-i18next";
import {
  convertCentToDollar,
  convertSecondsToDhms,
} from "../../../../app/util";
import { getStrollerService } from "../../reports.api";
import CustomSelectMall from "../../../customFilters/CustomSelectMall";

const Carts = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("financial::strollerID"),
      dataIndex: "strollerAdditionalCode",
      key: "strollerAdditionalCode",
      searchable: true,
    },
    {
      title: t("financial::operator"),
      dataIndex: "mallName",
      key: "mallIds",
      customFilter: CustomSelectMall,
    },
    {
      title: t("financial::totalPrice"),
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (totalPrice) => {
        return convertCentToDollar(totalPrice);
      },
    },
    {
      title: t("financial::totalRent"),
      dataIndex: "totalRent",
      key: "totalRent",
      filter: "fromTo",
    },
    {
      title: t("financial::duration") + " (DD:HH:MM:SS)",
      dataIndex: "usageDuration",
      key: "usageDuration",
      render: (usageDuration) => {
        if (!usageDuration) return "-";
        usageDuration = usageDuration / 1000;
        return convertSecondsToDhms(usageDuration);
      },
    },
  ];

  return (
    <Container>
      <Crud
        get={{
          apiService: (params) => getStrollerService(params),
          columns,
        }}
      />
    </Container>
  );
};

export default Carts;
