import { HttpService } from "../../../../app/apiService";

export const API = "v1/contents/categories";

export const createCategory = (data, type) => {
  data.type = type;
  return HttpService.post(`${API}`, data);
};

export const updateCategory = (data, type) => {
  const id = data.id;
  data.type = type;
  delete data.id;
  return HttpService.put(`${API}/${id}`, data);
};

export const deleteCategoryService = (id) => {
  return HttpService.delete(`${API}/${id}`);
};
