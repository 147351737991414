import React from "react";
import { Input, Modal } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./rateInfo.style.scss";

const RateInfo = (props) => {
  const { rateInfoPopup, setRateInfoPopup } = props;
  const { t } = useTranslation();

  return (
    <Modal
      title={t("general::rate")}
      visible={rateInfoPopup !== false}
      onOk={() => setRateInfoPopup(false)}
      onCancel={() => setRateInfoPopup(false)}
      cancelText={t("general::close")}
      okButtonProps={{ hidden: true }}
    >
      <div className="rate-info">
        <div className="rate-count">{`${t("general::rate")}: ${
          rateInfoPopup.rating
        }`}</div>
        <label>{`${t("general::comment")}:`}</label>
        <Input value={rateInfoPopup.comment} readOnly />
      </div>
    </Modal>
  );
};

RateInfo.propTypes = {
  rateInfoPopup: PropTypes.any,
  setRateInfoPopup: PropTypes.func,
};

export default RateInfo;
