import React, { useState } from "react";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import {
  API,
  getVersionService,
  updateVersionService,
} from "./ForceUpdate.api";
import FormComponent from "./components/FormComponent/FormComponents";
import { useTranslation } from "react-i18next";
import { Button, Image } from "antd";
import ExtraInfo from "./components/extraInfo/ExtraInfo";
import plus from "../../../../assets/plus.svg";
import { showDetailDate } from "../../../../app/util";

const ForceUpdate = () => {
  const [extraInfoPopup, setExtraInfoPopup] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("forceUpdate::applicationName"),
      dataIndex: "applicationName",
      key: "applicationName",
    },
    {
      title: t("forceUpdate::packageName"),
      dataIndex: "packageName",
      key: "packageName",
    },
    {
      title: t("forceUpdate::versionName"),
      dataIndex: "versionName",
      key: "versionName",
    },

    {
      title: t("forceUpdate::applicationType"),
      dataIndex: "applicationType",
      key: "applicationType",
    },

    {
      title: t("forceUpdate::updateMode"),
      dataIndex: "updateMode",
      key: "updateMode",
    },
    {
      title: t("general::createdDateMilli"),
      dataIndex: "createdDateMilli",
      key: "createdDateMilli",
      sorter: true,
      render: (state) => {
        return showDetailDate(state);
      },
    },
    {
      title: t("general::extraInfo"),
      dataIndex: "metaData",
      key: "metaData",
      render: (text, record) => {
        return (
          <Button type="primary" onClick={() => setExtraInfoPopup(record)}>
            {t("general::viewInformation")}
          </Button>
        );
      },
    },
  ];

  return (
    <Container>
      <Crud
        create={{
          api: API,
          modal: {
            title: t("forceUpdate::createVersion"),
          },
          button: {
            title: t("forceUpdate::createVersion"),
            icon: (
              <Image src={plus} preview={false} className="icon-add-items" />
            ),
          },
        }}
        get={{
          apiService: (p) => getVersionService(p),
          columns,
        }}
        update={{
          apiService: updateVersionService,
          onInitial: (values) => {
            setSelectedRows(values);
            return values;
          },
          modal: {
            title: t("general::update"),
          },
        }}
        remove={{
          api: API,
        }}
      >
        <FormComponent
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </Crud>
      <ExtraInfo
        extraInfoPopup={extraInfoPopup}
        setExtraInfoPopup={setExtraInfoPopup}
      />
    </Container>
  );
};

export default ForceUpdate;
