import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { getActivePath } from "./components/getActivePath";
import { useTranslation } from "react-i18next";
import { haveAccess } from "../../../userManagement/components/privileges/privileges.utils";
import { getCategories } from "../../../contentComponent/content.api";

const RenderMenuItems = ({ routes }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const [selectedKeys, setSelectedKeys] = useState("");
  const [openKeys, setOpenKeys] = useState([]);
  const [categories, setCategories] = useState(
    sessionStorage.getItem("sidebar_categories")
      ? JSON.parse(sessionStorage.getItem("sidebar_categories"))
      : []
  );

  useEffect(() => {
    setActivePath();
    fetchCategories();
    // eslint-disable-next-line
  }, []);

  function setActivePath() {
    let activePath = getActivePath(routes, location);
    if (
      activePath &&
      activePath.currentMenu &&
      activePath.currentMenu.id &&
      activePath.currentMenu.id !== openKeys
    ) {
      if (activePath.hasChildren) {
        setOpenKeys([activePath.item.id.toString()]);
      } else {
        setOpenKeys([]);
      }

      setSelectedKeys(activePath.currentMenu.id);
    }
  }

  function fetchCategories() {
    getCategories({ pageSize: 20 }).then((r) => {
      if (r.data && r.data.content && r.data.content.length > 0) {
        sessionStorage.setItem(
          "sidebar_categories",
          JSON.stringify(r.data.content)
        );
        setCategories(r.data.content);
      }
    });
  }

  return (
    <Menu
      theme="light"
      mode="inline"
      openKeys={openKeys}
      onOpenChange={(keys) => setOpenKeys(keys)}
      defaultSelectedKeys={["2"]}
      defaultOpenKeys={[""]}
      onSelect={({ key }) => {
        setSelectedKeys(key);
      }}
      selectedKeys={selectedKeys.toString()}
    >
      {routes &&
        routes.map((item, index) => {
          let {
            titleFa,
            title,
            icon,
            path,
            showInSideBar,
            id,
            children,
            sidebarPathParams,
          } = item;

          if (sidebarPathParams && typeof sidebarPathParams === "object") {
            for (let key in sidebarPathParams) {
              if (sidebarPathParams.hasOwnProperty(key)) {
                let val = sidebarPathParams[key];
                path = path.replace(`:${key}`, val);
              }
            }
          }

          let access = false;
          if (item.children && item.children.length > 0) {
            item.children.forEach((child) => {
              if (access === false && haveAccess(child.permissions)) {
                access = true;
              }
            });
          } else {
            access = true;
          }

          if (access && item.permissions && item.permissions.length > 0) {
            access = haveAccess(item.permissions);
          }

          if (
            (typeof showInSideBar !== "undefined" && showInSideBar === false) ||
            !access
          ) {
            return <span key={`sidebar-${index}`} />;
          }

          if (children) {
            return (
              <Menu.SubMenu
                key={`${id}`}
                icon={icon}
                title={title ? t(`general::${title}`) : titleFa}
              >
                {children.map((child, index) => {
                  let access = child.permissions
                    ? haveAccess(child.permissions)
                    : true;

                  if (
                    (typeof child.showInSideBar !== "undefined" &&
                      child.showInSideBar === false) ||
                    !access
                  ) {
                    return <span key={`sidebar-children-${index}`} />;
                  }

                  if (
                    child &&
                    child.sidebarPathParams &&
                    typeof child.sidebarPathParams === "object"
                  ) {
                    for (let key in child.sidebarPathParams) {
                      if (child.sidebarPathParams.hasOwnProperty(key)) {
                        let val = child.sidebarPathParams[key];
                        child.path = child.path.replace(`:${key}`, val);
                      }
                    }
                  }

                  if (child.hasCategory) {
                    return (
                      categories &&
                      categories.map((catItem) => (
                        <Menu.Item
                          key={`${catItem.id}-category-${child.id}`}
                          icon={child.icon}
                        >
                          <Link
                            to={`/operations/mall-management/${catItem.id}`}
                          >
                            {catItem.title}
                          </Link>
                        </Menu.Item>
                      ))
                    );
                  }

                  return (
                    <Menu.Item key={child.id} icon={child.icon}>
                      <Link to={child.path}>
                        {child.title
                          ? t(`general::${child.title}`)
                          : child.titleFa}
                      </Link>
                    </Menu.Item>
                  );
                })}
              </Menu.SubMenu>
            );
          }

          return (
            <Menu.Item key={`${id}`} icon={icon}>
              <Link to={path}>{title ? t(`general::${title}`) : titleFa}</Link>
            </Menu.Item>
          );
        })}
    </Menu>
  );
};

export default RenderMenuItems;
