import { HttpService } from "../../../../app/apiService";
import { message } from "antd";

export const API = "/v1/contents";

export const getStationService = (data) => {
  if (!data) {
    message.error("id not found!", 3);
    return false;
  }
  let params = {
    sort: "DESC",
    byChildren: true,
    pageSize: 10,
    pageNumber: 0,
    type: "Station",
  };

  return HttpService.get(`${API}/${data}`, { params });
};

export const createStationService = (params) => {
  params = {
    type: "stations",
    ...params,
  };
  return HttpService.post(API, params);
};

export const updateStationService = (params) => {
  if (!params || (params && !params.id)) {
    message.error("id not found!", 3);
    return false;
  }
  params = {
    type: "stations",
    ...params,
  };

  return HttpService.put(`${API}/${params.id}`, params);
};

export const deleteStationService = (id) => {
  return HttpService.delete(`${API}/${id}`);
};
