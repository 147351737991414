import React from "react";
import { API, updateDiscountService } from "./referralCode.api";
import { useTranslation } from "react-i18next";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import UpdaterReferral from "./components/updaterReferral/UpdaterReferral";

const ReferralCode = () => {
  const { t } = useTranslation();
  const onFinish = (value) => {
    value.ownerChargeValue = parseInt(value.ownerChargeValue);
    value.targetChargeValue = parseInt(value.targetChargeValue);
    return value;
  };
  const columns = [
    {
      title: t("general::userId"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("general::name"),
      dataIndex: "name",
      key: "name",
    },

    {
      title: t("referral::owner"),
      dataIndex: "inviterAction",
      key: "inviterAction",
      render: (text, record) => {
        const { invitedAction } = record;
        return invitedAction && invitedAction.value ? invitedAction.value : "-";
      },
    },
    {
      title: t("referral::target"),
      dataIndex: "inviterAction",
      key: "inviterAction",
      render: (text, record) => {
        const { inviterAction } = record;
        return inviterAction && inviterAction.value ? inviterAction.value : "-";
      },
    },
  ];

  return (
    <Container classNames="contents">
      <Crud
        get={{
          columns,
          api: API,
        }}
        update={{
          apiService: updateDiscountService,
          onInitial: (values) => {
            return values;
          },
          modal: {
            title: t("general::update"),
          },
          formProps: {
            onFinish,
          },
        }}
      >
        <UpdaterReferral />
      </Crud>
    </Container>
  );
};

export default ReferralCode;
