import { HttpService } from "../../../../app/apiService";

export const API = "/v1/subscriber";

let pagination = {
  locked: false,
  pageNumber: 0,
  pageSize: 10,
  sort: "DESC",
};

export const getSystemUsers = (params, role) => {
  params = { ...pagination, ...params, role: role };
  return HttpService.get(`${API}`, { params });
};

export const getUserSessions = (
  id,
  params = {
    pageNumber: 1,
    pageSize: 10,
    subscriberId: id,
  }
) => {
  return HttpService.get(`/v1/session/all/`, { params });
};

export const getPivotTypesService = () => {
  return HttpService.get("/v1/cuddle/pivotal-permission/values");
};
export const updateSubscriberService = (data) => {
  return HttpService.patch(`${API}`, data);
};

export const updatePivotService = (id, data) => {
  return HttpService.put(`v1/security-server/pivotal-permission/${id}`, data);
};

export const addPivotService = (data) => {
  return HttpService.post(`v1/security-server/pivotal-permission`, data);
};

export const getPivotalService = (id) => {
  return HttpService.get(`v1/security-server/pivotal-permission/${id}`);
};

export const getPivotService = () => {
  return HttpService.get("v1/security-server/pivotal-permission");
};

export const getMallServices = () => {
  return HttpService.get("/v1/cuddle/malls/admin");
};
