import React, { useEffect, useState } from "react";
import VerticalAlignTopOutlined from "@ant-design/icons/lib/icons/VerticalAlignTopOutlined";
import { useSelector } from "react-redux";

const UploadFile = ({
  accept,
  folderId,
  simpleMode = false,
  handleUploadFile,
}) => {
  const [file, setFile] = useState(null);
  const { configReducer } = useSelector((state) => state);

  useEffect(() => {
    if (file) {
      uploadFile();
    }
    // eslint-disable-next-line
  }, [file]);

  function uploadFile() {
    handleUploadFile(file, configReducer.data.FOLDER_ID);
  }

  return (
    <label className={simpleMode ? "ant-btn" : "back-btn"}>
      {!simpleMode && <VerticalAlignTopOutlined />}
      {simpleMode ? <p>Choose File</p> : <p>Upload File</p>}
      <input
        type="file"
        accept={accept}
        style={{ display: "none" }}
        onChange={({ target: { files } }) => setFile(files[0])}
      />
    </label>
  );
};

export default UploadFile;
