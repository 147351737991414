import React, { useEffect, useState } from "react";
import { Button, Form, message, Modal, Select } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  addPivotService,
  getMallServices,
  updatePivotService,
} from "../../users.api";
import { checkValue } from "../../../../../../app/util";

const { Option } = Select;

const PivotModal = (props) => {
  const { isOpen, setIsOpen, user, typePtivot, setLoadingUser } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [malls, setMalls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pivotalPermission, setPivotalPermission] = useState({});
  const pivotArry = user.pivotalPermissionList;
  const pivotArryShift = [...pivotArry].shift();
  const pivotIdSet = checkValue(pivotArryShift)
    ? pivotArryShift.pivotIdSet
    : [];

  useEffect(() => {
    getPivot();

    // eslint-disable-next-line
  }, [pivotalPermission]);

  function getPivot() {
    getMallServices()
      .then((res) => {
        setMalls(res.data.content);
      })
      .catch((err) => {
        message.error(t("general::errorConnectingToTheServer"), 3);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const onFinish = (values) => {
    setLoadingUser(true);
    let params = {
      id: pivotArryShift ? pivotArryShift.id : user.id,
      pivotIdSet: values.mall,
      type: typePtivot,
      username: user.username,
    };
    if (pivotArryShift) {
      updatePivotService(pivotArryShift.id, params).then((res) => {
        message.success("data add successfully", 5);
        setIsOpen(false);
        setPivotalPermission(res.data);
        setLoadingUser(false);
      });
    } else {
      addPivotService(params).then((res) => {
        message.success("data add successfully", 5);
        setIsOpen(false);
        setPivotalPermission(res.data);
        setLoadingUser(false);
      });
    }
  };

  return (
    <Modal
      title={t("general::pivot")}
      visible={isOpen}
      onCancel={() => setIsOpen(false)}
      cancelText={"Close"}
      loading={loading}
      footer={null}
    >
      <Form form={form} className={"subscriber-extra-info"} onFinish={onFinish}>
        <Form.Item
          label={"Operator"}
          name={"mall"}
          rules={[
            {
              required: true,
              message: "Please select a mall",
            },
          ]}
        >
          <Select
            mode="tags"
            placeholder="Enter Name"
            defaultValue={pivotIdSet}
          >
            {malls.map((item) => {
              let data = item.title.fields;
              return (
                <Option value={item.id} key={item.id}>
                  {[...data].shift()}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PivotModal;

PivotModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  user: PropTypes.any,
  typePtivot: PropTypes.any,
};
