export const RolesReduxTypes = {
  FETCH_ROLES: "FETCH_ROLES",
  FETCH_ROLES_SUCCESS: "FETCH_ROLES_SUCCESS",
  FETCH_ROLES_FAILURE: "FETCH_ROLES_FAILURE",
};

export const UnUpdatedRoles = [
  "ROLE_USER",
  "ROLE_BRANCH",
  "ROLE_STRIPE_CONNECT",
];
