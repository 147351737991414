import { HttpService } from "../../../../app/apiService";

export const API = "v1/subscriber";

export const getSubscribersAPI = "v1/cuddle/subscriber";

let pagination = {
  pageNumber: 0,
  pageSize: 10,
  sort: "DESC",
  sortKey: "createdDate",
};

export const getSubscribers = (params) => {
  params = { ...pagination, ...params };
  return HttpService.get(`${getSubscribersAPI}`, {
    params,
  });
};

export const updateSubscriberService = (data) => {
  return HttpService.patch(`${API}`, data);
};

export const getUserInfo = (id) => {
  return HttpService.get(`${API}/${id}`);
};

export const changeSubscriberPassword = (data) => {
  return HttpService.patch(`${API}/password`, data);
};

export const lockSubscriber = (id) => {
  return HttpService.patch(`${API}/lock?subscriberId=${id}`);
};

export const unlockSubscriber = (id) => {
  return HttpService.patch(`${API}/unlock?userId=${id}`);
};
