import { Input } from "antd";
import React, { useEffect, useState } from "react";

const SearchFilter = (props) => {
  const { setFilter, item, title, style, reset, setReset } = props;
  const [value, setValue] = useState("");

  useEffect(
    () => {
      setFilter((prevData) => ({
        ...prevData,
        [item]: value,
      }));
    },
    // eslint-disable-next-line
    [value]
  );

  useEffect(
    () => {
      setValue();
      setReset(false);
    },
    // eslint-disable-next-line
    [reset]
  );

  const handlerChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div style={style} className="search-filter">
      <Input
        allowClear
        onChange={handlerChange}
        placeholder={`Enter ${title}`}
        value={value}
        defaultValue={""}
        className="search-filter"
      />
    </div>
  );
};

export default SearchFilter;
