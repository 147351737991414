import React from "react";
import { Layout, Spin } from "antd";
import PropTypes from "prop-types";
//import Bread from "../breadcrumb/Breadcrumb";
//import {adminRoutes} from "../../../../app/router";
import "../../container.style.scss";

const { Content } = Layout;

const PageContainer = ({
  children,
  loading = false,
  classNames = "",
  ...props
}) => {
  return (
    <Layout {...props} id="page-container">
      <Content>
        <Spin spinning={loading}>
          <div className={"page-container-content " + classNames}>
            {/* <Bread routeList={adminRoutes} /> */}
            <div className={"page-container-child"}>{children && children}</div>
          </div>
        </Spin>
      </Content>
    </Layout>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  classNames: PropTypes.string,
  props: PropTypes.object,
};

export default PageContainer;
