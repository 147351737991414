import React, { useEffect, useState } from "react";
import { Form, message, Modal } from "antd";
import DropOption from "./components/DropOption";
import { convertArrayToTitleObject, Log, logType } from "../../app/util";
import Create from "./components/Create";
import "./crud.style.scss";
import { deleteService, getService } from "./crud.api";
import Read from "./components/Read";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Crud = (props) => {
  const { t } = useTranslation();
  const {
    create,
    get,
    remove,
    update,
    otherAction = [],
    otherActionCustom,
    otherButtons,
    loading: loadingProps = false,
    showTotalItems = false,
    fromToInitialValue
  } = props;
  const {
    apiService,
    api,
    responseProperty,
    responseIsArray = false,
    tableProps,
    columns,
    dataSource,
    fetchDataFunc,
    fetchDataDependency = [],
    pagination = {
      sort: "ASC",
      total: 0,
      current: 1,
      pageSize: 10,
    },
    defaultSort = {
      order: "",
      key: "",
    },
  } = get;
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [preColumn, setPreColumn] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [filter, setFilter] = useState({});
  const [isFilter, setIsFilter] = useState(false);

  const [paginationState, setPaginationState] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
    ...pagination,
    filters: fromToInitialValue ? {...filter, ...fromToInitialValue} : filter,
    sort: defaultSort.order || "",
    sortKey: defaultSort.key || "",
  });
  const [lastPagination, setLastPagination] = useState({});
  const [lastFetchDependency, setLastFetchDependency] = useState([]);

  useEffect(() => {
    handleSetOtherAction();
    // eslint-disable-next-line
  }, []);



  useEffect(() => {
    if (columns && columns.length > 0) {
      let indexColumn = [
        {
          title: "no.",
          dataIndex: "index",
          key: "index",
          width: "5%",
          render: (text, record, index) => {
            return (
              index +
              1 +
              paginationState.pageSize * (paginationState.current - 1)
            );
          },
        },
      ];
      let columnsParse = [...columns];

      columns.forEach((item, index) => {
        if (item.searchable) {
          setIsFilter(true);
          columnsParse[index] = {
            ...item,
          };
        }
        if ((item.filter && item.filter === "fromTo") || item.customFilter) {
          setIsFilter(true);
          columnsParse[index] = {
            ...item,
          };
        }
      });

      setTableColumn([...indexColumn, ...columnsParse, ...preColumn]);
    }
    // eslint-disable-next-line
  }, [columns, preColumn, paginationState.current]);

  useEffect(() => {
    if (!columns || (columns && columns.length === 0)) {
      if (apiData && apiData.length > 0) {
        generateColumn(apiData);
      } else if (dataSource) {
        generateColumn(dataSource);
      }
    }

    // eslint-disable-next-line
  }, [apiData, dataSource]);

  useEffect(() => {
    if (
      JSON.stringify(lastPagination) !== JSON.stringify(paginationState) ||
      JSON.stringify(lastFetchDependency) !==
        JSON.stringify(fetchDataDependency)
    ) {
      if (!dataSource && (api || getService)) {
        fetchData();
      }
    }
    // eslint-disable-next-line
  }, [
    paginationState.current,
    paginationState.filters,
    paginationState.pageSize,
    paginationState.sort,
    paginationState.sortKey,
    paginationState.total,
    // eslint-disable-next-line
    ...fetchDataDependency,
  ]);

  function handleSetOtherAction() {
    const defaultActions = [];
    let updateInOtherAction = false;
    let removeInOtherAction = false;
    if (otherAction && otherAction.length > 0) {
      otherAction.forEach((i) => {
        if (i.name === "edit" || i.name === "update") {
          updateInOtherAction = true;
        }
        if (i.name === "delete" || i.name === "remove") {
          removeInOtherAction = true;
        }
      });
    }

    if (update && (update.api || update.apiService) && !updateInOtherAction) {
      defaultActions.push({
        name: "Update",
        func: (record) => {
          let result = { ...record };
          if (update.onInitial) {
            result = update.onInitial(result);
          }

          setShowUpdateModal(result);
        },
      });
    }
    if (remove && (remove.api || remove.apiService) && !removeInOtherAction) {
      defaultActions.push({
        name: t("delete"),
        func: (record) => {
          if (!record.hasOwnProperty("id")) {
            message.error(t("general::idForDeleteNotDefined"), 3);
            return false;
          }

          Modal.confirm({
            title: t("general::deleteQuestion"),
            onOk() {
              setLoading(true);
              if (remove.api) {
                deleteService(`${remove.api}/${record.id}`)
                  .then((resp) => deleteThen(resp))
                  .catch((error) => deleteCatch(error));
              } else {
                remove
                  .apiService(record.id)
                  .then((resp) => deleteThen(resp))
                  .catch((error) => deleteCatch(error));
              }
            },
            okText: t("general::accept"),
            cancelText: t("general::cancel"),
          });
        },
      });
    }
    let actions = [...otherAction, ...defaultActions];
    if (actions.length > 0) {
      actions.map((item, index) => (item.key = `${index + 1}`));

      setPreColumn([
        ...preColumn,
        {
          title: "Operations",
          dataIndex: "",
          key: "x",
          render: (text, record) => {
            return (
              <DropOption
                menuOptions={actions}
                record={record}
                otherActionCustom={otherActionCustom}
                fetchData={fetchData}
              />
            );
          },
        },
      ]);
    }
  }

  function generateColumn(obj) {
    if (!obj || (obj && obj.length === 0)) {
      Log("generateColumn must have data!", logType.warn);
      return false;
    }
    let result = [];
    let sampleObject = obj[0];
    Object.keys(sampleObject).forEach((item) => {
      result.push({
        title: item,
        dataIndex: item,
        key: item,
        render: (text) => {
          return (
            <span>
              {typeof text === "object" ? JSON.stringify(text) : text}
            </span>
          );
        },
      });
    });
    setTableColumn([...result, ...preColumn]);
  }

  const fetchData = () => {
    if (loading) {
      return false;
    }

    setLastPagination(paginationState);
    setLastFetchDependency(fetchDataDependency);
    const {
      current,
      pageSize,
      filters,
      sort = "",
      sortKey = "",
    } = paginationState;

    let paginationData = {
      pageNumber: current - 1,
      pageSize,
      sort,
      sortKey,
      ...filters,
    };

    if (!sort || sort === "") {
      delete paginationData.sort;
      delete paginationData.sortKey;
    }

    if (fetchDataFunc) {
      fetchDataFunc(paginationData);
      return false;
    }
    setLoading(true);
    if (api) {
      getService(api, paginationData)
        .then((resp) => {
          fetchThen(resp);
        })
        .catch((error) => fetchCatch(error));
    } else if (apiService) {
      apiService(paginationData)
        .then((resp) => {
          fetchThen(resp);
        })
        .catch((error) => fetchCatch(error));
    }
  };

  function fetchThen(resp) {
    let data = [];
    let metaData = {};

    if (resp && resp.data && resp.data.content && !responseProperty) {
      const { content, ...meta } = resp.data;
      data = content;
      metaData = meta;
    } else if (
      responseProperty &&
      resp &&
      resp.data &&
      resp.data[responseProperty]
    ) {
      data = resp.data[responseProperty];
      metaData = resp.data;
    } else if (resp && resp.data) {
      data = resp.data;
    }

    // if response is array for better filter convert to object
    if (data.length && responseIsArray) {
      data = convertArrayToTitleObject(data);
    }

    setMetaData(metaData);
    setApiData(data);
    setLoading(false);
    setHasError(false);
  }

  function fetchCatch(error) {
    setLoading(false);
    setHasError(true);
    message.error(t("general::receiveError"), 3);
    setApiData([]);
    Log(error, logType.error);
  }

  function deleteThen() {
    message.info(t("general::deletedSuccessfully"), 3);
    fetchData();
    setLoading(false);
  }

  function deleteCatch(error) {
    message.error(t("general::errorOnDeletingData"), 3);
    Log(error, logType.error);
    setLoading(false);
  }

  function setMetaData(metaData) {
    const { size, totalElements } = metaData;
    setPaginationState({
      ...paginationState,
      total: totalElements,
      pageSize: size,
      current: tableProps?.properties ? 1:paginationState.current
    });
  }

  const handleChange = (pagination, filters, sorter) => {
    let temp = {
      ...paginationState,
      current: pagination.current,
      pageSize: pagination.pageSize,
      sortKey: "",
      sort: "",
    };

    if (sorter && sorter.order) {
      temp.sortKey = sorter.column.dataIndex;
      temp.sort = sorter.order === "ascend" ? "ASC" : "DESC";
    } else if (paginationState.sortKey && paginationState.sort) {
      temp.sortKey = paginationState.sortKey;
      temp.sort = paginationState.sort;
    } else {
      temp.sortKey = defaultSort.key || "";
      temp.sort = defaultSort.order || "";
    }

    // if (filters) {
    //   let filtersParse = {};
    //   if (filters && JSON.stringify(filters) !== "{}") {
    //     for (let i in filters) {
    //       let val = filters[i];
    //       if (val && val.length === 1) {
    //         val = val[0];
    //         if (typeof val === "object") {
    //           filtersParse = {
    //             ...filtersParse,
    //             ...val,
    //           };
    //         } else {
    //           filtersParse[i] = val;
    //         }
    //       }
    //     }
    //   }
    //
    //   temp.filters = { ...filtersParse };
    // }

    setPaginationState(temp);
  };

  // const getColumnSearchProps = (dataIndex) => ({
  //   // filterDropdown: ({
  //   //   setSelectedKeys,
  //   //   selectedKeys,
  //   //   confirm,
  //   //   clearFilters,
  //   // }) => (
  //   //   <SearchFilter
  //   //     dataIndex={dataIndex}
  //   //     selectedKeys={selectedKeys}
  //   //     setSelectedKeys={setSelectedKeys}
  //   //     confirm={confirm}
  //   //     clearFilters={clearFilters}
  //   //   />
  //   // ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  //   ),
  // });

  // const handleFilters = (
  //   dataIndex,
  //   CustomFilter = undefined,
  //   CustomFilterIcon = undefined
  // ) => ({
  //   // filterDropdown: (props) =>
  //   //   CustomFilter ? (
  //   //     <CustomFilter {...props} dataIndex={dataIndex} />
  //   //   ) : (
  //   //     <FilterFromTo {...props} dataIndex={dataIndex} />
  //   //   ),
  //   // filterIcon: () =>
  //   //   CustomFilterIcon ? <CustomFilterIcon /> : <CalendarOutlined />,
  // });

  return (
    <Form className="crud">
      {props.children && create && (create.api || create.apiService) && (
        <Create
          {...create}
          fetchData={fetchData}
          otherButtons={otherButtons}
          buttonInitial={{
            disabled: loadingProps ? !!loadingProps : loading,
          }}
        >
          {React.cloneElement(props.children, {
            fetchData,
            ...create,
          })}
        </Create>
      )}

      {props.children && update && (update.api || update.apiService) && (
        <Create
          fetchData={fetchData}
          forUpdate={true}
          modalInitial={{
            visible: showUpdateModal !== false,
            onCancel: () => setShowUpdateModal(false),
          }}
          formPropsInitial={{
            initialValues:
              showUpdateModal && showUpdateModal !== false
                ? showUpdateModal
                : {},
          }}
          {...update}
        >
          {React.cloneElement(props.children, {
            record: showUpdateModal,
            fetchData,
            forUpdate: true,
            showModal: showUpdateModal !== false,
            ...update,
          })}
        </Create>
      )}

      <Read
        apiData={apiData}
        dataSource={dataSource}
        fetchData={fetchData}
        hasError={hasError}
        loading={loadingProps ? loadingProps : loading}
        tableColumn={tableColumn}
        handleChange={handleChange}
        tableProps={tableProps}
        fromToInitialValue={fromToInitialValue}
        // pagination={{ pageNumber, pageSize, sort, totalPages }}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        showTotalItems={showTotalItems}
        filter={filter}
        setFilter={setFilter}
        setApiData={setApiData}
        isFilter={isFilter}
      />
    </Form>
  );
};

Crud.propTypes = {
  create: PropTypes.shape({
    apiService: PropTypes.func,
    api: PropTypes.string,
    button: PropTypes.shape({
      title: PropTypes.string,
    }),
    modal: PropTypes.shape({
      title: PropTypes.string,
    }),
    formProps: PropTypes.shape({
      initialValues: PropTypes.shape({
        type: PropTypes.string,
      }),
    }),
    fetchData: PropTypes.func,
    justLog: PropTypes.bool,
  }),
  get: PropTypes.shape({
    apiService: PropTypes.func,
    api: PropTypes.string,
    responseProperty: PropTypes.string,
    responseIsArray: PropTypes.bool,
    tableProps: PropTypes.shape({
      rowKey: PropTypes.any,
    }),
    columns: PropTypes.array,
    dataSource: PropTypes.array,
    fetchDataFunc: PropTypes.func,
    fetchDataDependency: PropTypes.array,
    pagination: PropTypes.shape({
      sort: PropTypes.string,
      total: PropTypes.number,
      current: PropTypes.number,
      pageSize: PropTypes.number,
    }),
    defaultSort: PropTypes.shape({
      order: PropTypes.string,
      key: PropTypes.string,
    }),
  }),
  update: PropTypes.shape({
    apiService: PropTypes.func,
    api: PropTypes.string,
    onInitial: PropTypes.func,
    modal: PropTypes.shape({
      title: PropTypes.string,
    }),
    formProps: PropTypes.object,
    justLog: PropTypes.bool,
  }),
  remove: PropTypes.shape({
    apiService: PropTypes.func,
    api: PropTypes.string,
  }),
  otherAction: PropTypes.array,
  otherButtons: PropTypes.node,
  loading: PropTypes.bool,
  showTotalItems: PropTypes.bool,
  totalItemsCount: PropTypes.number,
};

export default Crud;
