import { ProfileReduxTypes } from "./profile.constant";
import { getProfile } from "./profile.api";

export const fetchProfile = () => (dispatch) => {
  dispatch({
    type: ProfileReduxTypes.FETCH_PROFILE,
  });

  getProfile()
    .then((response) => {
      let values = response.data;
      if (
        values &&
        values.extraInfo &&
        values.extraInfo.addressList &&
        values.extraInfo.addressList.length > 0
      ) {
        values.extraInfo.addressList = values.extraInfo.addressList.map(
          (item) => {
            if ((item.lng || item.lon) && item.lat) {
              return {
                ...item,
                location: {
                  lat: item.lat,
                  lng: item.lon ? item.lon : item.lng,
                },
              };
            }

            return {
              ...item,
              location: {
                lat: 14.5936002,
                lng: 120.9836623,
              },
            };
          }
        );
      }

      sessionStorage.setItem("profile", JSON.stringify(values));
      dispatch({
        type: ProfileReduxTypes.FETCH_PROFILE_SUCCESS,
        payload: values,
      });
    })
    .catch((error) => {
      dispatch({
        type: ProfileReduxTypes.FETCH_PROFILE_FAILURE,
        payload: error,
      });
    });
};
