import React from "react";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import {
  getOpinionRateService,
  updateOpinionService,
} from "./rateController.api";
import { useTranslation } from "react-i18next";
import RateUpdateForm from "./components/RateUpdateForm";

const RateController = ({ type, item }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("rate::rateValueEnum"),
      dataIndex: "rateValueEnum",
      key: "rateValueEnum",
    },
    {
      title: t("rate::opinions"),
      dataIndex: "positiveOpinions",
      key: "positiveOpinions",
      width: "20%",
      render: (text, record) => {
        if (record.rateValueEnum === "ONE" || record.rateValueEnum === "TWO") {
          return (
            <ul style={{ paddingInlineStart: "18px" }}>
              {record.negativeOpinions.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          );
        } else if (
          record.rateValueEnum === "THREE" ||
          record.rateValueEnum === "FOUR"
        ) {
          return (
            <ul style={{ paddingInlineStart: "18px" }}>
              {record.positiveOpinions.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          );
        }
      },
    },
    {
      title: t("rate::title"),
      dataIndex: "title",
      key: "title",
      width: "20%",
    },
  ];

  return (
    <Container classNames="contents">
      <Crud
        get={{
          apiService: (params) => {
            return getOpinionRateService(params);
          },
          columns,
        }}
        update={{
          apiService: (params) => updateOpinionService(params),
          modal: {
            title: t("general::Update"),
          },
        }}
      >
        <RateUpdateForm />
      </Crud>
    </Container>
  );
};

export default RateController;
