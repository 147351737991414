import React, { useState } from "react";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import { getDataHistory, postEndRentService } from "./history.api";
import { useTranslation } from "react-i18next";
import { Button, message } from "antd";
import { useParams } from "react-router-dom";
import ExtraInfo from "./components/extraInfo/ExtraInfo";
import EditPrice from "./components/editPrice/EditPrice";
import { showDetailDate, convertSecondsToDhms } from "../../../../app/util";
import CustomFilterState from "../../../customFilters/CustomFilterState";
import CustomFilterEmail from "../../../customFilters/CustomFilterEmail";
import CustomFilterMobile from "../../../customFilters/CustomFilterMobile";
import CustomFilterAdditionalCode from "../../../customFilters/CustomFilterAdditionalCode";
import moment from "moment/moment";

const History = (props) => {
  let { historyType } = props.item;
  const { parentId } = useParams();
  const { t } = useTranslation();
  const [extraInfoPopup, setExtraInfoPopup] = useState(false);
  const [editPrice, setEditPrice] = useState();
  const [editPriceIsOpen, setEditPriceIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: t("order::orderNumber"),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
    },
    {
      title: t("order::createDate"),
      dataIndex: "rentStartTime",
      key: "rentStartTime",
      filter: "fromTo",
      sorter: true,
      render: (text) => {
        try {
          return showDetailDate(text);
        } catch {
          return text;
        }
      },
    },
    {
      title: t("general::mobile"),
      dataIndex: "mobile",
      key: "mobile",
      customFilter: CustomFilterMobile,
    },
    {
      title: t("general::email"),
      dataIndex: "email",
      key: "email",
      customFilter: CustomFilterEmail,
    },
    {
      title: t("order::duration") + " (DD:HH:MM:SS)",
      dataIndex: "durationInSeconds",
      key: "durationInSeconds",
      render: (durationInSeconds) => {
        return convertSecondsToDhms(durationInSeconds);
      },
    },
    {
      title: t("order::strollerID"),
      dataIndex: "strollerId",
      key: "strollerId",
    },
    {
      title: t("order::cartId"),
      dataIndex: "additionalCode",
      key: "additionalCode",
      customFilter: CustomFilterAdditionalCode,
    },
    {
      title: t("order::state"),
      dataIndex: "rentState",
      key: "rentState",
      customFilter: CustomFilterState,
      render: (text) => {
        if (text && text === "FREE") {
          return <span style={{ color: "green" }}>Available</span>;
        } else if (text && text === "RENTED") {
          return <span style={{ color: "red" }}>Rented</span>;
        }
      },
    },
    {
      title: t("general::extraInfo"),
      dataIndex: "usageDuration",
      key: "usageDuration",
      render: (text, record) => {
        return (
          <Button type="primary" onClick={() => setExtraInfoPopup(record)}>
            {t("general::viewInformation")}
          </Button>
        );
      },
    },
  ];

  const handleEndRent = (rentId, fetchData) => {
    setLoading(true);
    postEndRentService(rentId)
      .then((response) => {
        message.success(t("general::successEndRent"), 3);
        fetchData();
      })
      .catch((error) => {
        message.error(t("general::errorGettingInformation"), 3);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container loading={loading}>
      <Crud
        otherAction={[
          {
            name: t("editPrice"),
            func: (record) => {
              setEditPriceIsOpen(!editPriceIsOpen);
              setEditPrice(record);
            },
          },
          {
            name: t("endRent"),
            func: (record, fetchData) => {
              handleEndRent(record.rentId, fetchData);
            },
            notShowFunc: (record) => {
              if (record.rentState === "FREE") {
                return true;
              } else {
                return false;
              }
            },
          },
        ]}
        get={{
          apiService: (params) => {
            return getDataHistory(params, historyType, parentId);
          },
          tableProps: {
            size: "middle",
          },
          columns,
        }}
        fromToInitialValue = {{
        from:moment()
        .subtract(0, "days")
        .set({ hour: 0, minute: 0, second: 1 }),
        to:moment()
        .subtract(0, "days")
        .set({ hour: 23, minute: 59, second: 59 })
      }}
      />

      {editPriceIsOpen === true && (
        <EditPrice
          isOpen={editPriceIsOpen}
          setIsOpen={setEditPriceIsOpen}
          editPrice={editPrice}
          toggle={() => setEditPriceIsOpen(!editPriceIsOpen)}
        />
      )}

      <ExtraInfo
        extraInfoPopup={extraInfoPopup}
        setExtraInfoPopup={setExtraInfoPopup}
      />
    </Container>
  );
};

export default History;
