import AppLogo from "../assets/logo.svg";
import LoginLogo from "../assets/logowithname.svg";

export default {
  baseUrl:
    // process.env.REACT_APP_BASE_URL || "https://web-stage.cuddlecart.com/",
    window.location.hostname === "localhost" ||
    window.location.hostname === "panel-stage.cuddlecart.com"
      ? "https://web-stage.cuddlecart.com/"
      : "https://web.cuddlecart.com/",
  appName: process.env.REACT_APP_APP_NAME || "Vasl App MBaas Platform",
  appLogo: process.env.REACT_APP_APP_LOGO || AppLogo,
  loginLogo: process.env.REACT_APP_APP_LOGO || LoginLogo,
  mapBoxToken:
    process.env.REACT_APP_MAPBOX_TOKEN ||
    "pk.eyJ1Ijoic2FlZWRrb2xpdmFuZCIsImEiOiJja2U2cThzOWIxZmN1MnpvYjF5OGxuY2RhIn0.M8J3Z477nY6jKuztU1-iaA",
  defaultHeader: {
    Accept: "application/json",
    lang: "en",
  },
  credential: {
    username: "sampleClient",
    password: "sampleSecret",
  },
  getTokenApi: "oauth/token",
  prefix: "api/",
  setMultipleLang: false,
};
