import { HttpService } from "../../../../app/apiService";

const API = "v1/cuddle/owners";
const APIOwner = "v1/cuddle/subscriber/suggested/owners";
const APIMall = "v1/cuddle/malls";

export const getClubOwnerService = (data) => {
  const params = {
    mallId: data,
  };
  return HttpService.get(API, { params });
};

export const getAllAvailableOwnerService = (data) => {
  const params = {
    mallId: data,
  };
  return HttpService.get(APIOwner, { params });
};

export const addClubOwnerService = (params) => {
  return HttpService.post(API, params);
};

export const updateClubOwnerService = (params) => {
  return HttpService.put(API, params);
};

export const getMallService = (id) => {
  return HttpService.get(`${APIMall}/${id}`);
};
