import { HttpService } from "../../app/apiService";
import { downloadURL } from "../../app/util";
import qs from "qs";

export const API = "/v1/cuddle/rents/reports";
export const batteryApi = "/v1/cuddle/rents/reports/battery";
export const failedPaymentApi = "/v1/cuddle/pay/failed";
export const failedPaymentApiV2 = "/v1/ipg/transaction/failed";
export const failedPaymentInfo = "/v1/cuddle/rents";

export const getReportFinancialService = (params) => {
  let filter;

  if (params) {
    if (params.sortKey === "rentStartTime") {
      params.sortKey = "startTime";
    }

    if (params.strollerId) {
      params.strollerIdSet = params.strollerId;
      delete params.strollerId;
    }
    if (params.mallIds) {
      params.mallIdSet = params.mallIds;
      delete params.mallIds;
    }
    if (params.mobile === "") {
      params.mobile = undefined;
    }
    if (params.email === "") {
      params.email = undefined;
    }
    if (params.rentState) {
      params.mallIdSet = params.rentState;
      delete params.rentState;
    }
  }

  if (params.from && params.to) {
    filter = {
      rentFrom: params.from.format("YYYY-MM-DDTHH:mm:ss"),
      rentTo: params.to.format("YYYY-MM-DDTHH:mm:ss"),
    };
    delete params.from;
    delete params.to;
  }

  params = {
    ...params,
    ...filter,
    sortKey: "startTime",
  };

  return HttpService.get(`${API}/integrated`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const getStrollerService = (params) => {
  let filter;
  if (params) {
    if (params.from && params.to) {
      filter = {
        rentFrom: params.from.format("YYYY-MM-DDTHH:mm:ss"),
        rentTo: params.to.format("YYYY-MM-DDTHH:mm:ss"),
      };
      delete params.from;
      delete params.to;
    }
    params = {
      ...params,
      sort: "DESC",
      sortKey: "createdDate",
      mallIdSet: params.mallIds,
      ...filter,
    };
  }
  return HttpService.get(`${API}/stroller-grouped`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const getMallGroupedService = (data) => {
  let params = data;
  if (data && data.from && data.to) {
    params = {
      ...data,
      from: data.from.format("YYYY-MM-DDTHH:mm:ss"),
      to: data.to.format("YYYY-MM-DDTHH:mm:ss"),
    };
  }
  return HttpService.get(`${API}/mall-grouped`, { params });
};

export const getRentsReportsStatisticalService = (data) => {
  let params;
  if (data) {
    params = {
      from: data.from.format("YYYY-MM-DDTHH:mm:ss"),
      to: data.to.format("YYYY-MM-DDTHH:mm:ss"),
    };
  }
  return HttpService.get(`${API}/statistical`, { params });
};

export const getReportExcelService = (
  params,
  type = "mall-grouped.csv",
  setLoading
) => {
  if (setLoading) {
    setLoading(true);
  }

  console.log("params", params);

  // params = {
  //   ...params,
  //   rentFrom:
  //     dateFilter &&
  //     dateFilter.from &&
  //     dateFilter.from.format("YYYY-MM-DDTHH:mm:ss"),
  //   rentTo:
  //     dateFilter &&
  //     dateFilter.to &&
  //     dateFilter.to.format("YYYY-MM-DDTHH:mm:ss"),
  // };

  return HttpService.get(`${API}/${type}`, {
    params,
  })
    .then(async (r) => {
      let data = await r.data;
      downloadURL(
        "data:text/plain;charset=utf-8,%EF%BB%BF" + encodeURIComponent(data),
        `report-${JSON.stringify(params)}.csv`
      );
    })
    .finally(() => {
      if (setLoading) {
        setLoading(false);
      }
    });
};

export const getReportBatteryService = (params) => {
  params = {
    sort: "DESC",
    sortKey: "batteryCapacity",
    ...params,
  };
  return HttpService.get(batteryApi, { params });
};

export const failedPayment = (params) => {
  return HttpService.get(failedPaymentApi, { params });
};

export const failedPaymentV2 = (params) => {
  return HttpService.get(failedPaymentApiV2, { params });
};

export const failedPaymentInfoService = (id) => {
  return HttpService.get(`${failedPaymentInfo}/${id}`);
};
