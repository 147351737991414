import React from "react";
import { Card } from "antd";
import { camelCaseToText, capitalize } from "../../app/util";

const { Meta } = Card;
const DashboardCard = (props) => {
  const { color, title, value, icons } = props;
  return (
    // <Card type="flex" align="middle">
    //   <Title level={4}>{value}</Title>
    //   <div className="icons-dashboard">
    //     {icons[title] !== undefined ? icons[title] : null}
    //   </div>
    //   <Text type="secondary">{capitalize(camelCaseToText(title))}</Text>
    // </Card>
    <Card>
      <Meta
        avatar={
          <p
            className="icons-dashboard"
            style={{ backgroundColor: color[title] }}
          >
            {icons[title] !== undefined ? icons[title] : null}
          </p>
        }
        title={value}
        description={capitalize(
          camelCaseToText(
            title
              .replace("numberOf", "")
              .replace("totalDurationMinutes", "Duration")
              .replace("Malls", "operators")
              .replace("Strollers", "carts")
              .replace("totalPrice", "total Sales")
          )
        )}
      />
    </Card>
  );
};

export default DashboardCard;
