import { ConfigReduxTypes } from "./config.constant";

const initialState = {
  data: {},
  loading: true,
  hasError: false,
};

export const configReducer = (state = initialState, { payload, ...action }) => {
  switch (action.type) {
    case ConfigReduxTypes.FETCH_CONFIG:
      return {
        ...initialState,
      };
    case ConfigReduxTypes.FETCH_CONFIG_SUCCESS:
      return {
        loading: false,
        hasError: false,
        data: payload,
      };

    case ConfigReduxTypes.FETCH_CONFIG_FAILURE:
      return {
        loading: false,
        hasError: true,
        data: {},
      };
    default:
      return state;
  }
};
