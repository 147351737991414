import { ContentNewsReduxTypes } from "./content.constant";
import {
  ContentLanguages,
  PublishStatusTypes,
} from "./components/createContent/createContent.constant";
import { getCookieLanguages } from "../../app/util";

export const initialState = {
  contents: [],
  meta: {
    title: "",
    summary: "",
    banner: "",
    language:
      getCookieLanguages() === "fa" ? ContentLanguages.fa : ContentLanguages.en,
    status: PublishStatusTypes.published,
    tagsId: [],
    catsId: [],
    type: "content",
  },
  fieldsMetaData: {},
  fields: {},
  loading: false,
  hasError: false,
};

// ContentNewsReduxTypes
export const ContentNewsReducer = (
  state = initialState,
  { payload, ...action }
) => {
  switch (action.type) {
    case ContentNewsReduxTypes.Fetch:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case ContentNewsReduxTypes.FetchSuccess:
      let fieldsMetaData = state.fieldsMetaData;
      if (payload && payload.fields && payload.fields.metaData) {
        fieldsMetaData = payload.fields.metaData;
      }

      return {
        ...state,
        hasError: false,
        loading: false,
        contents: payload.contents,
        meta: payload.metaData,
        fields: payload.fields,
        fieldsMetaData,
      };
    case ContentNewsReduxTypes.FetchFailure:
      return { ...state, hasError: true, loading: false };
    case ContentNewsReduxTypes.AddContent:
      return {
        ...state,
        hasError: false,
        loading: false,
        contents: [...state.contents, payload],
      };
    case ContentNewsReduxTypes.EditContent:
      state.contents.forEach((i, index) => {
        if (i.id === payload.id) {
          state.contents[index] = {
            ...i,
            value: payload.value,
          };
        }
      });

      return { ...state, hasError: false, loading: false };
    case ContentNewsReduxTypes.ChangeMeta: {
      let { name, value } = payload;
      state.meta = {
        ...state.meta,
        [name]: value,
      };

      return { ...state, hasError: false, loading: false };
    }
    case ContentNewsReduxTypes.ChangeField: {
      let { name, value, isMeta } = payload;

      if (isMeta) {
        state.fieldsMetaData[name] = value;
      } else {
        state.fields[name] = value;
      }

      return { ...state, hasError: false, loading: false };
    }
    case ContentNewsReduxTypes.DeleteContent:
      state.contents.forEach((i, index) => {
        if (i.id === payload.id) {
          state.contents.splice(index, 1);
        }
      });

      return { ...state, hasError: false, loading: false };
    case ContentNewsReduxTypes.resetStore:
      let res = {
        contents: [],
        meta: {
          title: "",
          summary: "",
          banner: "",
          language:
            getCookieLanguages() === "fa"
              ? ContentLanguages.fa
              : ContentLanguages.en,
          status: PublishStatusTypes.published,
          tagsId: [],
          catsId: [],
          type: payload.type,
        },
        fieldsMetaData: {},
        fields: {},
        loading: false,
        hasError: false,
      };
      return res;
    default:
      return state;
  }
};
