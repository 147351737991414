import React, { useEffect, useState } from "react";
import Container from "../../../container/Container";
import CreateUserForm from "./components/CreateUserForm/CreateUserForm";
import {
  API,
  getPivotService,
  getPivotTypesService,
  getSystemUsers,
  updateSubscriberService,
} from "./users.api";
import PivotModal from "./components/pivotModal/PivotModal";
import Crud from "../../../crud/Crud";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Image, message, Select } from "antd";
import plus from "../../../../assets/plus.svg";

const { Option } = Select;
const Users = () => {
  const { t } = useTranslation();
  const { list } = useSelector((state) => state.rolesReducer);
  const [roleType, setRoleType] = useState("ROLE_USER");
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pivotIds, setPivotIds] = useState([]);
  const [pivotIsOpen, setPivotIsOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [user, setUser] = useState("");

  useEffect(() => {
    fetchTypeList();
    getPivot();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: t("userManagement::username"),
      dataIndex: "username",
      key: "username",
      width: "30%",
    },
    {
      title: t("userManagement::roles"),
      dataIndex: "roles",
      key: "roles",
    },
  ];
  function onFinish(values) {
    return values;
  }
  const RenderRoles = () => {
    let values = Object.keys(list);
    return values.map((role, index) => (
      <Option key={`role-${index}`} value={role}>
        {role}
      </Option>
    ));
  };

  const handleRoleChange = (value) => {
    setRoleType(value);
  };

  function fetchTypeList() {
    setLoading(true);
    getPivotTypesService()
      .then((r) => {
        let data = r.data;
        setTypes([...data].shift().value);
      })
      .catch((e) => {
        message.error("error!", 3);
      })
      .finally(() => setLoading(false));
  }

  function getPivot() {
    getPivotService()
      .then((res) => {
        setPivotIds(res.data.content);
      })
      .catch((err) => {
        message.error(t("general::errorConnectingToTheServer"), 3);
        setHasError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Container classNames="contents" loading={loading}>
      <Crud
        loading={loading}
        otherButtons={
          <Select
            optionFilterProp="children"
            showSearch
            style={{ width: 250 }}
            defaultValue={roleType}
            onChange={handleRoleChange}
          >
            {RenderRoles()}
          </Select>
        }
        otherAction={[
          {
            name: t("pivot"),
            func: (record) => {
              setPivotIsOpen(!pivotIsOpen);
              setUser(record);
            },
          },
        ]}
        create={{
          api: API,
          modal: {
            title: t("userManagement::createSystemUser"),
          },
          button: {
            title: t("userManagement::createSystemUser"),
            icon: (
              <Image src={plus} className="icon-add-items" preview={false} />
            ),
          },
        }}
        get={{
          apiService: (params) => getSystemUsers(params, roleType),
          fetchDataDependency: [roleType, pivotIsOpen],
          columns,
        }}
        remove={{
          api: API,
        }}
        update={{
          apiService: updateSubscriberService,
          modal: {
            title: t("Update"),
          },
          formProps: {
            onFinish,
          },
        }}
      >
        <CreateUserForm />
      </Crud>
      {pivotIsOpen === true && !hasError && (
        <PivotModal
          isOpen={pivotIsOpen}
          setIsOpen={setPivotIsOpen}
          pivotIds={pivotIds}
          user={user}
          typePtivot={types}
          toggle={() => setPivotIsOpen(!pivotIsOpen)}
          setLoadingUser={setLoading}
        />
      )}
    </Container>
  );
};

export default Users;
