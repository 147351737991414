import React, { useEffect, useState } from "react";
import Container from "../../../container/Container";
import {
  Button,
  Col,
  Form,
  InputNumber,
  message,
  PageHeader,
  Row,
  Select,
} from "antd";
import {
  addClubOwnerService,
  getAllAvailableOwnerService,
  getClubOwnerService,
  getMallService,
  updateClubOwnerService,
} from "./ownerClub.api";
import { useParams, useHistory } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const OwnerClub = () => {
  const [owners, setOwners] = useState({});
  const [loading, setLoading] = useState(false);
  const [allOwner, setAllOwner] = useState([]);
  const [disable, setDisable] = useState(false);
  const [mallName, setMallName] = useState("");
  const { mallId, catId } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();

  const getAllOwnerFunc = async (mallId) => {
    setLoading(true);
    await getAllAvailableOwnerService(mallId).then((res) => {
      setAllOwner(res.data);
    });
  };

  const getClubOwnerFunc = async (mallId) => {
    setLoading(true);
    const res = await getClubOwnerService(mallId);
    if (res.status === 200 && res.data.owners) {
      setLoading(false);
      const data = [];
      const response = res.data.owners;
      Object.keys(response).forEach((item) => {
        data.push({ mallOwner: item, percent: response[item] });
      });
      setOwners(data);
      form.setFieldsValue({
        owners: data,
      });
    }
  };

  const getMallName = (mallId) => {
    getMallService(mallId).then((res) => {
      const data = res.data;
      setMallName(data && data.title && data.title.fields[0]);
    });
  };
  useEffect(
    () => {
      async function allFetch() {
        try {
          await getAllOwnerFunc(mallId);
          await getClubOwnerFunc(mallId);
        } catch (error) {
          setLoading(false);
        }
      }
      allFetch();
      getMallName(mallId);
    },
    // eslint-disable-next-line
    []
  );

  const fieldCheckHandler = (changeVal, allVal) => {
    let allPercent = allVal.owners.reduce((sum, i) => {
      if (i && i.percent) {
        return sum + i.percent;
      } else {
        return sum + 0;
      }
    }, 0);

    if (allPercent > 100 || allPercent < 100) {
      //  message.error("The sum of the percentages is greater than 100", 3);
      setDisable(true);
    } else {
      setDisable(false);
    }
  };
  const addOwnerHandler = (data) => {
    let ownersTmp = {};
    data.owners.forEach((owner) => {
      ownersTmp = {
        ...ownersTmp,
        [owner.mallOwner]: owner.percent,
      };
    });

    const ownerItems = {
      mallId,
      owners: ownersTmp,
    };
    if (owners.length > 0) {
      setLoading(true);
      return updateClubOwnerService(ownerItems)
        .then((res) => {
          if (res.status === 200) {
            message.success("owner successfully updated");
            history.push(`/operations/mall-management/${catId}`);
          }
        })
        .catch((error) => {
          message.error(error.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      return addClubOwnerService(ownerItems)
        .then((res) => {
          message.success("owner successfully added");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Container classNames="contents" loading={loading}>
      <PageHeader className="site-page-header" title={mallName} />
      <Form
        form={form}
        name="basic"
        onFinish={addOwnerHandler}
        onValuesChange={fieldCheckHandler}
      >
        <Form.List name={"owners"}>
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                {fields.map((field, index) => {
                  return (
                    <Row key={index}>
                      <Col className="gutter-row" xs={24} sm={24} md={8} lg={8}>
                        <Form.Item
                          {...field}
                          label="mallOwner"
                          name={[field.name, "mallOwner"]}
                          fieldKey={[field.fieldKey, "mallOwner"]}
                          rules={[
                            {
                              required: true,
                              message: "This field is required!",
                            },
                          ]}
                        >
                          {/*<Input placeholder={"Enter owner"} />*/}
                          <Select
                            showSearch
                            placeholder="Search owner"
                            optionFilterProp="children"
                            // filterOption={(input, option) =>
                            //   option.children
                            //     .toLowerCase()
                            //     .indexOf(input.toLowerCase()) >= 0
                            // }
                            // filterSort={(optionA, optionB) =>
                            //   optionA.children
                            //     .toLowerCase()
                            //     .localeCompare(optionB.children.toLowerCase())
                            // }
                            // value={selected}
                            // onChange={(val) => setSelectedCountry(val)}
                          >
                            {allOwner.map((item, index) => (
                              <Option key={item.id} value={item.id}>
                                {item.username}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={{ span: 4, offset: 2 }}
                        lg={{ span: 4, offset: 2 }}
                      >
                        <Form.Item
                          {...field}
                          label="percent"
                          name={[field.name, "percent"]}
                          fieldKey={[field.fieldKey, "percent"]}
                        >
                          <InputNumber placeholder={"Enter percent"} />
                        </Form.Item>
                      </Col>
                      <MinusCircleOutlined onClick={() => remove(field.key)} />
                    </Row>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: "60%" }}
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <Form.Item>
          {disable && (
            <p style={{ color: "red" }}>
              The total percentage is not equal to 100
            </p>
          )}
          <Button type="primary" htmlType="submit" disabled={disable}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default OwnerClub;
