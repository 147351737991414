import {
  AppstoreOutlined,
  AuditOutlined,
  DingtalkOutlined,
  FormOutlined,
  SnippetsOutlined,
  SyncOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import React from "react";
import TicketMessages from "./components/TicketMessages";
import Ticket from "./pages/ticket/Ticket";
import Support from "./Administration";
import Faq from "./pages/faq/Faq";
import CustomerServiceFilled from "@ant-design/icons/lib/icons/CustomerServiceFilled";
import ForceUpdate from "./pages/forceUpdate/ForceUpdate";
import ReferralCode from "./pages/referral/referralCode";
import Categories from "../contentComponent/components/categories/Categories";
import ConfigController from "./pages/config/ConfigController";
import RateController from "./pages/rateController/RateConroller";
import Roles from "./pages/roles/Roles";
import { APP_Permissions } from "../userManagement/components/privileges/privileges.constant";
import StationMonitoring from "./pages/stationMonitoring/StationMonitoring";
import StationMonitoringMessages from "./components/StationMonitoringMessages";

const root = "/administration/";

export const administrationPathNames = {
  root,
  tickets: root + "tickets",
  messages: root + "tickets/messages/:ticketId",
  stationMonitoring: root + "station-monitoring",
  stationMessages: root + "station-monitoring/messages/:ticketId",
  faq: root + "faq",
  faqCategory: root + "faq/category",
  forceUpdate: root + "force-update",
  codes: root + "referral-code",
  category: root + "category",
  config: root + "config",
  rate: root + "rate",
  roles: root + "roles",
};

export const AdministrationRoutes = [
  {
    title: "administration",
    component: Support,
    path: administrationPathNames.root,
    exact: true,
    icon: <CustomerServiceFilled />,
    children: [
      {
        title: "stationMonitoring",
        path: administrationPathNames.stationMonitoring,
        component: StationMonitoring,
        exact: true,
        icon: <TeamOutlined />,
        permissions: [
          APP_Permissions["support"]["support_tickets.admin.getListTicket"],
        ],
      },
      {
        title: "ticket",
        path: administrationPathNames.tickets,
        component: Ticket,
        exact: true,
        icon: <TeamOutlined />,
        permissions: [
          APP_Permissions["support"]["support_tickets.admin.getListTicket"],
        ],
      },
      {
        title: "faq",
        path: administrationPathNames.faq,
        component: Faq,
        exact: true,
        icon: <TeamOutlined />,
        permissions: [
          APP_Permissions["faq"]["faq_queans.getAll"],
          APP_Permissions["faq"]["faq_category.getAll"],
        ],
      },
      // {
      //   title: "faqCategory",
      //   path: SupportPathNames.faqCategory,
      //   component: FaqCategory,
      //   exact: true,
      //   icon: <ApartmentOutlined />,
      //   permissions: [
      //     APP_Permissions["faq"]["faq_category.getAll"],
      // },
      {
        title: "forceUpdate",
        path: administrationPathNames.forceUpdate,
        component: ForceUpdate,
        exact: true,
        icon: <SyncOutlined />,
        permissions: [
          APP_Permissions["version"]["application_version.get.all"],
        ],
      },
      {
        title: "referralCodes",
        path: administrationPathNames.codes,
        component: ReferralCode,
        icon: <FormOutlined />,
        exact: true,
        permissions: [
          APP_Permissions["campaign"]["campaign_Campaign.getListCampaign"],
        ],
      },
      {
        title: "categories",
        path: administrationPathNames.category,
        component: Categories,
        exact: true,
        icon: <SnippetsOutlined />,
        permissions: [APP_Permissions["contents"]["contents_categories.list"]],
      },
      {
        title: "config",
        path: administrationPathNames.config,
        component: ConfigController,
        exact: true,
        icon: <AppstoreOutlined />,
        permissions: [APP_Permissions["config"]["cuddle_config.getAll"]],
      },
      {
        title: "rateController",
        path: administrationPathNames.rate,
        component: RateController,
        exact: true,
        icon: <AuditOutlined />,
        permissions: [
          APP_Permissions["rating"]["rating_getAll"],
          APP_Permissions["rating"]["rating_opinion.getAll"],
        ],
      },
      {
        title: "userRole",
        path: administrationPathNames.roles,
        component: Roles,
        icon: <DingtalkOutlined />,
        exact: true,
        permissions: [
          APP_Permissions["securityServer"][
            "security-server_role-permission.getAll"
          ],
        ],
      },
    ],
  },
  {
    title: "messages",
    path: administrationPathNames.messages,
    component: TicketMessages,
    exact: true,
    icon: <TeamOutlined />,
    showInSideBar: false,
    permissions: [
      APP_Permissions["support"]["support_ticket-messages.getTicketMessage"],
    ],
  },
  {
    title: "messages",
    path: administrationPathNames.stationMessages,
    component: StationMonitoringMessages,
    exact: true,
    icon: <TeamOutlined />,
    showInSideBar: false,
    permissions: [
      APP_Permissions["support"]["support_ticket-messages.getTicketMessage"],
    ],
  },
];
