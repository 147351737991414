import { ConfigReduxTypes } from "./config.constant";
import { getConfigs } from "./config.api";

export const fetchConfigs = () => (dispatch) => {
  dispatch({
    type: ConfigReduxTypes.FETCH_CONFIG,
  });

  getConfigs({
    keys: "FREE_RENT_TIME,RENT_LIMIT, CHANGE_INVOICE_QUESTION_ID, FOLDER_ID",
  })
    .then((response) => {
      let configData = {};

      response.data.forEach((item) => {
        configData[item.key] = item.value;
      });

      sessionStorage.setItem("configs", JSON.stringify(configData));

      dispatch({
        type: ConfigReduxTypes.FETCH_CONFIG_SUCCESS,
        payload: configData,
      });
    })
    .catch((error) => {
      dispatch({
        type: ConfigReduxTypes.FETCH_CONFIG_FAILURE,
        payload: error,
      });
    });
};
