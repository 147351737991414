import { HttpService } from "../../app/apiService";
import { API as categoriesAPI } from "./components/categories/categories.api";

export const API = "v1/contents";

export const getCategories = (params) => {
  let temp = { pageNumber: 0, pageSize: 10, sort: "ASC" };
  let res = {
    ...temp,
    ...params,
    sort: "DESC",
    sortKey: "publishDate",
    type: "mall",
  };
  return HttpService.get(`${categoriesAPI}`, { params: res });
};

export const getContent = (params = { pageNumber: 0, pageSize: 25 }, api) => {
  if (params) {
    params = {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      sortKey: "publishDate",
      sort: params.publishDate ? params.publishDate : "DESC",
      ...params,
    };
  }

  return HttpService.get(`${api ? api : API}`, { params });
};

export const getContentById = (id, api, params) => {
  return HttpService.get(`${api ? api : API}/${id}`, { params });
};

export const removeContentById = (id, api) => {
  return HttpService.delete(`${api ? api : API}/${id}`);
};
