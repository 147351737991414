import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../container/Container";

const Operation = () => {
  const { t } = useTranslation();
  return <Container>{t("content::contentManagement")}</Container>;
};

export default Operation;
