import React, { useState } from "react";
import { Popconfirm } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DeleteConfirmation = (props) => {
  const { t } = useTranslation();
  const { deleteFunction } = props;

  const [visible, setVisible] = useState(false);

  return (
    <Popconfirm
      placement="right"
      title={t("content::areYouSureYouWantToRemoveThisPart")}
      onConfirm={deleteFunction}
      okText={t("general::yes")}
      cancelText={t("general::no")}
    >
      <div
        onClick={() => setVisible(!visible)}
        className="delete-btn flex-center"
      >
        <DeleteFilled />
      </div>
    </Popconfirm>
  );
};

DeleteConfirmation.propTypes = {
  deleteFunction: PropTypes.func.isRequired,
};

export default DeleteConfirmation;
