import React, { useEffect, useState } from "react";
import Container from "../../../container/Container";
import { getRoles } from "./roles.api";
import FormComponent from "./components/FormComponent/FormComponents";
import Crud from "../../../crud/Crud";
import ShowPrivilegesInTable from "./components/ShowPrivilegesInTable/ShowPrivilegesInTable";
import { useTranslation } from "react-i18next";

const Roles = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("general::title"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("general::privileges"),
      dataIndex: "privileges",
      key: "privileges",
      render: (record) => <ShowPrivilegesInTable rolePermissions={record} />,
    },
  ];

  // const getRoleFunc = () => {
  //   setLoading(true);
  //   getRole()
  //     .then((response) => {
  //       setData(response.data.content);
  //       setLoading(false);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };
  // useEffect(
  //   () => {
  //     getRoleFunc();
  //   },
  //   // eslint-disable-next-line
  //   []
  // );

  // function onFinish(values) {
  //   if (!selectedRows || selectedRows.length === 0) {
  //     message.error(t("general::atLeastSelectOnePrivilegeForRole"), 3);
  //     return false;
  //   }
  //   values.privileges = selectedRows;
  //
  //   return values;
  // }

  const getRolesFunc = () => {
    setLoading(true);
    let roles = [];
    getRoles().then((res) => {
      if (res && res.data && res.data.content) {
        roles = res.data.content.filter((item) => {
          return item.name !== "ROLE_ADMIN";
        });
        setData(roles);
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    getRolesFunc();
  }, []);

  return (
    <Container classNames="contents" loading={loading}>
      <Crud
        // otherAction={[
        //   {
        //     name: "delete",
        //     func: (record) => {
        //       const result = UnUpdatedRoles.find(
        //         (unUpdatedRole) => record.name === unUpdatedRole
        //       );
        //       if (!result) {
        //         setLoading(true);
        //         deleteRole(record.id)
        //           .then(() => {
        //             getRolesFunc();
        //           })
        //           .finally(() => {
        //             setLoading(false);
        //           });
        //       }
        //     },
        //     notShowFunc: (record) => {
        //       const result = UnUpdatedRoles.find(
        //         (unUpdatedRole) => record.name === unUpdatedRole
        //       );
        //       return result;
        //     },
        //   },
        // ]}
        get={{
          dataSource: data,
          columns,
          fetchDataFunc: () => {
            getRolesFunc();
          },
        }}
        // create={{
        //   api: API,
        //   modal: {
        //     title: t("userManagement::createRole"),
        //   },
        //   button: {
        //     title: t("userManagement::createRole"),
        //     icon: (
        //       <Image src={plus} preview={false} className="icon-add-items" />
        //     ),
        //   },
        //   formProps: {
        //     onFinish,
        //   },
        // }}
        // get={{
        //   dataSource: data,
        //   columns,
        // }}
        // remove={{
        //   api: API,
        // }}
        // update={{
        //   api: API,
        //   onInitial: (values) => {
        //     setSelectedRows(values.privileges);
        //     return values;
        //   },
        //   modal: {
        //     title: t("general::update"),
        //   },
        //   formProps: {
        //     onFinish,
        //   },
        // }}
      >
        <FormComponent
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </Crud>
    </Container>
  );
};

export default Roles;
