import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Radio, Select, TimePicker } from "antd";
import Container from "../../../container/Container";
import moment from "moment";
import momentTimezone from "moment-timezone";
import Location from "../location/Location";
import { PublishStatusTypes } from "../../../contentComponent/components/createContent/createContent.constant";
import MediaSection from "../mediaSection/MediaSection";
import { useHistory, useParams } from "react-router-dom";
import {
  createMallService,
  getMallByIdService,
  updateMallService,
} from "../../operation.api";
import { useTranslation } from "react-i18next";
import {
  checkValue,
  errorMessageHandler,
  getFieldValue,
} from "../../../../app/util";
import CustomInputJustFloatDigit from "../../../crud/components/CustomInputJustFloatDigit";

export const PropertyCreate = () => {
  const { catId, mallId } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [mallData, setMallData] = useState(null);

  useEffect(
    () => {
      if (mallId) {
        fetchMallById();
      }
    },
    // eslint-disable-next-line
    [mallId]
  );

  useEffect(
    () => {
      if (mallData) {
        // set temp data for edit mode
        let temp = { ...mallData };

        // title
        let title = getFieldValue(mallData.title);
        temp.title = Array.isArray(title) ? title.join(" ") : title;
        // lat, lng = location
        temp.location = {
          lat: mallData.lat,
          lng: mallData.lng,
        };
        delete temp.lng;
        delete temp.lat;
        // closingTime, openingTime

        // temp.openingTime = moment(mallData.openingTime).format("HH:mm:ss")
        temp.openingTime = moment(mallData.openingTime, "HH:mm:ss");
        // temp.closingTime = moment(mallData.closingTime).format("HH:mm:ss")
        temp.closingTime = moment(mallData.closingTime, "HH:mm:ss");
        // moment(value, "HH:mm:ss")

        // banner
        let banner = getFieldValue(mallData.banner);
        temp.banner = Array.isArray(banner) ? banner.join(" ") : banner;

        if (temp.banner === "-") {
          temp.banner = null;
        }
        temp.costPerTrip = temp.costPerTrip / 100;
        temp.initialFee = temp.initialFee / 100;
        form.setFieldsValue(temp);
        // console.log(form.getFieldsValue(), "getFieldsValue");
      }
    },
    // eslint-disable-next-line
    [mallData]
  );

  function fetchMallById() {
    setLoading(true);
    getMallByIdService(mallId)
      .then((r) => {
        // console.log(r.data);
        // form.setFieldsValue(r.data);
        setMallData(r.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onFinish(values) {
    let result = { ...values };
    result.closingTime = moment(result.closingTime).format("HH:mm:ss");
    result.openingTime = moment(result.openingTime).format("HH:mm:ss");
    result.lat = values.location.lat;
    result.lng = values.location.lng;
    delete result.location;
    result.title = {
      fields: [values.title],
      type: "text",
    };
    result.language = "EN";
    result.publishDate = mallData ? mallData.publishDate : new Date().getTime();
    result.banner = {
      fields: [values.banner ? values.banner : "-"],
      type: "text",
    };
    result.summary = {
      fields: [values.summary ? values.summary : "-"],
      type: "text",
    };
    result.catsId = [catId];
    result.type = "mall";
    result.costPerTrip = result.costPerTrip * 100;
    result.initialFee = result.initialFee * 100;

    setLoading(true);
    if (mallId) {
      result.id = mallData.id;
      updateMallService(result)
        .then(() => {
          message.success(t("content::contentCreatedSuccessfully"), 3);
          history.push(`/operations/mall-management/${catId}`);
        })
        .catch((error) => {
          message.error(errorMessageHandler(error), 3);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createMallService(result)
        .then(() => {
          message.success(t("content::contentCreatedSuccessfully"), 3);
          history.push(`/operations/mall-management/${catId}`);
        })
        .catch((error) => {
          message.error(errorMessageHandler(error), 3);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <Container loading={loading}>
      <Form
        onFinish={onFinish}
        initialValues={{
          status: PublishStatusTypes.published,
          openingTime: moment("9:00", "HH:mm"),
          closingTime: moment("18:00", "HH:mm"),
          timeZone: momentTimezone.tz.guess(),
        }}
        form={form}
      >
        <Form.Item
          label={"Title"}
          name={"title"}
          rules={[{ required: true }]}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Address"}
          name={["metaData", "address"]}
          rules={[{ required: true, message: "address is required" }]}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Phone Number"}
          name={["metaData", "phone"]}
          rules={[{ required: true, message: "phone number is required" }]}
          hasFeedback
        >
          <Input type="number" min={0} />
        </Form.Item>
        <Form.Item
          label={"Opening Time"}
          name={"openingTime"}
          rules={[{ required: true }]}
          hasFeedback
        >
          <MallTimePicker />
        </Form.Item>
        <Form.Item
          label={"Closing Time"}
          name={"closingTime"}
          rules={[{ required: true }]}
          hasFeedback
        >
          <MallTimePicker />
        </Form.Item>
        <Form.Item
          label={"Time increment (minutes)"}
          name={"tripTimeLimitInMinutes"}
          rules={[{ required: true }]}
          hasFeedback
        >
          <Input type="number" min={0} defaultValue={0} />
        </Form.Item>
        <Form.Item
          label={"Time Zone"}
          name={"timeZone"}
          rules={[{ required: true }]}
          hasFeedback
        >
          <Select showSearch style={{ width: 300 }}>
            {renderOptions()}
          </Select>
        </Form.Item>
        <Form.Item
          label={"cost per time increment ($)"}
          name={"costPerTrip"}
          rules={[{ required: true }]}
          hasFeedback
        >
          <CustomInputJustFloatDigit min={0} defaultValue={0} />
        </Form.Item>
        <Form.Item
          label={"initialFee ($)"}
          name={"initialFee"}
          rules={[{ required: true }]}
          hasFeedback
        >
          <CustomInputJustFloatDigit min={0} defaultValue={0} />
        </Form.Item>
        <Form.Item
          label={"location"}
          name={"location"}
          rules={[{ required: true }]}
          hasFeedback
        >
          <Location />
        </Form.Item>
        <Form.Item
          label={"Banner image"}
          name={"banner"}
          // rules={[{ required: true }]}
          hasFeedback
        >
          <MediaSection name={"banner"} accept="image/*" />
        </Form.Item>
        <Form.Item
          label={"Release status"}
          name={"status"}
          rules={[{ required: true }]}
          hasFeedback
        >
          <Radio.Group>
            <Radio
              value={PublishStatusTypes.published}
              children={"Active"}
              style={{ color: "green" }}
            />
            <Radio
              value={PublishStatusTypes.draft}
              children={"Inactive"}
              style={{ color: "red" }}
            />
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

function renderOptions() {
  const timezones = moment.tz.names();
  let mappedValues = {};
  let regions = [];

  timezones.forEach((timezone) => {
    const splitTimezone = timezone.split("/");
    const region = splitTimezone[0];
    if (!mappedValues[region]) {
      mappedValues[region] = [];
      regions.push(region);
    }
    mappedValues[region].push(timezone);
  });
  return regions.map((region) => {
    const options = mappedValues[region].map((timezone) => {
      return <Select.Option key={timezone}>{timezone}</Select.Option>;
    });
    return (
      <Select.OptGroup
        key={region}
        title={<div style={{ fontSize: 30 }}>{region}</div>}
      >
        {options}
      </Select.OptGroup>
    );
  });
}

function MallTimePicker({
  onChange: handleChange,
  value,
  title,
  defaultTime = "09:00",
}) {
  useEffect(() => {
    if (checkValue(value)) {
      console.error("value2", value);
      handleChange(moment(value, "HH:mm:ss"));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <TimePicker
        name={title}
        value={
          checkValue(value)
            ? moment(value, "HH:mm:ss")
            : moment(defaultTime, "HH:mm")
        }
        className="mt-2"
        defaultValue={moment(defaultTime, "HH:mm")}
        format={"HH:mm"}
        allowClear={false}
        onChange={(e) => {
          handleChange(e);
        }}
      />
    </>
  );
}

export default PropertyCreate;
