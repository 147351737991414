import React, { useState } from "react";
import { Button } from "antd";
import Container from "../../../container/Container";
import Crud from "../../../crud/Crud";
import { useTranslation } from "react-i18next";
import { showDetailDate, convertSecondsToDhms } from "../../../../app/util";
import { getReportFinancialService } from "../../reports.api";
import ExtraInfo from "../../../operation/pages/subscribers/components/extraInfo/ExtraInfo";
import CustomFilterMobile from "../../../customFilters/CustomFilterMobile";
import CustomFilterEmail from "../../../customFilters/CustomFilterEmail";

const Subscriber = () => {
  const { t } = useTranslation();
  const [extraInfoPopup, setExtraInfoPopup] = useState(false);

  const columns = [
    {
      title: t("order::orderNumber"),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
    },
    {
      title: t("order::createDate"),
      dataIndex: "rentStartTime",
      key: "rentStartTime",
      // filter: "fromTo",
      sorter: true,
      render: (text) => {
        return showDetailDate(text);
      },
    },

    {
      title: t("order::duration") + " (DD:HH:MM:SS)",
      dataIndex: "durationInSeconds",
      key: "durationInSeconds",
      render: (durationInSeconds) => {
        return convertSecondsToDhms(durationInSeconds);
      },
    },
    {
      title: t("order::strollerID"),
      dataIndex: "strollerId",
      key: "mobile",
      customFilter: CustomFilterMobile,
    },
    {
      title: t("order::cartId"),
      dataIndex: "additionalCode",
      key: "email",
      customFilter: CustomFilterEmail,
    },
    {
      title: t("order::state"),
      dataIndex: "rentState",
      key: "rentState",
      render: (text) => {
        if (text && text === "FREE") {
          return <span style={{ color: "green" }}>Available</span>;
        } else if (text && text === "RENTED") {
          return <span>Rented</span>;
        }
      },
    },
    {
      title: t("general::extraInfo"),
      dataIndex: "usageDuration",
      key: "usageDuration",
      render: (text, record) => {
        return (
          <Button type="primary" onClick={() => setExtraInfoPopup(record)}>
            {t("general::viewInformation")}
          </Button>
        );
      },
    },
  ];

  return (
    <Container>
      {/*<Row>*/}
      {/*  <div style={{ marginBottom: ".5em", marginLeft: ".5rem" }}>*/}
      {/*    <Button onClick={() => downloadExcel()}> Excel Download </Button>*/}
      {/*  </div>*/}
      {/*</Row>*/}
      <Crud
        get={{
          apiService: (params) => getReportFinancialService(params),
          columns,
        }}
      />
      <ExtraInfo
        extraInfoPopup={extraInfoPopup}
        setExtraInfoPopup={setExtraInfoPopup}
      />
    </Container>
  );
};

export default Subscriber;
