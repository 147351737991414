import React, { useEffect } from "react";
import { fetchPermissions } from "../userManagement/components/privileges/privileges.action";
import { useDispatch } from "react-redux";
import { fetchProfile } from "../dashboard/pages/profile/profile.action";
import { fetchRoles } from "../administration/pages/roles/role.action";
import { fetchConfigs } from "../configManagement/components/config/config.action";
import { fetchProfileImage } from "../dashboard/pages/profile/profileImage.action";

const AppContainer = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPermissions());
    // dispatch(fetchRoles());
    dispatch(fetchProfileImage());
    dispatch(fetchProfile());
    dispatch(fetchConfigs());
    dispatch(fetchRoles());
    // eslint-disable-next-line
  }, []);

  return <div>{props.children}</div>;
};

export default AppContainer;
