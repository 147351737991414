import Login from "./Login";
import { LoginOutlined } from "@ant-design/icons";
import React from "react";

export const LoginPathNames = {
  login: "/login",
};

export const LoginRoutes = [
  {
    path: LoginPathNames.login,
    component: Login,
    exact: true,
    icon: <LoginOutlined />,
    showInSideBar: false,
    isPublicRoute: true,
  },
];
