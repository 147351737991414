import React, { useCallback, useEffect, useState } from "react";
import { AutoComplete, Button, Input } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { getStrollerService } from "../operation/pages/stroller/stroller.api";
import { debounce } from "debounce";

function CustomFilterAdditionalCode(props) {
  const { setFilter, item, reset, setReset } = props;
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState();
  const [title, setTitle] = useState();

  useEffect(
    () => {
      if (searchResult && searchResult.value !== undefined) {
        setTitle(searchResult.value);
      }
    },
    // eslint-disable-next-line
    [searchResult]
  );

  useEffect(
    () => {
      setFilter((prevData) => ({
        ...prevData,
        [item]: title,
      }));
    },
    // eslint-disable-next-line
    [value, title]
  );

  const onSearch = (searchText) => {
    setValue(searchText);
  };

  const onSelect = (data, option) => {
    setSearchResult(option);
  };

  const onChange = (option) => {
    setTitle(option);
    setValue(option);
  };

  // useEffect(
  //   () => {
  //     fetchData();
  //   },
  //   // eslint-disable-next-line
  //   [value]
  // );

  useEffect(
    () => {
      verify(value);
    },
    // eslint-disable-next-line
    [value]
  );

  const verify = useCallback(
    debounce((value) => {
      fetchData(value);
    }, 1000),
    []
  );

  const fetchData = (value) => {
    setLoading(true);
    setOptions([]);
    getStrollerService({ additionalCode: value, pageSize: 100 })
      .then((r) => {
        let temp = r.data.content.map((i) => {
          return {
            value: i.additionalCode,
          };
        });
        setOptions(temp);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  useEffect(
    () => {
      setValue("");
      setSearchResult();
      setTitle("");
      setReset(false);
    },
    // eslint-disable-next-line
    [reset]
  );

  return (
    <>
      <AutoComplete
        allowClear
        backfill
        style={{ width: "100%", padding: "4px 9px" }}
        onSelect={onSelect}
        onSearch={onSearch}
        onChange={onChange}
        options={options}
        placeholder="Enter Number"
        value={title || value}
        loading={loading}
      >
        <Input
          suffix={
            <Button className={"down-icon"}>
              <DownOutlined />
            </Button>
          }
        />
      </AutoComplete>
    </>
  );
}

export default CustomFilterAdditionalCode;
