import React from "react";
import { Modal, Table } from "antd";
import PropTypes from "prop-types";
import moment from "jalali-moment";

const ExtraInfo = (props) => {
  const { extraInfoPopup, setExtraInfoPopup } = props;

  const columns = [
    {
      title: "Lock Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Change Date",
      dataIndex: "stateMachine",
      key: "stateMachine",

      render: (text) => {
        try {
          return moment(text.current && text.current.at).format(
            "YYYY/MM/DD-hh:mm"
          );
        } catch {
          return text;
        }
      },
    },
  ];

  return (
    <Modal
      title={"Information"}
      visible={extraInfoPopup !== false}
      onOk={() => setExtraInfoPopup(false)}
      onCancel={() => setExtraInfoPopup(false)}
      cancelText={"Close"}
      okButtonProps={{ hidden: true }}
    >
      <Table columns={columns} dataSource={extraInfoPopup} />
    </Modal>
  );
};

ExtraInfo.propTypes = {
  extraInfo: PropTypes.any,
  setExtraInfoPopup: PropTypes.func,
};

export default ExtraInfo;
