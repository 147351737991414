import { ProfileImageReduxTypes } from "./profileImage.constant";

const initialState = {
  image: "",
  loading: true,
  hasError: false,
};

export const profileImageReducer = (
  state = initialState,
  { payload, ...action }
) => {
  switch (action.type) {
    case ProfileImageReduxTypes.FETCH_PROFILE_IMAGE:
      return {
        ...initialState,
      };
    case ProfileImageReduxTypes.FETCH_PROFILE_IMAGE_SUCCESS:
      return {
        loading: false,
        hasError: false,
        image: payload,
      };
    case ProfileImageReduxTypes.FETCH_PROFILE_IMAGE_FAILURE:
      return {
        loading: false,
        hasError: true,
        image: "",
      };
    default:
      return state;
  }
};
