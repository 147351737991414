const base = "CONTENT_NEWS_";
export const ContentNewsReduxTypes = {
  Fetch: base + "FETCH",
  FetchSuccess: base + "FETCH_SUCCESS",
  FetchFailure: base + "FETCH_FAILURE",
  AddContent: base + "ADD_CONTENT",
  EditContent: base + "EDIT_CONTENT",
  DeleteContent: base + "DELETE_CONTENT",
  ChangeMeta: base + "CHANGE_META",
  ChangeField: base + "CHANGE_FIELD",
  AddBulkData: base + "ADD_BULK",
  resetStore: base + "RESET_STORE",
};
