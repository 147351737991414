import { HttpService } from "../../../../app/apiService";
import { message } from "antd";

export const API = "v1/cuddle/strollers";

export const getStrollerService = (params) => {
  if (params) {
    params = {
      sortKey: "createdDate",
      sort: "DESC",
      ...params,
    };
  }

  return HttpService.get(API, { params });
};

export const addStrollerService = (params) => {
  delete params.mallName;
  return HttpService.post(API, params);
};

export const updateStrollerService = (values) => {
  if (!values || (values && !values.id)) {
    message.error("id not found!", 3);
    return false;
  }
  delete values.mallName;
  return HttpService.put(`${API}/${values.id}`, { ...values });
};

export const addCodeStroller = (data) => {
  let params = {
    code: data.code,
    lockStateEnumDto: "USE",
  };
  return HttpService.put(`${API}/lock/${data.id}`, params);
};
